import React from 'react'
import EmptyComponent from '../EmptyComponent/EmptyComponent'
import './DepositRecords.css'

const DepositRecords = () => {
    return (
        <div className='addressRecords'>
            <div className='withdrawalsRecordsTextBox'>
                <p className='withdrawalsRecordsText'>Recording</p>
            </div>
            <div className='withdrawRecords'>
                <div className='emptyWithdraw' ><EmptyComponent /></div>
            </div>
        </div>
    )
}

export default DepositRecords
