import React, { useState } from 'react'
import './MobileMarketTrends.css'
import { useNavigate } from 'react-router-dom'
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import { useSelector, useDispatch } from 'react-redux'
import millify from 'millify'
import { selectCoin } from '../../Redux/User/userAction'
import Loading from '../Loading/Loading'

const MobileMarketTrends = ({ showSearch }) => {


    const [searchField, setSearchField] = useState('')

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const allCoins = useSelector(state => state.user.allCoins)
    const isLoading = useSelector(state => state.user.isLoading)

    const filteredCoins = allCoins?.filter(coin => {
        return coin.symbol.toLowerCase().includes(searchField.toLowerCase())
    })

    const showCoinDetails = (coinSymbol) => {
        dispatch(selectCoin(coinSymbol))
        navigate(`/exchanges/${coinSymbol}`)
    }

    let content = <Loading />

    if (isLoading === false && allCoins?.length > 0) {
        content = filteredCoins?.map(coin => {
            return (
                <div key={coin.symbol} onClick={() => showCoinDetails(coin.symbol.toUpperCase())} className='mobileMarketTrendCard' >
                    <div className='mobileCardLeftSection' >
                        <img className='mobileCardImage' src={coin.image} alt='name' />
                        <div>
                            <p className='mobileCoinName'>{coin.symbol.toUpperCase()}<span className='mobileUSDT' >/USDT</span></p>
                            <p className='mobileCoinVol'>{millify(coin.market_cap_change_24h)}</p>
                        </div>
                    </div>
                    <div className='mobileCardRightSection' >
                        <div>
                            <p className='mobileCoinPrice'>{millify(coin.current_price)}</p>
                            <p className='mobileCoinPercentage' style={{ color: (coin.market_cap_change_percentage_24h > 0) ? 'rgb(14, 203, 129)' : 'red' }}  >{millify(coin.market_cap_change_percentage_24h)}%</p>
                        </div>
                    </div>

                </div>
            )
        })

    }

    if (filteredCoins?.length === 0 && allCoins?.length > 0) {
        content = <div className='emptyBoxHolder' >
            <EmptyComponent />
        </div>
    }


    return (
        <div className='mobileMarketTrends' >
            <div className='mobileSearchContainer'>
                {
                    !showSearch ?
                        <h1 className='marketTrendText'>Market Trend</h1> :

                        <div className='marketHeading' >
                            <div className='hotContainer' >
                                <p className='hotText'>Hot</p>
                            </div>
                            <div className='searchContainer mobileSearchInputContainer' >
                                <i className="ri-search-line searchIcon"></i>
                                <input onChange={e => setSearchField(e.target.value)} className='searchInput' type='search' placeholder='Search' />
                            </div>
                        </div>
                }
            </div>

            <div className='mobileMarketTrendHolder' >
                {content}
            </div>
        </div>
    )
}

export default MobileMarketTrends
