import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import HomeNavBar from "../../Components/HomeNavBar/HomeNavBar";
import { useSelector, useDispatch } from 'react-redux'
import { TailSpin } from "react-loading-icons";
import "./ModifyLogin.css";
import { userChangePassword, offMessage } from "../../Redux/User/userAction";



const ModifyLogin = () => {
  const navigate = useNavigate();
  const userDetails = useSelector(state => state.user.userDetails)
  const isLoading = useSelector(state => state.user.isLoading)
  const msg = useSelector(state => state.user.message)
  const user_id = userDetails.user_id
  const token = userDetails.token

  const dispatch = useDispatch()

  const [isOriginalPasswordEmpty, setIsOriginalPasswordEmpty] = useState(true);
  const [isNewPasswordEmpty, setIsNewPasswordEmpty] = useState(true);
  const [isConfirmNewPasswordEmpty, setIsConfirmNewPasswordEmpty] = useState(true);



  const [old_password, setOriginalPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmNewPassword] = useState("");

  const [err, setErr] = useState('')


  const modificationDetails = {
    user_id, old_password, new_password, confirm_password
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (

      old_password.length === 0 &&
      new_password.length === 0 &&
      confirm_password.length === 0
    ) {
      setIsOriginalPasswordEmpty(false);
      setIsNewPasswordEmpty(false);
      setIsConfirmNewPasswordEmpty(false);
      return;

    }
    else if (new_password.length === 0) {
      setIsNewPasswordEmpty(false);
    } else if (old_password.length === 0) {
      setIsOriginalPasswordEmpty(false);
    } else if (confirm_password.length === 0) {
      setIsConfirmNewPasswordEmpty(false);
    } else if (new_password !== confirm_password) {
      setIsConfirmNewPasswordEmpty(false);
      setIsNewPasswordEmpty(false);
    } else {
      // console.log(modificationDetails)
      dispatch(userChangePassword(modificationDetails, token))
    }
  };

  useEffect(() => {
    setErr(msg)
  }, [setErr, msg])

  const goBack = () => {
    dispatch(offMessage())
    navigate(-1)
  }


  return (
    <div className="withdrawalBody">
      <div className="linearGradientBody">
        <HomeNavBar gradient={true} />
        <div className="withdrawContainer">
          <div className="arrowBox" onClick={goBack}>
            <i className="ri-arrow-left-line backArw"></i>
          </div>
          <h2 className="withdraw">Modify Login</h2>
        </div>
      </div>
      <div className="withdrawals setFundPassword ">
        <div className="telephoneBox fundPasswordbox modifyLogin">
          <div className="emailRegister bindteleBox ">
            <form>
              <p className="activityInfo">Activity Info</p>

              <div className="inputContainer">
                <input
                  className="loginInput"
                  type="password"
                  placeholder="Please enter old password"
                  onChange={(e) => setOriginalPassword(e.target.value)}
                  value={old_password}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      e.target.style.border = "1px solid red";
                      setIsOriginalPasswordEmpty(false);
                    } else {
                      setIsOriginalPasswordEmpty(true);
                    }
                  }}
                  onFocus={(e) => {
                    e.target.style.border = "1px solid #535353";
                  }}
                />
                {!isOriginalPasswordEmpty && (
                  <p className="errMsg">Please enter original password</p>
                )}
              </div>
              <div className="inputContainer">
                <input
                  className="loginInput"
                  type="password"
                  placeholder="Please enter new password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={new_password}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      e.target.style.border = "1px solid red";
                      setIsNewPasswordEmpty(false);
                    } else {
                      setIsNewPasswordEmpty(true);
                    }
                  }}
                  onFocus={(e) => {
                    e.target.style.border = "1px solid #535353";
                  }}
                />
                {!isNewPasswordEmpty && (
                  <p className="errMsg">Please enter your new Password</p>
                )}
              </div>
              <div className="inputContainer">
                <input
                  className="loginInput"
                  type="password"
                  placeholder="Please confirm new Password"
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  value={confirm_password}
                  onBlur={(e) => {
                    if (e.target.value.length === 0) {
                      e.target.style.border = "1px solid red";
                      setIsConfirmNewPasswordEmpty(false);
                    } else {
                      setIsConfirmNewPasswordEmpty(true);
                    }
                  }}
                  onFocus={(e) => {
                    e.target.style.border = "1px solid #535353";
                  }}
                />
                {!isConfirmNewPasswordEmpty && (
                  <p className="errMsg">Confirm new assword</p>
                )}
              </div>
              {
                isLoading ? <button className="signInBtn signUp"><TailSpin width={20} stroke="#fff" /></button> :
                  <button onClick={handleSubmit} className="signInBtn signUp">Submit</button>
              }
              <p className='msg' >{err}</p>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ModifyLogin;
