import React from 'react'
import './AddAddress.css'

const AddAddress = () => {
    return (
        <form className='addAddress'>
            <p className='withdrawalLeftText'>Add withdrawal address</p>
            <div className='addressFormContainer'>
                <div className='selectContainer'>
                    <label className='addressLabel'>Coin</label>
                    <select className='addressInput'>
                        <option>BTC</option>
                    </select>
                </div>
                <div className='addressContainer'>
                    <label className='addressLabel'>Address</label>
                    <input className='addressInput' type='text' placeholder='Address' />
                </div>
                <div className='remarkContainer'>
                    <label className='addressLabel'>Remark</label>
                    <input className='addressInput' type='text' placeholder='Remark' />
                </div>
            </div>
            <div className='withdrawalSubmitBtnContainer'>
                <button className='withdrawalSubmitBtn'>Add</button>
            </div>
        </form>
    )
}

export default AddAddress
