import React from 'react'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import AddAddress from '../../Components/AddAddress/AddAddress'
import AddressRecords from '../../Components/AddressRecords/AddressRecords'


const AddressManagement = () => {

    const navigate = useNavigate()

    return (
        <div className='withdrawalBody'>
            <div className='linearGradientBody'>
                <HomeNavBar gradient={true} />
                <div className='withdrawContainer'>
                    <div className='arrowBox' onClick={() => navigate(-1)} >
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className='withdraw'>Address management</h2>
                </div>
            </div>
            <div className='withdrawals'>
                <AddAddress />
                <AddressRecords />
            </div>
            <Footer />
        </div>
    )
}

export default AddressManagement
