import React from 'react'
import './WithdrawalRecords.css'
import EmptyComponent from '../EmptyComponent/EmptyComponent'
import { useSelector } from 'react-redux'
import Loading from '../Loading/Loading'

const WithdrawalRecords = () => {

    const withdrawals = useSelector(state => state.user.withdrawals)
    const isLoading = useSelector(state => state.user.isLoading)
    const message = useSelector(state => state.user.message)

    let content = <Loading />

    if (isLoading === false && message === 'withdraw not found') {
        content = <div className='emptyWithdraw' ><EmptyComponent /></div>

    }

    else if (isLoading === false && withdrawals?.length > 0) {
        content = <div className='tableParent withDrawalTableContainer'  >
            <table className='userListTable withdrawTable userWithdrawTable' >
                <thead>
                    <tr>
                        <th>Currency</th>
                        <th>Address</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        withdrawals?.map((withdraw, id) => {
                            return (
                                <tr key={id} >

                                    <td>{withdraw?.currency}</td>
                                    <td>{withdraw?.address}</td>
                                    <td>{withdraw?.amount}</td>
                                </tr>
                            )
                        })


                    }
                </tbody>
            </table>
        </div>
    }






    return (
        <div className='WithdrawalRecords'>
            <div className='withdrawalsRecordsTextBox'>
                <p className='withdrawalsRecordsText'>Withdrawals record</p>
            </div>
            {content}

        </div>
    )
}

export default WithdrawalRecords
