import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../Components/Loading/Loading'
// import Footer from '../../Components/Footer/Footer'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import { updateSingleICO, fetchAllICOs } from '../../Redux/User/userAction'
import { TailSpin } from "react-loading-icons";

const AdminICODetails = () => {




    const icoDetails = useSelector(state => state.user.icoDetails)
    const { token } = useSelector(state => state.user.userDetails)
    const [err, setErr] = useState('')

    let percentage = 0;
    if (icoDetails?.progress_bar === null) {
        percentage = 0
    }
    else {
        percentage = icoDetails?.progress_bar;
    }

    const [circulation, setCirculation] = useState('')
    const [coinValue, setCoinValue] = useState('')
    const [price, setPrice] = useState('')
    const [startingDate, setStartingDate] = useState('')
    const [endingDate, setEndingDate] = useState('')
    const [progress_bar, setProgressBar] = useState('')

    useEffect(() => {
        setCirculation(icoDetails.circulation)
        setCoinValue(icoDetails.coin_value)
        setPrice(icoDetails.price)
        setStartingDate(icoDetails.start_date)
        setEndingDate(icoDetails.end_date)
        setProgressBar(percentage)

    }, [setCirculation, setCoinValue, setPrice, setStartingDate, setEndingDate, percentage, setProgressBar, icoDetails])

    const coinDetails = {
        circulation, coinValue, price, startingDate, endingDate, progress_bar
    }
    // ||
    const updateIco = () => {
        if (circulation.length === 0 && coinValue === 0 && progress_bar === '' && price === 0 && startingDate === 0 && endingDate === 0) {
            return
        }
        else {
            dispatch(updateSingleICO(icoDetails.id, coinDetails, token))
            dispatch(fetchAllICOs(token))
        }
    }




    const dispatch = useDispatch()

    const navigate = useNavigate()
    const isLoading = useSelector(state => state.user.isLoading)

    const startDate = new Date(icoDetails?.start_date).toLocaleDateString("en-US")
    const endDate = new Date(icoDetails?.end_date).toLocaleDateString("en-US")

    const msg = useSelector(state => state.user.message)

    useEffect(() => {
        setErr(msg)
    }, [setErr, msg])

    return (
        <div className='withdrawalBody'>
            <div className='linearGradientBody'>
                <AdminNavbar gradient={true} />
                <div className='withdrawContainer'>
                    <div className='arrowBox' onClick={() => navigate(-1)} >
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className='withdraw'>ICO</h2>
                </div>
            </div>


            <div className='icoDetailsBox' >
                {isLoading ? <Loading />
                    :
                    <div className='icoDetailsHodingBox'>
                        <div className='icoDetailsHodingBoxLeft'>
                            <div className='icoLeftSub' >
                                <img src={icoDetails?.image_url} alt='icon' className='icoImg icoLeftImg' icboBoxImg />
                                <p className='icoDetailsCoinName' >{icoDetails?.coin_name}</p>
                            </div>
                            <div className='EditIco' >
                                <p className='editIcoText' >Edit <span className='icoDetailsCoinName' >{icoDetails?.coin_name}</span> </p>
                                <div className='EditIcoInputsContainer' >
                                    <input onChange={e => setCirculation(e.target.value)} className='editIcoInput' placeholder='Circulation' type='number' value={circulation} />
                                    <input onChange={e => setCoinValue(e.target.value)} className='editIcoInput' placeholder='Coin Value' type="number" value={coinValue} />
                                    <input onChange={e => setPrice(e.target.value)} className='editIcoInput' placeholder='Price' type="number" value={price} />
                                    <input type='number' onChange={e => setProgressBar(e.target.value)} className='editIcoInput' placeholder='Progress bar' value={progress_bar} />
                                    <input
                                        className='editIcoInput'
                                        onFocus={
                                            (e) => e.target.type = 'date'
                                        }
                                        onBlur={
                                            (e) => e.target.type = 'text'
                                        }
                                        onChange={e => setStartingDate(e.target.value)}
                                        value={startingDate}
                                        placeholder='Start Date'
                                    />
                                    <input
                                        className='editIcoInput'
                                        placeholder='End Date'
                                        onFocus={
                                            (e) => e.target.type = 'date'
                                        }
                                        onBlur={
                                            (e) => e.target.type = 'text'
                                        }
                                        onChange={e => setEndingDate(e.target.value)}
                                        value={endingDate}
                                    />
                                    {
                                        isLoading ? <button className='attendBtn updateIcoBtn ' ><TailSpin width={20} stroke="#fff" /></button> :

                                            <button onClick={updateIco} className='attendBtn updateIcoBtn '>Update Ico</button>
                                    }
                                    <p className='msg success ' >{err}</p>
                                </div>
                            </div>
                        </div>
                        <div className='icoDetailsHodingBoxRight'>
                            <div className='activityInfoBox' >
                                <p className='activityInfo' >Activity Info</p>
                            </div>
                            <div className='progressBox' >
                                <div className='totalSupplyBox'>
                                    <p className='progress' >Progress</p>
                                    <p className='progress' >Total supply</p>
                                </div>
                                <div className='progressbarLength' >
                                    <div className='progressBar' style={{ width: `${percentage}%` }} />
                                </div>
                                <div className='totalSupplyBox'>
                                    <p className='progress' >{percentage}%</p>
                                    <p className='progress' >{icoDetails?.circulation}</p>
                                </div>
                            </div>
                            <div className='otherCardDetails' >
                                <p className='bolder' >Status</p>
                                <p className='lighter' >Completed</p>
                                <p className='bolder' >Total Supply</p>
                                <p className='lighter' >{icoDetails?.circulation}</p>
                                <p className='bolder' >Publish price</p>
                                <p className='lighter' >{icoDetails?.price}</p>
                                <p className='bolder' >Activity Coin Symbol</p>
                                <p className='lighter' >{icoDetails?.coin_name}</p>
                                <p className='bolder' >activity.acceptcoin</p>
                                <p className='lighter' >USDT</p>
                                <p className='bolder' >Start Date</p>
                                <p className='lighter' >{startDate}</p>
                                <p className='bolder' >End Date</p>
                                <p className='lighter' >{endDate}</p>
                            </div>
                            {/*<button className='attendBtn' disabled={true} >Attend</button>*/}
                        </div>
                    </div>
                }
            </div>
            {/*<Footer />*/}
        </div>
    )
}

export default AdminICODetails
