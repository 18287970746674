import React from 'react'
import './IDPhotos.css'

const IDPhotos = () => {
    return (
        <div className='telephoneBox fundPasswordbox  identityAuth idPhotosBox'  >
            <div className='activityInfoBox'>
                <p className='activityInfo' >Front and BACK ID photos</p>
            </div>

            <div className='imageBoxParent' >
                <div>
                    <div className='imageBox'>
                        <i className="ri-add-line plusSign"></i>
                        <input className='fileInput' type='file' />
                    </div>
                    <p className='uploadText' >Upload photo ID (front)</p>
                </div>
                <div>
                    <div className='imageBox'>
                        <i className="ri-add-line plusSign"></i>
                        <input className='fileInput' type='file' />
                    </div>
                    <p className='uploadText' >Upload photo ID (back)</p>
                </div>
            </div>


        </div>
    )
}

export default IDPhotos
