import React, { useState } from "react";
import QRCodeViewer from "../QRCode/QRCode";
import './USDTDeposit.css'

const USDTDeposit = ({ selected, handleChange }) => {
  //here are the real wallet address
  const firstTooltipValue = "0x9cA4c4e8e4C3CBb3f0eB37B2BF3E09B6B6712b43";
  const secondTooltipValue = "TKobrQw7aKcMpmTatH85gdGsHxhfYsCzYR";

  // Check if the text has been copied
  const [hasCopiedText, setHasCopiedText] = useState(false);

  // Toggle between each component
  const [isFirstActive, setIsFirstActive] = useState(true);
  const [isSecondActive, setIsSecondActive] = useState(false);

  const copyFirstText = () => {
    navigator.clipboard.writeText(firstTooltipValue);
    setHasCopiedText(true);
  };

  const copySecondText = () => {
    navigator.clipboard.writeText(secondTooltipValue);
    setHasCopiedText(true);
  };

  const makeFirstActive = () => {
    setIsSecondActive(false);
    setIsFirstActive(true);
    setHasCopiedText(false);
  };

  const makeSecondActive = () => {
    setIsFirstActive(false);
    setIsSecondActive(true);
    setHasCopiedText(false);
  };

  return (
    <div className="withdrawalDetails usdtDeposits">
      <div className="withdrawalLeft">
        <p className="withdrawalLeftText">Please select a currency</p>
        <select
          className="currencySelect"
          value={selected}
          onChange={handleChange}>
          <option value="BTC" className="withdrawalOptions">
            BTC
          </option>
          <option value="ETH" className="withdrawalOptions">
            ETH
          </option>
          <option value="USDT" className="withdrawalOptions">
            USDT
          </option>
        </select>
        <div className="moneyBox">
          <div className="balanceBox">
            <p className="availableBalance">Available Balance</p>
            <p className="ethBal">
              0 USDT <i className="ri-refresh-line spin"></i>
            </p>
          </div>
          <div className="balanceBox">
            <p className="availableBalance">Minimum recharge amount</p>
            <p className="ethBal">10 USDT </p>
          </div>
        </div>
        <div className="withdrawalDescBox">
          <p className="withdrawalDesc">
            Do not recharge any non-currency assets to the above address,
            otherwise the assets will not be recovered. After you recharge to
            the above address, you need to confirm the entire network node,
            after 2 network confirmations arrive, after 6 network confirmation
            can be extracted.
          </p>
          <p className="withdrawalDesc">
            Minimum recharge amount: 10 USDT , recharge less than the minimum
            amount will not be accounted.
          </p>
          <p className="withdrawalDesc">
            Your top-up address will not change frequently and you can repeat
            the recharge; if there is any change, we will try to notify you via
            website announcement or email.
          </p>
        </div>
      </div>
      <div className="withdrawalRight">
        <p className="withdrawalLeftText">Choose a network</p>
        <div>
          <button
            style={{ background: isFirstActive ? "#feb032" : "#535353" }}
            onClick={makeFirstActive}
            className="depositSectionBtns">
            ERC20
          </button>
          <button
            style={{ background: isSecondActive ? "#feb032" : "#535353" }}
            onClick={makeSecondActive}
            className="depositSectionBtns">
            TRC20
          </button>
        </div>

        <div className="DepositRightHeader">
          <p className="withdrawalLeftText">Please select a currency</p>
          <p className="DepositRightHeaderWarning">
            Only recharge is allowed USDT
          </p>
        </div>
        {isFirstActive && (
          <div className="DepositQrCodeBox">
            <div className="DepositQrCodeHolder">
              <div className="qrInnerBox">
                <QRCodeViewer text={firstTooltipValue} />
              </div>
              <p className="qrCode">{firstTooltipValue}</p>
              <div className="copyBtnParent">
                {hasCopiedText ? (
                  <button className="copyBtn">Copied!</button>
                ) : (
                  <button onClick={copyFirstText} className="copyBtn">
                    Copy
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {isSecondActive && (
          <div className="DepositQrCodeBox">
            <div className="DepositQrCodeHolder">
              <div className="qrInnerBox">
                <QRCodeViewer text={secondTooltipValue} />
              </div>
              <p className="qrCode">{secondTooltipValue}</p>
              <div className="copyBtnParent">
                {hasCopiedText ? (
                  <button className="copyBtn">Copied!</button>
                ) : (
                  <button onClick={copySecondText} className="copyBtn">
                    Copy
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/*Second*/}
    </div>
  );
};

export default USDTDeposit;
