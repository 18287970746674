import { useState, useEffect, useRef } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import logo from '../../Assets/primary horizontal-01.png'
import companyLogo from '../../Assets/logo.png'
import { logout } from '../../Redux/User/userAction'
import './AdminNavbar.css'

const AdminNavbar = ({ gradient }) => {

    const [isMobileOpen, setIsMobileOpen] = useState(true)

    const toggleOption = () => {
        setIsMobileOpen(prev => !prev)
    }




    const isSignedIn = useSelector(state => state.user?.isSignedIn)
    const { email } = useSelector(state => state.user?.userDetails)
    const token = useSelector(state => state.user?.userDetails.token)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const logoutFn = () => {
        dispatch(logout(token))
        navigate('/')
    }
    // Auto Logout
    const timeOut = 3600000;
    const logoutTimer = useRef(null);

    useEffect(() => {
        const startTimeout = () => {
            logoutTimer.current = setTimeout(() => {
                dispatch(logout(token));
            }, timeOut);
        };

        const resetTimeout = () => {
            clearTimeout(logoutTimer.current);
            startTimeout();
        };


        startTimeout();
        resetTimeout()


    }, [dispatch, timeOut, token]);


    return (
        <header className='DesktopHeader' style={{ background: (gradient ? 'transparent' : 'rgb(24, 24, 26, .1)') }} >
            <div className='normalNavContainer' >
                <Link to='/admin/home' className='brand normalMenuLogo' >
                    <img className='logoImg' src={logo} alt='logo' />
                </Link>
                <div className='mobileNavOptions' >
                    {
                        isMobileOpen ? <i onClick={toggleOption} className="ri-menu-line mobileOption"></i>
                            : <i onClick={toggleOption} className="ri-close-fill mobileOption"></i>
                    }
                </div>
                <div className={!isMobileOpen ? 'normalMenu' : 'normalMenuNotOpen'} >

                    <button className='loginBtn' onClick={logoutFn} >Log Out</button>
                </div>
            </div>
            <nav>
                <div className='left adminNav'>
                    <Link to='/admin/home' className='brand' >
                        <img className='logoImg' src={logo} alt='logo' />
                    </Link>
                    <ul className='navLinksMobileParent'>

                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/admin/home'>Users</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/admin/deposits'>Deposits</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/admin/withdraws'>Withdrawals</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/admin/ico'>ICO</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/admin/finance'>Finance</NavLink>
                        </li>
                    </ul>

                </div>
                <div className='adminNavParentDiv' >
                    {
                        !isSignedIn ?
                            <div className='navParentDiv'>
                                <button onClick={() => navigate('/login')} className='loginBtn'>Log In</button>
                                <button onClick={() => navigate('/signup')} className='signUpBtn'>Sign Up</button>
                            </div> :
                            <div className='cont' >
                                <div className='userParentDiv'>
                                    <img src={companyLogo} alt='logo' className='userImage' />
                                    <p className='userEmail'>{email}</p>
                                    <i className="ri-arrow-down-s-line downArrow"></i>
                                </div>
                                <button onClick={logoutFn} className='signUpBtn logOutBtn'>Logout</button>
                            </div>

                    }
                </div>



            </nav>
        </header>
    )
}

export default AdminNavbar
