import React from 'react'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import './LabOrder.css'
import Footer from '../../Components/Footer/Footer'

const LabOrder = () => {
    return (
        <div>
            <div className='navholder' >
                <HomeNavBar gradient={false} />
            </div>
            <div className='dummyBox' />
            <div className='labOrderData' >
                <div className='labOrderHolder' >
                    <EmptyComponent />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LabOrder
