import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/es/storage";
import { userReducer } from "./User/userReducer";


const persistConfig = {
    key: "root",
    storage: localStorage,
};

const rootReducer = combineReducers({

    user: userReducer,

})

export const persistedReducer = persistReducer(persistConfig, rootReducer);



// import { combineReducers } from "redux";

// import { userReducer } from "./User/userReducer";




// export const rootReducer = combineReducers({

//     user: userReducer

// })
