import React from 'react'
import './Loading.css'
import { TailSpin } from "react-loading-icons";

const Loading = () => {
    return (
        <div className='loading' >
            <div className='loadingHolder' >
                <TailSpin width={20} stroke="#FFB331" />
            </div>
        </div>
    )
}

export default Loading
