import React, { useState } from 'react'
import './WithdrawalDetails.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { postWithdrawal, fetchWithdrawals } from '../../Redux/User/userAction'
import { TailSpin } from "react-loading-icons";

const WithdrawalDetails = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.user.userDetails);
  const { user_id } = useSelector((state) => state.user.userDetails);
  const isLoading = useSelector((state) => state.user.isLoading);

  const [isAddressEmpty, setIsAddressEmpty] = useState(true);

  const [currency, setCurrency] = useState("BTC");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);

  const withdrawDetails = {
    user_id,
    currency,
    address,
    amount,
  };

  // console.log(isAddressEmpty);

  const makeWithdrawal = (e) => {
    e.preventDefault();

    if (amount === 0 && address === "") {
      return;
    }
    dispatch(postWithdrawal(withdrawDetails, token));
    dispatch(fetchWithdrawals(user_id, token));
    setAmount(0);
    setAddress("");
  };

    return (
        <div className='withdrawalDetails'>
            <div className='withdrawalLeft'>
                <p className='withdrawalLeftText'>Please select a currency</p>
                <select defaultValue={currency} onChange={e => setCurrency(e.target.value)} className='currencySelect'>
                    <option value='BTC' className='withdrawalOptions' >BTC</option>
                    <option value='ETH' className='withdrawalOptions' >ETH</option>
                    <option value='USDT' className='withdrawalOptions' >USDT</option>
                </select>
                <div className='moneyBox'>
                    <div className='balanceBox'>
                        <p className='availableBalance'>Available Balance</p>
                        <p className='ethBal'>0 ETH <i className="ri-refresh-line spin"></i></p>
                    </div>
                    <div className='balanceBox'>
                        <p className='availableBalance'>Frozen amount</p>
                        <p className='ethBal'>0 ETH </p>
                    </div>
                </div>
                <div className='withdrawalDescBox'>
                    <p className='withdrawalDesc'>In order to ensure the security of funds, when your account security policy changes, passwords are changed, and you use the new address to withdraw coins, we will conduct a manual audit of the coins.
                    </p>
                    <p className='withdrawalDesc'>Please be patient and wait for the staff to call or email. </p>
                    <p className='withdrawalDesc'>
                        Please be sure to confirm the security of your computer and browser to prevent information from being tampered with or leaked.</p>
                </div>
            </div>
            <form onSubmit={makeWithdrawal} className='withdrawalRight'>
                <div className='withdrawalAddressTextBox'>
                    <p className='withdrawalLeftText'>Address</p>
                    {/* <Link to='/user/address' className='userAddressLink' >Address management</Link> */}
                </div>
                <div className='addressinputBox'>
                    <input className='withdrawalAddressInput' type='text' placeholder='Please fill in the address'
                        onChange={e => setAddress(e.target.value)} value={address}
                        onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                e.target.style.border = '1px solid red'
                                setIsAddressEmpty(false)
                            }
                            else {
                                setIsAddressEmpty(true)
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.border = '1px solid #535353'
                        }}
                    />
                </div>
                <>
                    <div className='withdrawalAddressTextBox'>
                        <p className='withdrawalLeftText'>Amount</p>
                    </div>
                    <div className='addressinputBox'>
                        <input className='withdrawalAddressInput' type='number' placeholder='Please enter amount'
                            onChange={e => setAmount(e.target.value)} value={amount}
                            onBlur={(e) => {
                                if (e.target.value.length === 0) {
                                    e.target.style.border = '1px solid red'
                                    setIsAddressEmpty(false)
                                }
                                else {
                                    setIsAddressEmpty(true)
                                }
                            }}
                            onFocus={(e) => {
                                e.target.style.border = '1px solid #535353'
                            }}
                        />
                    </div>

                </>
                <div className='moneyBox'>
                    <div className='balanceBox'>
                        <p className='availableBalance'>Fees</p>
                        <p className='ethBal'>0 BTC </p>
                    </div>
                    <div className='balanceBox'>
                        <p className='availableBalance'>Amount to the account</p>
                        <p className='ethBal btcBal'>0 BTC </p>
                    </div>
                </div>
                <div className='withdrawalSubmitBtnContainer'>
                    {
                        isLoading ? <button className='withdrawalSubmitBtn'><TailSpin width={20} stroke="#fff" /></button> :
                            <button className='withdrawalSubmitBtn'>Submit</button>
                    }
                </div>

            </form>
            {/*Second*/}
        </div>
    )
}

export default WithdrawalDetails;
