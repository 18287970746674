import React, { useEffect } from 'react'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import { useNavigate } from 'react-router-dom'
import './Finance.css'
import Footer from '../../Components/Footer/Footer'
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import Loading from '../../Components/Loading/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { fetchFinance } from '../../Redux/User/userAction'

const Finance = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isLoading = useSelector(state => state.user.isLoading)
    const finance = useSelector(state => state.user.finance)
    const { user_id } = useSelector(state => state.user.userDetails)
    const { token } = useSelector(state => state.user.userDetails)




    let totalFinance = 0


    if (isLoading === false && finance?.length > 0) {
        totalFinance = finance[0]?.total_finance

    }
    else if (isLoading === false && finance?.length === 0) {
        totalFinance = 0
    }

    else {
        totalFinance = 0

    }








    useEffect(() => {
        dispatch(fetchFinance(user_id, token))
    }, [dispatch, token, user_id])

    let content = <Loading />

    if (isLoading === false && finance.length === 0) {
        content = <div className='emptyBox'>
            <EmptyComponent />
        </div>
    }

    else if (isLoading === false && finance.length > 0) {
        content = <div className='tableParent withDrawalTableContainer'  >
            <table className='userListTable  ' >
                <thead>
                    <tr>
                        {/*<th>Finance id</th>*/}
                        {/*<th>User id</th>*/}
                        <th>Currency</th>
                        <th>Amount</th>


                    </tr>
                </thead>
                <tbody>
                    {

                        finance?.map(userFinance => {
                            return (
                                <tr key={userFinance?.finance_id} >
                                    {/*<td>{userFinance?.finance_id}</td>*/}
                                    {/*<td>{userFinance?.user_id}</td>*/}
                                    <td>{userFinance?.currency}</td>
                                    <td>{new Intl.NumberFormat().format(userFinance?.amount)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    else {
        content = <Loading />
    }

    return (
        <div className='withdrawalBody'>
            <div className="linearGradientBody">
                <HomeNavBar gradient={true} />
                <div className="withdrawContainer">
                    <div className="arrowBox" onClick={() => navigate(-1)}>
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className="withdraw">Finance</h2>
                </div>
                <div className='financeParent' >

                    <div className='financeHolder'>
                        <h1 className='totalFinance' >Total USD: {new Intl.NumberFormat().format(totalFinance)}</h1>
                        {content}
                    </div>
                </div>
                <Footer />
            </div>

        </div>
    )
}

export default Finance
