import { userTypes } from "./userActionTypes";
import axios from "axios";

const userFetchAllCoinsStart = () => ({
    type: userTypes.USER_GET_ALL_COINS_START

})

const userFetchAllCoinsSuccess = (data) => ({
    type: userTypes.USER_GET_ALL_COINS_SUCCESS,
    payload: data
})

const userFetchAllCoinsFailed = (message) => ({
    type: userTypes.USER_GET_ALL_COINS_FAILED,
    payload: message
})


export const selectCoin = (coin) => ({
    type: userTypes.SELECTED_COIN,
    payload: coin
})


export const fetchAllCoins = (total) => async (dispatch) => {
    try {
        dispatch(userFetchAllCoinsStart())
        const res = await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=${total}&page=1&sparkline=false&locale=en`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Access-Control-Allow-Origin': 'http://localhost:3000'
            }
        })
        const data = await res.json()

        // if (res.status === 401) {
        //     dispatch(userFetchAllCoinsSuccess(data.message))
        // }
        // console.log(data)

        dispatch(userFetchAllCoinsSuccess(data))

    }
    catch (err) {
        dispatch(userFetchAllCoinsFailed('An error occured'))
    }
}


// User Registration
const userRegistrationStart = () => ({
    type: userTypes.USER_REGISTER_START,
});

const userRegistrationSuccess = (data) => ({
    type: userTypes.USER_REGISTER_SUCCESS,
    payload: data,
});

const userRegistrationFailed = (message) => ({
    type: userTypes.USER_REGISTER_FAILED,
    payload: message,
});

export const userRegister = (userDetails) => async (dispatch) => {
    try {
        dispatch(userRegistrationStart());
        const res = await fetch("https://api.quatumn.com/user/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userDetails),
        });
        const data = await res.json();

        // console.log(data)
        if (res.status === 400) {
            dispatch(userRegistrationFailed(data.message));
        }

        dispatch(userRegistrationSuccess(data.message));
    } catch (err) {
        dispatch(userRegistrationFailed("An error occured"));
    }
};

// User Login

const userLoginStart = () => ({
    type: userTypes.USER_LOGIN_START,
});

const userLoginSuccess = (data) => ({
    type: userTypes.USER_LOGIN_SUCCESS,
    payload: data,
});

const userLoginFailed = (message) => ({
    type: userTypes.USER_LOGIN_FAILED,
    payload: message,
});

export const userLogin = (userDetails) => async (dispatch) => {
    try {
        dispatch(userLoginStart());
        const res = await fetch("https://api.quatumn.com/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userDetails),
        });
        const data = await res.json();
        if (res.status === 401) {
            // console.log(data.message);
            dispatch(userLoginFailed(data.message));
        } else {
            dispatch(userLoginSuccess(data));
        }
    } catch (err) {
        dispatch(userLoginFailed(""));
    }
};



// User Change Password

const userForgotPasswordStart = () => ({
    type: userTypes.USER_FORGOT_PASSWORD_START,
});

const userForgotPasswordSuccess = (message) => ({
    type: userTypes.USER_FORGOT_PASSWORD_SUCCESS,
    payload: message,
});

const userForgotPasswordFailed = (message) => ({
    type: userTypes.USER_FORGOT_PASSWORD_FAILED,
    payload: message,
});

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch(userForgotPasswordStart())
        const res = await fetch('https://api.quatumn.com/forgotpassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(email)
        })
        const data = await res.json()
        // console.log(data)
        if (res.status === 200) {
            dispatch(userForgotPasswordSuccess(data.message))
        }
        else {
            dispatch(userForgotPasswordFailed(data.message))

        }
    }
    catch (err) {
        dispatch(userChangePasswordFailed('Something went wrong'))
    }
}

// Logout


const logoutSuccess = (message) => ({
    type: userTypes.LOGOUT_SUCCESS,
    payload: message
})

export const logout = (token) => async (dispatch) => {
    try {
        const res = await fetch('https://api.quatumn.com/user/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ` ${token}`
            },
        })
        const data = await res.json()
        // console.log(data)
        if (res.status === 200) {
            dispatch(logoutSuccess(data.message))
        }
    }
    catch (err) {
        // console.log(err)
    }
}


// OFF MESSAGE

export const offMessage = () => ({
    type: userTypes.OFF_MESSAGE
})





// User Change Password

const userChangePasswordStart = () => ({
    type: userTypes.USER_CHANGE_PASSWORD_START,
});

const userChangePasswordSuccess = (message) => ({
    type: userTypes.USER_CHANGE_PASSWORD_SUCCESS,
    payload: message,
});

const userChangePasswordFailed = (message) => ({
    type: userTypes.USER_CHANGE_PASSWORD_FAILED,
    payload: message,
});

export const userChangePassword = (modificationDetails, token) => async (dispatch) => {
    try {
        dispatch(userChangePasswordStart());
        const res = await fetch("https://api.quatumn.com/user/change/password", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
            body: JSON.stringify(modificationDetails),
        });
        const data = await res.json();

        if (res.status === 400) {
            // console.log(data.message);
            dispatch(userChangePasswordFailed(data.error));
        } else {
            dispatch(userChangePasswordSuccess(data.message));
        }
    } catch (err) {
        dispatch(userChangePasswordFailed(""));
    }
};


// Reset Password
const ResetPasswordStart = () => ({
    type: userTypes.USER_RESET_PASSWORD_START,
});

const ResetPasswordSuccess = (data) => ({
    type: userTypes.USER_RESET_PASSWORD_SUCCESS,
    payload: data,
});

const ResetPasswordFailed = (message) => ({
    type: userTypes.USER_RESET_PASSWORD_FAILED,
    payload: message,
});

export const resetPassword = (userDetails, reset_token) => async (dispatch) => {
    try {
        dispatch(ResetPasswordStart());
        const res = await fetch(`https://api.quatumn.com/reset/password/${reset_token}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userDetails),
        });
        const data = await res.json();

        if (res.status === 200) {
            dispatch(ResetPasswordSuccess(data.message));

        }
        else {
            dispatch(ResetPasswordFailed(data.message));
        }

    } catch (err) {
        dispatch(ResetPasswordFailed("An error occured"));
    }
};

// Admin Get all Deposits

const userUpdatePhoneNumberStart = () => ({
    type: userTypes.USER_UPDATE_NUMBER_START,
});

const userUpdatePhoneNumberSuccess = (data) => ({
    type: userTypes.USER_UPDATE_NUMBER_SUCCESS,
    payload: data,
});

const userUpdatePhoneNumberFailed = (message) => ({
    type: userTypes.USER_UPDATE_NUMBER_FAILED,
    payload: message,
});

export const userUpdatePhoneNumber = (userId, token, phoneNumber) => async (dispatch) => {
    try {
        dispatch(userUpdatePhoneNumberStart());
        let res = await axios.patch(
            `https://api.quatumn.com/update/phone/${userId}`, phoneNumber,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;


        if (res.status === 200) {
            dispatch(userUpdatePhoneNumberSuccess(data));
        } else {
            dispatch(userUpdatePhoneNumberFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}



// get ico

const getAllIcoStart = () => ({
    type: userTypes.USER_GET_ALL_ICO_START,
});

const getAllIcoSuccess = (data) => ({
    type: userTypes.USER_GET_ALL_ICO_SUCCESS,
    payload: data,
});

const getAllIcoFailed = (message) => ({
    type: userTypes.USER_GET_ALL_ICO_FAILED,
    payload: message,
});

export const fetchAllIco = (token) => async (dispatch) => {
    try {
        dispatch(getAllIcoStart());
        let res = await axios.get("https://api.quatumn.com/coin", {
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
        });
        const data = await res.data;
        if (res.status === 200) {
            dispatch(getAllIcoSuccess(data));
        } else {
            dispatch(getAllIcoFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// get ico

const getIcoDetailsStart = () => ({
    type: userTypes.USER_GET_ALL_ICO_DETAILS_START,
});

const getIcoDetailsSuccess = (data) => ({
    type: userTypes.USER_GET_ALL_ICO_DETAILS_SUCCESS,
    payload: data,
});

const getIcoDetailsFailed = (message) => ({
    type: userTypes.USER_GET_ALL_ICO_DETAILS_FAILED,
    payload: message,
});

export const fetchIcoDetails = (coinId, token) => async (dispatch) => {
    try {
        dispatch(getIcoDetailsStart());
        let res = await axios.get(`https://api.quatumn.com/coin/${coinId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
        });
        const data = await res.data;
        // console.log(data);
        if (res.status === 200) {
            dispatch(getIcoDetailsSuccess(data));
        } else {
            dispatch(getIcoDetailsFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// get all withdrawals

const getAllWithdrawalsStart = () => ({
    type: userTypes.USER_GET_ALL_WITHDRAWALS_START,
});

const getAllWithdrawalsSuccess = (data) => ({
    type: userTypes.USER_GET_ALL_WITHDRAWALS_SUCCESS,
    payload: data,
});

const getAllWithdrawalsFailed = (message) => ({
    type: userTypes.USER_GET_ALL_WITHDRAWALS_FAILED,
    payload: message,
});

export const fetchWithdrawals = (user_id, token) => async (dispatch) => {
    try {
        dispatch(getAllWithdrawalsStart());
        let res = await axios.get(
            `https://api.quatumn.com/withdrawal/${user_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;

        dispatch(getAllWithdrawalsSuccess(data));
    } catch (err) {
        dispatch(getAllWithdrawalsFailed(err.response.data));
    }
};


// get all withdrawals

const getAllFinanceStart = () => ({
    type: userTypes.USER_GET_FINANCE_START,
});

const getAllFinanceSuccess = (data) => ({
    type: userTypes.USER_GET_FINANCE_SUCCESS,
    payload: data,
});

const getAllFinanceFailed = (message) => ({
    type: userTypes.USER_GET_FINANCE_FAILED,
    payload: message,
});

export const fetchFinance = (user_id, token) => async (dispatch) => {
    try {
        dispatch(getAllFinanceStart());
        let res = await axios.get(
            `https://api.quatumn.com/finance/${user_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        dispatch(getAllFinanceSuccess(data));
        if (res.status === 400) {
            dispatch(getAllFinanceFailed(data))
        }
    } catch (err) {
        dispatch(getAllWithdrawalsFailed(err.response.data));
    }
};



// post withdrawal

const postWithdrawStart = () => ({
    type: userTypes.USER_POST_WITHDRAWALS_START,
});

const postWithdrawSuccess = (data) => ({
    type: userTypes.USER_POST_WITHDRAWALS_SUCCESS,
    payload: data,
});

const postWithdrawFailed = (message) => ({
    type: userTypes.USER_POST_WITHDRAWALS_FAILED,
    payload: message,
});

export const postWithdrawal = (withdrawDetails, token) => async (dispatch) => {
    try {
        dispatch(postWithdrawStart());
        const res = await fetch(`https://api.quatumn.com/withdrawal`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
            body: JSON.stringify(withdrawDetails),
        });
        const data = await res.data;
        // console.log(data);
        dispatch(postWithdrawSuccess(data));
    } catch (err) {
        dispatch(postWithdrawFailed(err.response.data));
    }
};




// Admin Section

// get all users

const getAllUsersStart = () => ({
    type: userTypes.ADMIN_GET_ALL_USERS_START,
});

const getAllUsersSuccess = (data) => ({
    type: userTypes.ADMIN_GET_ALL_USERS_SUCCESS,
    payload: data,
});

const getAllUsersFailed = (message) => ({
    type: userTypes.ADMIN_GET_ALL_USERS_FAILED,
    payload: message,
});






export const fetchAllUsers = (token) => async (dispatch) => {
    try {
        dispatch(getAllUsersStart());
        let res = await axios.get(`https://api.quatumn.com/user/register`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
        });
        const data = await res.data;

        if (res.status === 200) {
            dispatch(getAllUsersSuccess(data));
        } else {
            dispatch(getAllUsersFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// get all ICOs

const getAllICOsStart = () => ({
    type: userTypes.ADMIN_GET_ALL_ICOS_START,
});

const getAllICOsSuccess = (data) => ({
    type: userTypes.ADMIN_GET_ALL_ICOS_SUCCESS,
    payload: data,
});

const getAllICOsFailed = (message) => ({
    type: userTypes.ADMIN_GET_ALL_ICOS_FAILED,
    payload: message,
});

export const fetchAllICOs = (token) => async (dispatch) => {
    try {
        dispatch(getAllICOsStart());
        let res = await axios.get(`https://api.quatumn.com/coin/admin`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
        });
        const data = await res.data;

        if (res.status === 200) {
            dispatch(getAllICOsSuccess(data));
        } else {
            dispatch(getAllICOsFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// get all ICOs

const getSingleICOStart = () => ({
    type: userTypes.ADMIN_GET_SINGLE_ICO_START,
});

const getSingleICOSuccess = (data) => ({
    type: userTypes.ADMIN_GET_SINGLE_ICO_SUCCESS,
    payload: data,
});

const getSingleICOFailed = (message) => ({
    type: userTypes.ADMIN_GET_SINGLE_ICO_FAILED,
    payload: message,
});

export const fetchSingleICO = (coinId, token) => async (dispatch) => {
    try {
        dispatch(getSingleICOStart());
        let res = await axios.get(`https://api.quatumn.com/coin/admin/${coinId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
        });
        const data = await res.data;

        if (res.status === 200) {
            dispatch(getSingleICOSuccess(data));
        } else {
            dispatch(getSingleICOFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// update Single ICO

const updateSingleICOStart = () => ({
    type: userTypes.ADMIN_UPDATE_SINGLE_ICO_START,
});

const updateSingleICOSuccess = (data) => ({
    type: userTypes.ADMIN_UPDATE_SINGLE_ICO_SUCCESS,
    payload: data,
});

const updateSingleICOFailed = (message) => ({
    type: userTypes.ADMIN_UPDATE_SINGLE_ICO_FAILED,
    payload: message,
});

export const updateSingleICO = (coinId, coinData, token) => async (dispatch) => {
    try {
        dispatch(updateSingleICOStart());

        let res = await axios.patch(
            `https://api.quatumn.com/updateico/${coinId}`,
            coinData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // // console.log(data);

        if (res.status === 200) {
            dispatch(updateSingleICOSuccess(data));
            dispatch(fetchAllICOs(token))
            dispatch(fetchSingleICO(coinId, token))
        } else {
            dispatch(updateSingleICOFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

const getAllWithdrawsStart = () => ({
    type: userTypes.ADMIN_GET_ALL_WITHDRAWS_START,
});

const getAllWithdrawsSuccess = (data) => ({
    type: userTypes.ADMIN_GET_ALL_WITHDRAWS_SUCCESS,
    payload: data,
});

const getAllWithdrawsFailed = (message) => ({
    type: userTypes.ADMIN_GET_ALL_WITHDRAWS_FAILED,
    payload: message,
});

export const getAllWithdrawsAdmin = (token) => async (dispatch) => {
    try {
        dispatch(getAllWithdrawsStart())
        let res = await axios.get(
            `https://api.quatumn.com/report/withdrawal`,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': ` ${token}`
                },
            }
        )
        const data = await res.data
        // console.log(data)

        if (res.status === 200) {
            dispatch(getAllWithdrawsSuccess(data));
        } else {
            dispatch(getAllWithdrawsFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// Admin Get single withdraw

const getSingleWithdrawStart = () => ({
    type: userTypes.ADMIN_GET_SINGLE_WITHDRAW_START,
});

const getSingleWithdrawSuccess = (data) => ({
    type: userTypes.ADMIN_GET_SINGLE_WITHDRAW_SUCCESS,
    payload: data,
});

const getSingleWithdrawFailed = (message) => ({
    type: userTypes.ADMIN_GET_SINGLE_WITHDRAW_FAILED,
    payload: message,
});

export const getSingleWithdrawAdmin = (withdrawId, token) => async (dispatch) => {
    try {
        dispatch(getSingleWithdrawStart());
        let res = await axios.get(
            `https://api.quatumn.com/withdrawal/${withdrawId}/admin`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(getSingleWithdrawSuccess(data));

        }
        else {
            dispatch(getSingleWithdrawFailed("Something went wrong"));
        }
    }
    catch (err) {
        dispatch(getSingleWithdrawFailed(err.response.data))
    }

}



// Admin update withdraws


const adminUpdateWithdrawStart = () => ({
    type: userTypes.ADMIN_UPDATE_USER_WITHDRAW_START,
});

const adminUpdateWithdrawSuccess = (data) => ({
    type: userTypes.ADMIN_UPDATE_USER_WITHDRAW_SUCCESS,
    payload: data,
});

const adminUpdateWithdrawFailed = (message) => ({
    type: userTypes.ADMIN_UPDATE_USER_WITHDRAW_FAILED,
    payload: message,
});

export const adminUpdateWithdraw = (withdrawId, userData, token) => async (dispatch) => {
    try {
        dispatch(adminUpdateWithdrawStart());

        let res = await axios.patch(
            `https://api.quatumn.com/updatewithdrawal/${withdrawId}`,
            userData,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(adminUpdateWithdrawSuccess(data));
            dispatch(getSingleWithdrawAdmin(withdrawId, token))
            dispatch(getAllWithdrawsAdmin(token))
        } else {
            dispatch(adminUpdateWithdrawFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};






// Admin Get all Deposits

const getAllDepositsStart = () => ({
    type: userTypes.ADMIN_GET_ALL_DEPOSIT_START,
});

const getAllDepositsSuccess = (data) => ({
    type: userTypes.ADMIN_GET_ALL_DEPOSIT_SUCCESS,
    payload: data,
});

const getAllDepositsFailed = (message) => ({
    type: userTypes.ADMIN_GET_ALL_DEPOSIT_FAILED,
    payload: message,
});

export const getAllDepositsAdmin = (token) => async (dispatch) => {
    try {
        dispatch(getAllDepositsStart());
        let res = await axios.get(`https://api.quatumn.com/deposit`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: ` ${token}`,
            },
        });
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(getAllDepositsSuccess(data));
        } else {
            dispatch(getAllDepositsFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
};

// Admin view single deposit

const viewSingleUserStart = () => ({
    type: userTypes.ADMIN_VIEW_SINGLE_USER_START,
});

const viewSingleUserSuccess = (data) => ({
    type: userTypes.ADMIN_VIEW_SINGLE_USER_SUCCESS,
    payload: data,
});

const viewSingleUserFailed = (message) => ({
    type: userTypes.ADMIN_VIEW_SINGLE_USER_FAILED,
    payload: message,
});

export const adminViewSingleUser = (userId, token) => async (dispatch) => {
    try {
        dispatch(viewSingleUserStart());
        let res = await axios.get(
            `https://api.quatumn.com/user/register/admin/${userId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(viewSingleUserSuccess(data));
        } else {
            dispatch(viewSingleUserFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}

// Admin Get all Deposits

const adminGetAllFinanceStart = () => ({
    type: userTypes.ADMIN_GET_ALL_FINANCE_START,
});

const adminGetAllFinanceSuccess = (data) => ({
    type: userTypes.ADMIN_GET_ALL_FINANCE_SUCCESS,
    payload: data,
});

const adminGetAllFinanceFailed = (message) => ({
    type: userTypes.ADMIN_GET_ALL_FINANCE_FAILED,
    payload: message,
});

export const adminGetAllFinance = (token) => async (dispatch) => {
    try {
        dispatch(adminGetAllFinanceStart());
        let res = await axios.get(
            `https://api.quatumn.com/report/finance`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(adminGetAllFinanceSuccess(data));
        } else {
            dispatch(adminGetAllFinanceFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}


// Admin Crea

const adminCreateFinanceStart = () => ({
    type: userTypes.ADMIN_CREATE_FINANCE_START,
});

const adminCreateFinanceSuccess = (data) => ({
    type: userTypes.ADMIN_CREATE_FINANCE_SUCCESS,
    payload: data,
});

const adminCreateFinanceFailed = (message) => ({
    type: userTypes.ADMIN_CREATE_FINANCE_FAILED,
    payload: message,
});

export const adminCreateFinance = (token, financeBody) => async (dispatch) => {
    try {
        dispatch(adminCreateFinanceStart());
        let res = await axios.post(
            `https://api.quatumn.com/finance`, financeBody,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(adminCreateFinanceSuccess(data));
            dispatch(adminGetAllFinance(token))
        } else {
            dispatch(adminCreateFinanceFailed(data.message));
        }
    } catch (err) {
        // console.log(err);
    }
}


// Admin get single finance

const adminGetSingleUserFinanceStart = () => ({
    type: userTypes.ADMIN_GET_SINGLE_USER_FINANCE_START,
});

const adminGetSingleUserFinanceSuccess = (data) => ({
    type: userTypes.ADMIN_GET_SINGLE_USER_FINANCE_SUCCESS,
    payload: data,
});

const adminGetSingleUserFinanceFailed = (message) => ({
    type: userTypes.ADMIN_GET_SINGLE_USER_FINANCE_FAILED,
    payload: message,
});

export const adminGetSingleUserFinance = (financeId, token) => async (dispatch) => {
    try {
        dispatch(adminGetSingleUserFinanceStart());
        let res = await axios.get(
            `https://api.quatumn.com/finance/${financeId}/admin`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(adminGetSingleUserFinanceSuccess(data));
        } else {
            dispatch(adminGetSingleUserFinanceFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}


// Admin update single finance

const adminUpdateSingleUserFinanceStart = () => ({
    type: userTypes.ADMIN_UPDATE_SINGLE_USER_FINANCE_START,
});

const adminUpdateSingleUserFinanceSuccess = (data) => ({
    type: userTypes.ADMIN_UPDATE_SINGLE_USER_FINANCE_SUCCESS,
    payload: data,
});

const adminUpdateSingleUserFinanceFailed = (message) => ({
    type: userTypes.ADMIN_UPDATE_SINGLE_USER_FINANCE_SUCCESS,
    payload: message,
});

export const adminUpdateSingleUserFinance = (financeId, updateFinanceBody, token) => async (dispatch) => {
    try {
        dispatch(adminUpdateSingleUserFinanceStart());
        let res = await axios.patch(
            `https://api.quatumn.com/finance/${financeId}`, updateFinanceBody,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;
        // console.log(data);

        if (res.status === 200) {
            dispatch(adminUpdateSingleUserFinanceSuccess(data));
            dispatch(adminGetSingleUserFinance(financeId, token))
            dispatch(adminGetAllFinance(token))
        } else {
            dispatch(adminUpdateSingleUserFinanceFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}



// Admin Delete User

const adminDeleteUserStart = () => ({
    type: userTypes.ADMIN_DELETE_USER_START,
});

const adminDeleteUserSuccess = (data) => ({
    type: userTypes.ADMIN_DELETE_USER_SUCCESS,
    payload: data,
});

const adminDeleteUserFailed = (message) => ({
    type: userTypes.ADMIN_DELETE_USER_FAILED,
    payload: message,
});

export const adminDeleteUser = (userId, token) => async (dispatch) => {
    try {
        dispatch(adminDeleteUserStart());
        let res = await axios.delete(
            `https://api.quatumn.com/user/delete/${userId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;


        if (res.status === 200) {
            dispatch(adminDeleteUserSuccess(data));
            dispatch(fetchAllUsers(token))
        } else {
            dispatch(adminDeleteUserFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}



// Admin Delete Withdraw

const adminDeleteWithdrawStart = () => ({
    type: userTypes.ADMIN_DELETE_WITHDRAW_START,
});

const adminDeleteWithdrawSuccess = (data) => ({
    type: userTypes.ADMIN_DELETE_WITHDRAW_SUCCESS,
    payload: data,
});

const adminDeleteWithdrawFailed = (message) => ({
    type: userTypes.ADMIN_DELETE_WITHDRAW_FAILED,
    payload: message,
});

export const adminDeleteWithdraw = (withdraw_id, token) => async (dispatch) => {
    try {
        dispatch(adminDeleteWithdrawStart());
        let res = await axios.delete(
            `https://api.quatumn.com/withdrawal/${withdraw_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;


        if (res.status === 200) {
            dispatch(adminDeleteWithdrawSuccess(data));
            dispatch(getAllWithdrawsAdmin(token))
        } else {
            dispatch(adminDeleteWithdrawFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}



// Admin Delete Finance

const adminDeleteFinanceStart = () => ({
    type: userTypes.ADMIN_DELETE_FINANCE_START,
});

const adminDeleteFinanceSuccess = (data) => ({
    type: userTypes.ADMIN_DELETE_FINANCE_SUCCESS,
    payload: data,
});

const adminDeleteFinanceFailed = (message) => ({
    type: userTypes.ADMIN_DELETE_FINANCE_FAILED,
    payload: message,
});

export const adminDeleteFinance = (finance_id, token) => async (dispatch) => {
    try {
        dispatch(adminDeleteFinanceStart());
        let res = await axios.delete(
            `https://api.quatumn.com/finance/${finance_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: ` ${token}`,
                },
            }
        );
        const data = await res.data;


        if (res.status === 200) {
            dispatch(adminDeleteFinanceSuccess(data));
            dispatch(adminGetAllFinance(token))
        } else {
            dispatch(adminDeleteFinanceFailed("Something went wrong"));
        }
    } catch (err) {
        // console.log(err);
    }
}

export const userDeleteId = (payload) => ({
    type: userTypes.DELETE_ID,
    payload: payload
});