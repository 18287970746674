import React from 'react'
import { useSelector } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import AddressManagement from './Pages/AddressManagement/AddressManagement'
import AdminDeposits from './Pages/AdminDeposits/AdminDeposits'
import AdminHome from './Pages/AdminHome/AdminHome'
import AdminICO from './Pages/AdminICO/AdminICO'
import AdminICODetails from './Pages/AdminIcoDetails/AdminIcoDetails'
import AdminViewSingleWithdraw from './Pages/ViewSingleWithdraw/AdminViewSingleWithdraw'
import AdminWithdrawal from './Pages/AdminWithdrawal/AdminWithdrawal'


import BindPhone from './Pages/BindPhone/BindPhone'
import Deposit from './Pages/Deposit/Deposit'
import Home from './Pages/Home/Home'
import ICO from './Pages/ICO/ICO'
import Exchanges from './Pages/Exchanges/Exchanges'
import ICODetails from './Pages/ICODetails/ICODetails'
import IdentityAuth from './Pages/IdentityAuth/IdentityAuth'
import LabOrder from './Pages/LabOrder/LabOrder'
import Login from './Pages/Login/Login'
import ModifyLogin from './Pages/ModifyLogin/ModifyLogin'
import Profile from './Pages/Profile/Profile'
import SetFundPassword from './Pages/SetFundPassword/SetFundPassword'
import SignUp from './Pages/SignUp/SignUp'

import Withdrawal from './Pages/Withdrawal/Withdrawal'
import AdminViewUser from './Pages/AdminViewUser/AdminViewUser'
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword'
import PlatformInformation from './Pages/PlatformInformation/PlatformInformation'
import Market from './Pages/Market/Market'
import ResetPassword from './Pages/ResetPassword/ResetPassword'
import Finance from './Pages/Finance/Finance'
import AdminFinance from './Pages/AdminFinance/AdminFinance'
import ViewSingleFinance from './Pages/ViewSingleFinance/ViewSingleFinance'
import CreateFinance from './Pages/CreateFinance/CreateFinance'






const App = () => {
  const isSignedIn = useSelector(state => state.user.isSignedIn)
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/info' element={<PlatformInformation />} />
      <Route path='/signup' element={<SignUp />} />
      <Route path='/exchanges/:coin_name' element={<Exchanges />} />
      <Route path='/market' element={<Market />} />
      <Route path='/reset/password/:params' element={<ResetPassword />} />

      <Route path='/user/withdraw' element={isSignedIn ? <Withdrawal /> : <Login />} />
      <Route path='/user/deposit' element={isSignedIn ? <Deposit /> : <Login />} />
      <Route path='/user/address' element={isSignedIn ? <AddressManagement /> : <Login />} />
      <Route path='/user/profile' element={isSignedIn ? <Profile /> : <Login />} />
      <Route path='/user/phone' element={isSignedIn ? <BindPhone /> : <Login />} />
      <Route path='/user/change-password' element={isSignedIn ? <ModifyLogin /> : <Login />} />
      <Route path='/user/fund/password' element={isSignedIn ? <SetFundPassword /> : <Login />} />
      <Route path='/user/auth' element={isSignedIn ? <IdentityAuth /> : <Login />} />
      <Route path='/ico' element={isSignedIn ? <ICO /> : <Login />} />
      <Route path='/user/finance' element={isSignedIn ? <Finance /> : <Login />} />
      <Route path='/ico/:name' element={isSignedIn ? <ICODetails /> : <Login />} />
      <Route path='/ico/lab/order' element={isSignedIn ? <LabOrder /> : <Login />} />



      {/* Admin */}
      {/*<Route path='/admin/home' element={isSignedIn ? <AdminHome /> : <Login />} />*/}

      <Route path='/admin/home' element={isSignedIn ? <AdminHome /> : <Login />} />
      <Route path='/admin/ico' element={isSignedIn ? <AdminICO /> : <Login />} />
      <Route path='/admin/ico/:name' element={isSignedIn ? <AdminICODetails /> : <Login />} />
      <Route path='/admin/withdraws' element={isSignedIn ? <AdminWithdrawal /> : <Login />} />
      <Route path='/admin/withdraws/:userId' element={isSignedIn ? <AdminViewSingleWithdraw /> : <Login />} />
      <Route path='/admin/deposits' element={isSignedIn ? <AdminDeposits /> : <Login />} />
      <Route path='/admin/finance' element={isSignedIn ? <AdminFinance /> : <Login />} />
      <Route path='/admin/users/:userId' element={isSignedIn ? <AdminViewUser /> : <Login />} />
      <Route path='/admin/finance/:financeId' element={isSignedIn ? <ViewSingleFinance /> : <Login />} />
      <Route path='/admin/create-finance' element={isSignedIn ? <CreateFinance /> : <Login />} />



    </Routes>
  )
}

export default App
