
import { userTypes } from "./userActionTypes"

const initialState = {
    userDetails: {},
    isLoading: false,
    message: '',
    isSignedIn: false,
    isRegSuccessful: false,
    icos: [],
    withdrawals: [],
    icoDetails: {},
    users: [],
    adminAllWithdrawals: [],
    adminViewSingleWithdrawal: {},
    adminAllDeposits: [],
    adminAllFinance: [],
    user: {},
    allCoins: [],
    selectedCoin: 'ETH',
    resetSuccess: false,
    finance: [],
    singleUserFinance: [],
    createSingleUserFinance: [],
    deleteId: ''


}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case userTypes.USER_REGISTER_START:
        case userTypes.USER_LOGIN_START:
        case userTypes.USER_CHANGE_PASSWORD_START:
        case userTypes.USER_GET_ALL_ICO_START:
        case userTypes.USER_GET_ALL_WITHDRAWALS_START:
        case userTypes.USER_POST_WITHDRAWALS_START:
        case userTypes.USER_GET_ALL_ICO_DETAILS_START:
        case userTypes.USER_FORGOT_PASSWORD_START:
        case userTypes.USER_GET_ALL_COINS_START:
        case userTypes.USER_RESET_PASSWORD_START:
        case userTypes.USER_GET_FINANCE_START:
        case userTypes.USER_UPDATE_NUMBER_START:

            return {
                ...state, isLoading: true, message: ''
            }
        case userTypes.SELECTED_COIN:
            return {
                ...state, selectedCoin: action.payload
            }

        case userTypes.OFF_MESSAGE:
            return {
                ...state, message: ''
            }


        // Fetch all coins
        case userTypes.USER_GET_ALL_COINS_SUCCESS:
            return {
                ...state, isLoading: false, allCoins: action.payload
            }
        case userTypes.USER_GET_ALL_COINS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        case userTypes.USER_REGISTER_SUCCESS:
            return {
                ...state, isLoading: false, message: action.payload, isRegSuccessful: true
            }
        case userTypes.USER_REGISTER_FAILED:
            return {
                ...state, isLoading: false, message: action.payload, isRegSuccessful: false
            }
        // UserLogin

        case userTypes.USER_LOGIN_SUCCESS:
            return {
                ...state, isLoading: false, isSignedIn: true, userDetails: action.payload, isRegSuccessful: false
            }
        case userTypes.USER_LOGIN_FAILED:
            return {
                ...state, isLoading: false, message: action.payload, isSignedIn: false, userDetails: {}
            }

        // User Change password

        case userTypes.USER_CHANGE_PASSWORD_SUCCESS:
        case userTypes.USER_CHANGE_PASSWORD_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // User Forgot password

        case userTypes.USER_FORGOT_PASSWORD_SUCCESS:
        case userTypes.USER_FORGOT_PASSWORD_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Reset Password
        case userTypes.USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state, isLoading: false, message: action.payload, resetSuccess: true
            }
        case userTypes.USER_RESET_PASSWORD_FAILED:
            return {
                ...state, isLoading: false, message: action.payload, resetSuccess: false
            }
        // Change Phone number
        case userTypes.USER_UPDATE_NUMBER_SUCCESS:
        case userTypes.USER_UPDATE_NUMBER_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Get all ICO

        case userTypes.USER_GET_ALL_ICO_SUCCESS:
            return {
                ...state, icos: action.payload, isLoading: false
            }
        case userTypes.USER_GET_ALL_ICO_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Get all withdrawals
        case userTypes.USER_GET_ALL_WITHDRAWALS_SUCCESS:
            return {
                ...state, isLoading: false, withdrawals: action.payload
            }
        case userTypes.USER_GET_ALL_WITHDRAWALS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Post withdrawals
        case userTypes.USER_POST_WITHDRAWALS_SUCCESS:
            return {
                ...state, isLoading: false, withdrawals: action.payload
            }
        case userTypes.USER_POST_WITHDRAWALS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Get single user all finances
        case userTypes.USER_GET_FINANCE_SUCCESS:

            return {
                ...state, isLoading: false, finance: action.payload
            }
        case userTypes.USER_GET_FINANCE_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }



        // Get Ico details
        case userTypes.USER_GET_ALL_ICO_DETAILS_SUCCESS:
            return {
                ...state, isLoading: false, icoDetails: action.payload
            }
        case userTypes.USER_GET_ALL_ICO_DETAILS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Admin Section
        case userTypes.ADMIN_GET_ALL_USERS_START:
        case userTypes.ADMIN_GET_ALL_ICOS_START:
        case userTypes.ADMIN_GET_SINGLE_ICO_START:
        case userTypes.ADMIN_UPDATE_SINGLE_ICO_START:
        case userTypes.ADMIN_GET_ALL_WITHDRAWS_START:
        case userTypes.ADMIN_GET_SINGLE_WITHDRAW_START:
        case userTypes.ADMIN_GET_ALL_DEPOSIT_START:
        case userTypes.ADMIN_VIEW_SINGLE_USER_START:
        case userTypes.ADMIN_UPDATE_USER_WITHDRAW_START:
        case userTypes.ADMIN_GET_ALL_FINANCE_START:
        case userTypes.ADMIN_CREATE_FINANCE_START:
        case userTypes.ADMIN_GET_SINGLE_USER_FINANCE_START:
        case userTypes.ADMIN_UPDATE_SINGLE_USER_FINANCE_START:
        case userTypes.ADMIN_DELETE_USER_START:
        case userTypes.ADMIN_DELETE_WITHDRAW_START:
        case userTypes.ADMIN_DELETE_FINANCE_START:
            return {
                ...state, isLoading: true, message: ''
            }
        // All Users
        case userTypes.ADMIN_GET_ALL_USERS_SUCCESS:
            return {
                ...state, isLoading: false, message: '', users: action.payload
            }
        case userTypes.ADMIN_GET_ALL_USERS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // All Icos
        case userTypes.ADMIN_GET_ALL_ICOS_SUCCESS:
            return {
                ...state, isLoading: false, icos: action.payload
            }
        case userTypes.ADMIN_GET_ALL_ICOS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // get SingleIco
        case userTypes.ADMIN_GET_SINGLE_ICO_SUCCESS:
            return {
                ...state, isLoading: false, icoDetails: action.payload
            }
        case userTypes.ADMIN_GET_SINGLE_ICO_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }
        // admin update single ico
        case userTypes.ADMIN_UPDATE_SINGLE_ICO_SUCCESS:
            return {
                ...state, isLoading: false, message: action.payload
            }
        case userTypes.ADMIN_UPDATE_SINGLE_ICO_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }
        // admin get all withdrawals
        case userTypes.ADMIN_GET_ALL_WITHDRAWS_SUCCESS:
            return {
                ...state, isLoading: false, adminAllWithdrawals: action.payload
            }
        case userTypes.ADMIN_GET_ALL_WITHDRAWS_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }
        // admin get single withdraw
        case userTypes.ADMIN_GET_SINGLE_WITHDRAW_SUCCESS:
            return {
                ...state, isLoading: false, adminViewSingleWithdrawal: action.payload
            }
        case userTypes.ADMIN_GET_SINGLE_WITHDRAW_FAILED:
            return {
                ...state, isLoading: false, adminViewSingleWithdrawal: action.payload
            }

        // Get all finance
        case userTypes.ADMIN_GET_ALL_FINANCE_SUCCESS:
            return {
                ...state, isLoading: false, adminAllFinance: action.payload
            }
        case userTypes.ADMIN_GET_ALL_FINANCE_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Get create finance
        case userTypes.ADMIN_CREATE_FINANCE_SUCCESS:
            return {
                ...state, isLoading: false, createSingleUserFinance: action.payload
            }
        case userTypes.ADMIN_CREATE_FINANCE_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Get single user finance
        case userTypes.ADMIN_GET_SINGLE_USER_FINANCE_SUCCESS:
            return {
                ...state, isLoading: false, singleUserFinance: action.payload
            }
        case userTypes.ADMIN_GET_SINGLE_USER_FINANCE_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // update single user Finance

        case userTypes.ADMIN_UPDATE_SINGLE_USER_FINANCE_SUCCESS:
        case userTypes.ADMIN_UPDATE_SINGLE_USER_FINANCE_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }


        // admin updatesingle withdraw
        case userTypes.ADMIN_UPDATE_USER_WITHDRAW_SUCCESS:
        case userTypes.ADMIN_UPDATE_USER_WITHDRAW_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }
        // admin get all deposits
        case userTypes.ADMIN_GET_ALL_DEPOSIT_SUCCESS:
        case userTypes.ADMIN_GET_ALL_DEPOSIT_FAILED:
            return {
                ...state, isLoading: false, adminAllDeposits: action.payload
            }

        // View single user

        case userTypes.ADMIN_VIEW_SINGLE_USER_SUCCESS:
            return {
                ...state, isLoading: false, user: action.payload
            }

        case userTypes.ADMIN_VIEW_SINGLE_USER_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }

        // Admin Delete User

        case userTypes.ADMIN_DELETE_USER_SUCCESS:
        case userTypes.ADMIN_DELETE_USER_FAILED:
        case userTypes.ADMIN_DELETE_WITHDRAW_SUCCESS:
        case userTypes.ADMIN_DELETE_WITHDRAW_FAILED:
        case userTypes.ADMIN_DELETE_FINANCE_SUCCESS:
        case userTypes.ADMIN_DELETE_FINANCE_FAILED:
            return {
                ...state, isLoading: false, message: action.payload
            }


        case userTypes.DELETE_ID:
            return {
                ...state, deleteId: action.payload
            }

        case userTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                userDetails: {}, message: '', isSignedIn: false, isLoading: false, user: {}, adminAllDeposits: [], adminViewSingleWithdrawal: {}, withdrawals: [], deleteId: '',
                adminAllWithdrawals: [], users: [], icoDetails: {}, icos: [], isRegSuccessful: false, adminAllFinance: [], resetSuccess: false, finance: [], singleUserFinance: [], createSingleUserFinance: []
            }
        default:
            return state
    }
}

