import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate, Link } from "react-router-dom";
import { userLogin } from "../../Redux/User/userAction";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loading-icons";
import logo from '../../Assets/primary horizontal-01.png'


const Login = () => {

  const [err, setErr] = useState('')


  const dispatch = useDispatch()
  const isSignedIn = useSelector(state => state.user.isSignedIn)
  const userDetails = useSelector(state => state.user.userDetails)
  const msg = useSelector(state => state.user.message)
  const isLoading = useSelector(state => state.user.isLoading)


  const navigate = useNavigate();

  const [isEmailEmpty, setIsEmailEmpty] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);

  const [email, setEmail] = useState("");
  const [password_hash, setPassword] = useState("");

  const loginDetails = {
    email, password_hash
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.length === 0 && password_hash.length === 0) {
      setIsEmailEmpty(false);
      setIsPasswordEmpty(false);
      return;
    } else if (email.length === 0) {
      setIsEmailEmpty(false);
    } else if (password_hash.length === 0) {
      setIsPasswordEmpty(false);
    } else {
      setIsEmailEmpty(true);
      setIsPasswordEmpty(true);
      dispatch(userLogin(loginDetails))

    }
  };


  useEffect(() => {
    if (isSignedIn && userDetails?.roles === 'user') {
      // console.log('User')
      navigate('/')
    }
    if (isSignedIn && userDetails?.roles === 'admin') {
      // console.log('Admin')
      navigate('/admin/home')
    }
  }, [isSignedIn, navigate, userDetails?.roles])

  useEffect(() => {
    setErr(msg)
  }, [setErr, msg])


  return (
    <div className="loginContainer">
      <div className="backArrowBox" onClick={() => navigate("/")}>
        <i className="ri-arrow-left-line backArrow"></i>
      </div>

      <div className="loginBox">
        <div className="logoHolder">
          <Link to='/' className='brand' >
            <img className='logoImg' src={logo} alt='logo' />
          </Link>
        </div>
        <div className="formContainer">
          <form className="loginForm">
            <div className="inputContainer">
              <label htmlFor="email" className="loginLabel">Email</label>
              <input
                id='email'
                className="loginInput"
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                value={email}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    e.target.style.border = "1px solid red";
                    setIsEmailEmpty(false);
                  } else {
                    setIsEmailEmpty(true);
                  }
                }}
                onFocus={(e) => {
                  e.target.style.border = "1px solid #535353";
                }}
              />
              {!isEmailEmpty && <p className="errMsg">Email</p>}
            </div>
            <div className="inputContainer">
              <label htmlFor="password" className="loginLabel">Password</label>
              <input
                id="password"
                className="loginInput"
                type="password"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password_hash}
                onBlur={(e) => {
                  if (e.target.value.length === 0) {
                    e.target.style.border = "1px solid red";
                    setIsPasswordEmpty(false);
                  } else {
                    setIsPasswordEmpty(true);
                  }
                }}
                onFocus={(e) => {
                  e.target.style.border = "1px solid #535353";
                }}
              />
              {!isPasswordEmpty && <p className="errMsg">Enter Password</p>}
            </div>
            <Link to="/forgot-password" className="forgotPwd">
              Forgot Password?
            </Link>
            {isLoading ? <button className='signInBtn signUp'><TailSpin width={20} stroke="#fff" /></button> : <button onClick={handleSubmit} className="signInBtn">Log In</button>}
            <button
              onClick={() => navigate("/signup")}
              className="CreateAcctBtn">
              Sign Up
            </button>
            <p className='msg' >{err}</p>
          </form>
        </div>
      </div>

    </div>
  );
};

export default Login;
