import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import './QRCode.css'

const QRCodeViewer = ({ text }) => {

    const [src, setSrc] = useState('')

    useEffect(() => {
        QRCode.toDataURL(text).then((data) => {
            setSrc(data)
        })
    }, [text])

    return (
        <div>
            <img src={src} alt='qrcode' className='QrCodeImage' />
        </div>
    )
}

export default QRCodeViewer
