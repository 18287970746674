export const userTypes = {
    USER_REGISTER_START: 'USER_REGISTER_START',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_FAILED: 'USER_REGISTER_FAILED',

    USER_LOGIN_START: 'USER_LOGIN_START',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILED: 'USER_LOGIN_FAILED',

    USER_FORGOT_PASSWORD_START: 'USER_FORGOT_PASSWORD_START',
    USER_FORGOT_PASSWORD_SUCCESS: 'USER_FORGOT_PASSWORD_SUCCESS',
    USER_FORGOT_PASSWORD_FAILED: 'USER_FORGOT_PASSWORD_FAILED',

    USER_CHANGE_PASSWORD_START: 'USER_CHANGE_PASSWORD_START',
    USER_CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
    USER_CHANGE_PASSWORD_FAILED: 'USER_CHANGE_PASSWORD_FAILED',

    USER_RESET_PASSWORD_START: 'USER_RESET_PASSWORD_START',
    USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
    USER_RESET_PASSWORD_FAILED: 'USER_RESET_PASSWORD_FAILED',

    USER_GET_ALL_ICO_START: 'USER_GET_ALL_ICO_START',
    USER_GET_ALL_ICO_SUCCESS: 'USER_GET_ALL_ICO_SUCCESS',
    USER_GET_ALL_ICO_FAILED: 'USER_GET_ALL_ICO_FAILED',


    USER_GET_ALL_ICO_DETAILS_START: 'USER_GET_ALL_ICO_DETAILS_START',
    USER_GET_ALL_ICO_DETAILS_SUCCESS: 'USER_GET_ALL_ICO_DETAILS_SUCCESS',
    USER_GET_ALL_ICO_DETAILS_FAILED: 'USER_GET_ALL_ICO_DETAILS_FAILED',

    USER_GET_ALL_WITHDRAWALS_START: 'USER_GET_ALL_WITHDRAWALS_START',
    USER_GET_ALL_WITHDRAWALS_SUCCESS: 'USER_GET_ALL_WITHDRAWALS_SUCCESS',
    USER_GET_ALL_WITHDRAWALS_FAILED: 'USER_GET_ALL_WITHDRAWALS_FAILED',

    USER_POST_WITHDRAWALS_START: 'USER_POST_WITHDRAWALS_START',
    USER_POST_WITHDRAWALS_SUCCESS: 'USER_POST_WITHDRAWALS_SUCCESS',
    USER_POST_WITHDRAWALS_FAILED: 'USER_POST_WITHDRAWALS_FAILED',


    USER_GET_FINANCE_START: 'USER_GET_FINANCE_START',
    USER_GET_FINANCE_SUCCESS: 'USER_GET_FINANCE_SUCCESS',
    USER_GET_FINANCE_FAILED: 'USER_GET_FINANCE_FAILED',


    USER_GET_ALL_COINS_START: 'USER_GET_ALL_COINS_START',
    USER_GET_ALL_COINS_SUCCESS: 'USER_GET_ALL_COINS_SUCCESS',
    USER_GET_ALL_COINS_FAILED: 'USER_GET_ALL_COINS_FAILED',


    USER_UPDATE_NUMBER_START: 'USER_UPDATE_NUMBER_START',
    USER_UPDATE_NUMBER_SUCCESS: 'USER_UPDATE_NUMBER_SUCCESS',
    USER_UPDATE_NUMBER_FAILED: 'USER_UPDATE_NUMBER_FAILED',

    // Admin

    ADMIN_GET_ALL_USERS_START: 'ADMIN_GET_ALL_USERS_START',
    ADMIN_GET_ALL_USERS_SUCCESS: 'ADMIN_GET_ALL_USERS_SUCCESS',
    ADMIN_GET_ALL_USERS_FAILED: 'ADMIN_GET_ALL_USERS_FAILED',


    ADMIN_GET_ALL_ICOS_START: 'ADMIN_GET_ALL_ICOS_START',
    ADMIN_GET_ALL_ICOS_SUCCESS: 'ADMIN_GET_ALL_ICOS_SUCCESS',
    ADMIN_GET_ALL_ICOS_FAILED: 'ADMIN_GET_ALL_ICOS_FAILED',


    ADMIN_GET_SINGLE_ICO_START: 'ADMIN_GET_SINGLE_ICO_START',
    ADMIN_GET_SINGLE_ICO_SUCCESS: 'ADMIN_GET_SINGLE_ICO_SUCCESS',
    ADMIN_GET_SINGLE_ICO_FAILED: 'ADMIN_GET_SINGLE_ICO_FAILED',

    ADMIN_UPDATE_SINGLE_ICO_START: 'ADMIN_UPDATE_SINGLE_ICO_START',
    ADMIN_UPDATE_SINGLE_ICO_SUCCESS: 'ADMIN_UPDATE_SINGLE_ICO_SUCCESS',
    ADMIN_UPDATE_SINGLE_ICO_FAILED: 'ADMIN_UPDATE_SINGLE_ICO_FAILED',

    ADMIN_GET_ALL_WITHDRAWS_START: 'ADMIN_GET_ALL_WITHDRAWS_START',
    ADMIN_GET_ALL_WITHDRAWS_SUCCESS: 'ADMIN_GET_ALL_WITHDRAWS_SUCCESS',
    ADMIN_GET_ALL_WITHDRAWS_FAILED: 'ADMIN_GET_ALL_WITHDRAWS_FAILED',

    ADMIN_GET_SINGLE_WITHDRAW_START: 'ADMIN_GET_SINGLE_WITHDRAW_START',
    ADMIN_GET_SINGLE_WITHDRAW_SUCCESS: 'ADMIN_GET_SINGLE_WITHDRAW_SUCCESS',
    ADMIN_GET_SINGLE_WITHDRAW_FAILED: 'ADMIN_GET_SINGLE_WITHDRAW_FAILED',

    ADMIN_GET_ALL_DEPOSIT_START: 'ADMIN_GET_ALL_DEPOSIT_START',
    ADMIN_GET_ALL_DEPOSIT_SUCCESS: 'ADMIN_GET_ALL_DEPOSIT_SUCCESS',
    ADMIN_GET_ALL_DEPOSIT_FAILED: 'ADMIN_GET_ALL_DEPOSIT_FAILED',

    ADMIN_VIEW_SINGLE_USER_START: 'ADMIN_VIEW_SINGLE_USER_START',
    ADMIN_VIEW_SINGLE_USER_SUCCESS: 'ADMIN_VIEW_SINGLE_USER_SUCCESS',
    ADMIN_VIEW_SINGLE_USER_FAILED: 'ADMIN_VIEW_SINGLE_USER_FAILED',

    ADMIN_UPDATE_USER_WITHDRAW_START: 'ADMIN_UPDATE_USER_WITHDRAW_START',
    ADMIN_UPDATE_USER_WITHDRAW_SUCCESS: 'ADMIN_UPDATE_USER_WITHDRAW_SUCCESS',
    ADMIN_UPDATE_USER_WITHDRAW_FAILED: 'ADMIN_UPDATE_USER_WITHDRAW_FAILED',

    ADMIN_GET_ALL_FINANCE_START: 'ADMIN_GET_ALL_FINANCE_START',
    ADMIN_GET_ALL_FINANCE_SUCCESS: 'ADMIN_GET_ALL_FINANCE_SUCCESS',
    ADMIN_GET_ALL_FINANCE_FAILED: 'ADMIN_GET_ALL_FINANCE_FAILED',

    ADMIN_CREATE_FINANCE_START: 'ADMIN_CREATE_FINANCE_START',
    ADMIN_CREATE_FINANCE_SUCCESS: 'ADMIN_CREATE_FINANCE_SUCCESS',
    ADMIN_CREATE_FINANCE_FAILED: 'ADMIN_CREATE_FINANCE_FAILED',

    ADMIN_GET_SINGLE_USER_FINANCE_START: 'ADMIN_GET_SINGLE_USER_FINANCE_START',
    ADMIN_GET_SINGLE_USER_FINANCE_SUCCESS: 'ADMIN_GET_SINGLE_USER_FINANCE_SUCCESS',
    ADMIN_GET_SINGLE_USER_FINANCE_FAILED: 'ADMIN_GET_SINGLE_USER_FINANCE_FAILED',

    ADMIN_UPDATE_SINGLE_USER_FINANCE_START: 'ADMIN_UPDATE_SINGLE_USER_FINANCE_START',
    ADMIN_UPDATE_SINGLE_USER_FINANCE_SUCCESS: 'ADMIN_UPDATE_SINGLE_USER_FINANCE_SUCCESS',
    ADMIN_UPDATE_SINGLE_USER_FINANCE_FAILED: 'ADMIN_UPDATE_SINGLE_USER_FINANCE_FAILED',

    ADMIN_DELETE_USER_START: 'ADMIN_DELETE_USER_START',
    ADMIN_DELETE_USER_SUCCESS: 'ADMIN_DELETE_USER_SUCCESS',
    ADMIN_DELETE_USER_FAILED: 'ADMIN_DELETE_USER_FAILED',

    ADMIN_DELETE_WITHDRAW_START: 'ADMIN_DELETE_WITHDRAW_START',
    ADMIN_DELETE_WITHDRAW_SUCCESS: 'ADMIN_DELETE_WITHDRAW_SUCCESS',
    ADMIN_DELETE_WITHDRAW_FAILED: 'ADMIN_DELETE_WITHDRAW_FAILED',

    ADMIN_DELETE_FINANCE_START: 'ADMIN_DELETE_FINANCE_START',
    ADMIN_DELETE_FINANCE_SUCCESS: 'ADMIN_DELETE_FINANCE_SUCCESS',
    ADMIN_DELETE_FINANCE_FAILED: 'ADMIN_DELETE_FINANCE_FAILED',

    SELECTED_COIN: 'SELECTED_COIN',

    OFF_MESSAGE: 'OFF_MESSAGE',

    LOGOUT_SUCCESS: 'LOGOUT_SUCCES',

    DELETE_ID: 'DELETE_ID'


}