import React, { useEffect, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import AdminMobileNav from '../../Components/AdminMobileNav/AdminMobileNav'
import { useNavigate } from 'react-router-dom'
import './AdminFinance.css'
import Loading from '../../Components/Loading/Loading'
import { useSelector, useDispatch } from 'react-redux'
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import { adminGetAllFinance, adminGetSingleUserFinance, userDeleteId } from '../../Redux/User/userAction'
import DeleteModal from '../../Components/DeleteModal/DeleteModal'

const AdminFinance = () => {

    const [isModalOpen, setIsModalOpen] = useState(false)

    const openDeleteModal = () => {
        setIsModalOpen(true)
    }

    const closeDeleteModal = () => {
        setIsModalOpen(false)
    }



    const adminAllFinance = useSelector(state => state.user.adminAllFinance)
    const isLoading = useSelector(state => state.user.isLoading)
    const { token } = useSelector(state => state.user.userDetails)

    const dispatch = useDispatch()

    const navigate = useNavigate()



    const getSingleUserFinance = (financeId) => {
        dispatch(adminGetSingleUserFinance(financeId, token))
        navigate(`/admin/finance/${financeId}`)
    }


    useEffect(() => {
        if (adminAllFinance?.length === 0) {
            dispatch(adminGetAllFinance(token))
        }
    }, [dispatch, adminAllFinance, token])




    const deleteFinance = (financeId) => {
        openDeleteModal()
        dispatch(userDeleteId(financeId))
    }



    let content = <Loading />

    if (adminAllFinance?.length > 0 && isLoading === false) {

        content = <div className='tableParent withDrawalTableContainer  '  >

            <table className='userListTable  ' >
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Currency</th>
                        <th>Amount</th>
                        <th>Action</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        adminAllFinance?.map((finance, id) => {
                            return (
                                <tr key={id} >
                                    <td>{id}</td>
                                    <td>{finance.name}</td>
                                    <td>{finance.email}</td>
                                    <td>{finance.currency}</td>
                                    <td>{new Intl.NumberFormat().format(finance.amount)}</td>
                                    <td><button className='viewBtn' onClick={() => getSingleUserFinance(finance.finance_id)} >View</button></td>
                                    <td><button className='viewBtn' onClick={() => deleteFinance(finance.finance_id)} >Delete</button></td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    }

    else if (adminAllFinance?.length === 0) {
        content = <div className='depositBody' ><EmptyComponent /></div>
    }




    return (
        <div>
            <div className='navholder' >
                <AdminNavbar gradient={false} />
                <AdminMobileNav />
                {isModalOpen && <DeleteModal closeDeleteModal={closeDeleteModal} />}
            </div>
            <div className='financeBodyParent'>
                <div className='financeBody ' >
                    {content}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminFinance
