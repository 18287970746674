import React, { useState } from 'react'
import './DepositDetails.css'

import BTCDeposit from '../BTCDeposit/BTCDeposit'
import ETHDeposit from '../ETHDeposit/ETHDeposit'
import USDTDeposit from '../USDTDeposit/USDTDeposit'

const DepositDetails = () => {

    const [selected, setSelected] = useState('BTC')

    const handleChange = event => {
        setSelected(event.target.value);
    };



    return (
        <>
            {
                selected === 'BTC' && <BTCDeposit handleChange={handleChange} selected={selected} />
            }
            {
                selected === 'ETH' && <ETHDeposit handleChange={handleChange} selected={selected} />
            }
            {
                selected === 'USDT' && <USDTDeposit handleChange={handleChange} selected={selected} />
            }


        </>
    )
}

export default DepositDetails
