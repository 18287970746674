import React, { useEffect } from "react";
import HomeNavBar from "../../Components/HomeNavBar/HomeNavBar";
import { useNavigate } from "react-router-dom";
import "./Withdrawal.css";
import WithdrawalDetails from "../../Components/WithdrawalDetails/WithdrawalDetails";
import Footer from "../../Components/Footer/Footer";
import WithdrawalRecords from "../../Components/WithdrawalRecords/WithdrawalRecords";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithdrawals, offMessage } from "../../Redux/User/userAction";



const Withdrawal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { user_id } = useSelector(state => state.user.userDetails)
  const { token } = useSelector(state => state.user.userDetails)

  useEffect(() => {
    dispatch(fetchWithdrawals(user_id, token))
  }, [dispatch, token, user_id])

  const goBack = () => {
    dispatch(offMessage())
    navigate(-1)
  }

  return (
    <div className="withdrawalBody">
      <div className="linearGradientBody">
        <HomeNavBar gradient={true} />
        <div className="withdrawContainer">
          <div className="arrowBox" onClick={goBack}>
            <i className="ri-arrow-left-line backArw"></i>
          </div>
          <h2 className="withdraw">Withdraw</h2>
        </div>
      </div>
      <div className="withdrawals">
        <WithdrawalDetails />
        <WithdrawalRecords />
      </div>
      <Footer />
    </div>
  );
};

export default Withdrawal;
