import React from 'react'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import AdminIcoCard from '../../Components/AdminIcoCard/AdminIcoCard'
import Footer from '../../Components/Footer/Footer'
import Loading from '../../Components/Loading/Loading'
import { useSelector } from 'react-redux'
import AdminMobileNav from '../../Components/AdminMobileNav/AdminMobileNav'


const AdminICO = () => {



    const icos = useSelector(state => state.user.icos)



    let ICOS = <Loading />

    if (icos.length > 0) {
        ICOS = <div className='icoCardHolder adminICOCardHolder'>
            {
                icos.map((data) => {
                    return <AdminIcoCard key={data.id} data={data} />
                })
            }
        </div>
    }

    return (
        <div>
            <div className='icoPage' >
                <div className='navholder' >
                    <AdminNavbar gradient={false} />
                    <AdminMobileNav />
                </div>
                <div className='icoBody adminIco'>
                    <div className='icoBodyParent adminIcoParent' >
                        <div className='icoHolder adminIcoHolder' >
                            <div className='icoHeaders' >
                                <div className='icoLeftOptions adminIcoLeftOptions' >
                                    <div style={{ background: '#FFB331' }} className='icoOptionBox'>
                                        <p style={{ color: '#121212' }} className='icoOption' >All</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {ICOS}
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default AdminICO
