import React, { useState } from "react";
import QRCodeViewer from "../QRCode/QRCode";

const BTCDeposit = ({ selected, handleChange }) => {
  const tooltipValue = "bc1qglnc8zj7exepsc3k43u04njzf2e0zksw4f2cd7";

  const [hasCopiedText, setHasCopiedText] = useState(false);

  const copyText = () => {
    navigator.clipboard.writeText(tooltipValue);
    setHasCopiedText(true);
  };

  return (
    <div className="withdrawalDetails depositDetailsBox">
      <div className="withdrawalLeft">
        <p className="withdrawalLeftText">Please select a currency</p>
        <select
          className="currencySelect"
          value={selected}
          onChange={handleChange}>
          <option value="BTC" className="withdrawalOptions">
            BTC
          </option>
          <option value="ETH" className="withdrawalOptions">
            ETH
          </option>
          <option value="USDT" className="withdrawalOptions">
            USDT
          </option>
        </select>
        <div className="moneyBox">
          <div className="balanceBox">
            <p className="availableBalance">Available Balance</p>
            <p className="ethBal">
              0 BTC <i className="ri-refresh-line spin"></i>
            </p>
          </div>
          <div className="balanceBox">
            <p className="availableBalance">Minimum recharge amount</p>
            <p className="ethBal">0.0001 BTC </p>
          </div>
        </div>
        <div className="withdrawalDescBox">
          <p className="withdrawalDesc">
            Do not recharge any non-currency assets to the above address,
            otherwise the assets will not be recovered. After you recharge to
            the above address, you need to confirm the entire network node,
            after 2 network confirmations arrive, after 6 network confirmation
            can be extracted.
          </p>
          <p className="withdrawalDesc">
            Minimum recharge amount: 0.0001 BTC , recharge less than the minimum
            amount will not be accounted.
          </p>
          <p className="withdrawalDesc">
            Your top-up address will not change frequently and you can repeat
            the recharge; if there is any change, we will try to notify you via
            website announcement or email.
          </p>
        </div>
      </div>
      <div className="withdrawalRight">
        <div className="DepositRightHeader">
          <p className="withdrawalLeftText">Please select a currency</p>
          <p className="DepositRightHeaderWarning">
            Only recharge is allowed BTC
          </p>
        </div>
        <div className="DepositQrCodeBox">
          <div className="DepositQrCodeHolder">
            <div className="qrInnerBox">
              <QRCodeViewer text={tooltipValue} />
            </div>
            <p className="qrCode">{tooltipValue}</p>
            <div className="copyBtnParent">
              {hasCopiedText ? (
                <button className="copyBtn">Copied!</button>
              ) : (
                <button onClick={copyText} className="copyBtn">
                  Copy
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*Second*/}
    </div>
  );
};

export default BTCDeposit;
