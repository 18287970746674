import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import logo from '../../Assets/primary logotype-01.png'
import { TailSpin } from 'react-loading-icons';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword } from '../../Redux/User/userAction';

const ForgotPassword = () => {

    const [isEmailEmpty, setIsEmailEmpty] = useState(true);

    const [email, setEmail] = useState("");

    const [err, setErr] = useState('')
    const msg = useSelector(state => state.user.message)
    const isLoading = useSelector(state => state.user.isLoading)

    const userEmail = {
        email
    }


    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email.length === 0) {
            setIsEmailEmpty(false);
            return;
        }
        else {
            setIsEmailEmpty(true);
            dispatch(forgotPassword(userEmail))
        }
    };

    const navigate = useNavigate()


    useEffect(() => {
        setErr(msg)
    }, [setErr, msg])

    return (
        <div className="loginContainer">
            <div className="backArrowBox" onClick={() => navigate("/")}>
                <i className="ri-arrow-left-line backArrow"></i>
            </div>

            <div className="loginBox">
                <div className="logoHolder">
                    <Link to='/' className='brand' >
                        <img className='logoImg' src={logo} alt='logo' />
                    </Link>
                </div>
                <div className="formContainer">
                    <form className="loginForm">
                        <div className="inputContainer">
                            <label className="loginLabel">Email</label>
                            <input
                                className="loginInput"
                                type="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                onBlur={(e) => {
                                    if (e.target.value.length === 0) {
                                        e.target.style.border = "1px solid red";
                                        setIsEmailEmpty(false);
                                    } else {
                                        setIsEmailEmpty(true);
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.style.border = "1px solid #535353";
                                }}
                            />
                            {!isEmailEmpty && <p className="errMsg">Email</p>}
                        </div>


                        {isLoading ? <button className='signInBtn signUp'><TailSpin width={20} stroke="#fff" /></button> : <button onClick={handleSubmit} className="signInBtn">Submit</button>}

                        <p className='msg' >{err}</p>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ForgotPassword
