import CaroselImage1 from '../../Assets/flexbanner1.png'
import CaroselImage2 from '../../Assets/flexbanner2.png'
import CaroselImage3 from '../../Assets/flexbanner3.png'
 

export const CaroselCardData = [
    {
        id: 0,
        img: CaroselImage1
    },
    {
        id: 1,
        img: CaroselImage2
    },
    {
        id: 2,
        img: CaroselImage3
    },
    {
        id: 3,
        img: CaroselImage1
    },
    {
        id: 4,
        img: CaroselImage2
    },
    {
        id: 5,
        img: CaroselImage3
    }
]