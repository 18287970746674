import React from 'react'
import './EmptyComponent.css'

const EmptyComponent = () => {
    return (
        <div className='emptyComponent'>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_19_2)">
                    <path d="M71.986 12.435L24.25 7.41802C23.195 7.30719 22.1391 7.61999 21.3147 8.28761C20.4903 8.95524 19.9649 9.923 19.854 10.978L14.419 62.693C14.3082 63.748 14.621 64.8039 15.2886 65.6283C15.9562 66.4527 16.924 66.9781 17.979 67.089L65.716 72.107C66.771 72.2179 67.8269 71.9051 68.6513 71.2374C69.4757 70.5698 70.0011 69.602 70.112 68.547L75.547 16.832C75.602 16.3095 75.5536 15.7814 75.4044 15.2776C75.2553 14.7739 75.0084 14.3044 74.6778 13.8961C74.3472 13.4878 73.9394 13.1486 73.4777 12.8979C73.016 12.6472 72.5095 12.4899 71.987 12.435H71.986Z" fill="#1A284B" />
                    <g filter="url(#filter0_d_19_2)">
                        <path d="M56 16H8C6.93913 16 5.92172 16.4214 5.17157 17.1716C4.42143 17.9217 4 18.9391 4 20V72C4 73.0609 4.42143 74.0783 5.17157 74.8284C5.92172 75.5786 6.93913 76 8 76H56C57.0609 76 58.0783 75.5786 58.8284 74.8284C59.5786 74.0783 60 73.0609 60 72V20C60 18.9391 59.5786 17.9217 58.8284 17.1716C58.0783 16.4214 57.0609 16 56 16V16Z" fill="#0E1A30" />
                    </g>
                    <path d="M56 16H8C6.93913 16 5.92172 16.4214 5.17157 17.1716C4.42143 17.9217 4 18.9391 4 20V72C4 73.0609 4.42143 74.0783 5.17157 74.8284C5.92172 75.5786 6.93913 76 8 76H56C57.0609 76 58.0783 75.5786 58.8284 74.8284C59.5786 74.0783 60 73.0609 60 72V20C60 18.9391 59.5786 17.9217 58.8284 17.1716C58.0783 16.4214 57.0609 16 56 16V16Z" fill="#243464" />
                    <path d="M51 40H13C12.7348 40 12.4804 40.1054 12.2929 40.2929C12.1054 40.4804 12 40.7348 12 41V43C12 43.2652 12.1054 43.5196 12.2929 43.7071C12.4804 43.8946 12.7348 44 13 44H51C51.2652 44 51.5196 43.8946 51.7071 43.7071C51.8946 43.5196 52 43.2652 52 43V41C52 40.7348 51.8946 40.4804 51.7071 40.2929C51.5196 40.1054 51.2652 40 51 40ZM51 26H27C26.7348 26 26.4804 26.1054 26.2929 26.2929C26.1054 26.4804 26 26.7348 26 27V31C26 31.2652 26.1054 31.5196 26.2929 31.7071C26.4804 31.8946 26.7348 32 27 32H51C51.2652 32 51.5196 31.8946 51.7071 31.7071C51.8946 31.5196 52 31.2652 52 31V27C52 26.7348 51.8946 26.4804 51.7071 26.2929C51.5196 26.1054 51.2652 26 51 26Z" fill="#36497C" />
                    <path d="M20 24H14C13.4696 24 12.9609 24.2107 12.5858 24.5858C12.2107 24.9609 12 25.4696 12 26V32C12 32.5304 12.2107 33.0391 12.5858 33.4142C12.9609 33.7893 13.4696 34 14 34H20C20.5304 34 21.0391 33.7893 21.4142 33.4142C21.7893 33.0391 22 32.5304 22 32V26C22 25.4696 21.7893 24.9609 21.4142 24.5858C21.0391 24.2107 20.5304 24 20 24Z" fill="#357CE1" />
                    <path d="M51 48H13C12.7348 48 12.4804 48.1054 12.2929 48.2929C12.1054 48.4804 12 48.7348 12 49V51C12 51.2652 12.1054 51.5196 12.2929 51.7071C12.4804 51.8946 12.7348 52 13 52H51C51.2652 52 51.5196 51.8946 51.7071 51.7071C51.8946 51.5196 52 51.2652 52 51V49C52 48.7348 51.8946 48.4804 51.7071 48.2929C51.5196 48.1054 51.2652 48 51 48ZM51 56H13C12.7348 56 12.4804 56.1054 12.2929 56.2929C12.1054 56.4804 12 56.7348 12 57V59C12 59.2652 12.1054 59.5196 12.2929 59.7071C12.4804 59.8946 12.7348 60 13 60H51C51.2652 60 51.5196 59.8946 51.7071 59.7071C51.8946 59.5196 52 59.2652 52 59V57C52 56.7348 51.8946 56.4804 51.7071 56.2929C51.5196 56.1054 51.2652 56 51 56Z" fill="#36497C" />
                </g>
                <defs>
                    <filter id="filter0_d_19_2" x="-6" y="8" width="76" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="5" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_2" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_2" result="shape" />
                    </filter>
                    <clipPath id="clip0_19_2">
                        <rect width="80" height="80" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <p className='emptyText'>No data yet</p>
        </div>

    )
}

export default EmptyComponent
