import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../Assets/primary horizontal-01.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footerBox1">
          <Link to="/" className="brand">
            <img className="logoImg" src={logo} alt="logo" />
          </Link>
          <div className="copyRightBox">
            <p className="copyRightText">The Best Cryptocurrency</p>
            <p className="copyRightText">
              Copyright © 2021 - quatumn All rights reserved.
            </p>
          </div>
        </div>
        <div className="aboutUsBox">
          <ul className="footerListParent">
            <li className="footerList">
              <Link to="/info#about" className="footerLink">
                About Us
              </Link>
            </li>
            <li className="footerList">
              <Link to="/info#disclaimer" className="footerLink">
                Service Policy
              </Link>
            </li>
            <li className="footerList">
              <Link to="/info#policy" className="footerLink">
                Conditions
              </Link>
            </li>
            <li className="footerList">
              <Link to="/info#privacy" className="footerLink">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
