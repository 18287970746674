import React from "react";
import Footer from "../../Components/Footer/Footer";
import HomeNavBar from "../../Components/HomeNavBar/HomeNavBar";
import { useNavigate } from "react-router-dom";
import DepositRecords from "../../Components/DepositRecords/DepositRecords";
import DepositDetails from "../../Components/DepositDetails/DepositDetails";

// this is the api for users to make deposit
// https://api.quatumn.com/deposit

//this is the api for users to view their own deposit
// https://api.quatumn.com/deposit/:user_id

const Deposit = () => {
  const navigate = useNavigate();

  return (
    <div className="withdrawalBody">
      <div className="linearGradientBody">
        <HomeNavBar gradient={true} />
        <div className="withdrawContainer">
          <div className="arrowBox" onClick={() => navigate(-1)}>
            <i className="ri-arrow-left-line backArw"></i>
          </div>
          <h2 className="withdraw">Deposit</h2>
        </div>
      </div>
      <div className="withdrawals">
        <DepositDetails />
        <DepositRecords />
      </div>
      <Footer />
    </div>
  );
};

export default Deposit;
