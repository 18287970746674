import React from 'react'
import './ICOCard.css'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { fetchIcoDetails } from '../../Redux/User/userAction';
import { useDispatch, useSelector } from 'react-redux';

const ICOCard = ({ data }) => {

    const { token } = useSelector(state => state.user.userDetails)

    let percentage = 0;
    if (data?.progress_bar === null) {
        percentage = 0
    }
    else {
        percentage = data?.progress_bar;
    }


    const dispatch = useDispatch()

    const navigate = useNavigate()

    const fetchDetails = () => {
        dispatch(fetchIcoDetails(data.id, token))
        navigate(`/ico/${data.coin_name}`)
    }

    const startDate = new Date(data.start_date).toLocaleDateString("en-US")
    const endDate = new Date(data.end_date).toLocaleDateString("en-US")


    return (
        <div className='icoCardBody' onClick={fetchDetails} >
            <div className='cardDescItems' >
                <img src={data.image_url} className='icoImg' alt={data.coin_name} />
                <div className='icoDesc' >
                    <div className='adminIcoCardProgressLength progressbarLength' >
                        <div className='progressBar' style={{ width: `${percentage}%` }} ></div>
                    </div>
                    <div className='icoNameBox' >
                        <p className='icoName' >{data.coin_name}</p>

                    </div>
                    <div className='icoSubs'>
                        <p className='icoSubName' >Circulation:</p>
                        <p className='coinAmount' >{data.circulation} {data.name}</p>
                    </div>
                    <div className='icoSubs'>
                        <p className='icoSubName'>Price:</p>
                        <p className='cryptoCoinAmount' >{data.price} <span className='icoSubName'> USDT</span></p>
                    </div>
                    <div className='icoSubs'>
                        <p className='icoSubName'>{startDate}</p> <span className='icoSubName' >~</span>
                        <p className='icoSubName'>{endDate}</p>
                    </div>
                </div>
            </div>
            <div className='cardPercentage' >
                <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
        </div>
    )
}

export default ICOCard
