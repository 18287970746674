import React, { useState, useEffect } from 'react'
import Footer from '../../Components/Footer/Footer'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import { useSelector, useDispatch } from 'react-redux'
import { TailSpin } from "react-loading-icons";
import { userUpdatePhoneNumber, offMessage } from '../../Redux/User/userAction'
import { useNavigate } from 'react-router-dom'

import './BindPhone.css'

const BindPhone = () => {

    const message = useSelector(state => state.user.message)
    const userId = useSelector(state => state.user.userDetails.user_id)
    const isLoading = useSelector(state => state.user.isLoading)
    const { token } = useSelector(state => state.user.userDetails)

    const dispatch = useDispatch()

    const [isTeleEmpty, setIsTeleEmpty] = useState(true)
    const [err, setErr] = useState('')


    const [phone_number, setTel] = useState('')

    const phoneNumber = {
        phone_number
    }


    const handleSubmit = e => {
        e.preventDefault()
        if (phone_number.length === 0) {
            setIsTeleEmpty(false)
            return
        }


        else if (phone_number.length === 0) {
            setIsTeleEmpty(false)
        }
        else {
            dispatch(userUpdatePhoneNumber(userId, token, phoneNumber))
        }
    }

    useEffect(() => {
        setErr(message)
    }, [setErr, message])


    const navigate = useNavigate()

    const goBack = () => {
        dispatch(offMessage())
        navigate(-1)
    }

    return (
        <div className='withdrawalBody'>
            <div className='linearGradientBody'>
                <HomeNavBar gradient={true} />
                <div className='withdrawContainer'>
                    <div className='arrowBox' onClick={goBack} >
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className='withdraw'>Bind telephone</h2>
                </div>
            </div>
            <div className='withdrawals setFundPassword'>
                <div className='telephoneBox fundPasswordbox modifyLogin' >
                    <div className='emailRegister bindteleBox '>
                        <form onSubmit={handleSubmit}>
                            <p className='activityInfo' >Activity Info</p>
                            <div className='inputContainer'>

                                <input className='loginInput' type='text' placeholder='Please enter phone number'
                                    onChange={e => setTel(e.target.value)} value={phone_number}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            e.target.style.border = '1px solid red'
                                            setIsTeleEmpty(false)
                                        }
                                        else {
                                            setIsTeleEmpty(true)
                                        }
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.border = '1px solid #535353'
                                    }}
                                />
                                {!isTeleEmpty && <p className='errMsg'>Please telephone number</p>}
                            </div>{
                                isLoading ?
                                    <button className='signInBtn signUp'><TailSpin width={20} stroke="#fff" /></button>
                                    :
                                    <button className='signInBtn signUp'>Bind</button>
                            }


                        </form>
                        <p className='msg' >{err}</p>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BindPhone
