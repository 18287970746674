import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppDesc from '../../Components/AppDesc/AppDesc'
import Carosel from '../../Components/Carosel/Carosel'
import Footer from '../../Components/Footer/Footer'
import HeroSection from '../../Components/HeroSection/HeroSection'
import MarketTrends from '../../Components/MarketTrends/MarketTrends'
import { fetchAllIco, fetchAllCoins } from '../../Redux/User/userAction'
import './Home.css'
import MobileMarketTrends from '../../Components/MobileMarketTrends/MobileMarketTrends'
import MobileNavBar from '../../Components/MobileNavBar/MobileNavBar'



const Home = () => {

    const total = 10

    const { token } = useSelector(state => state.user.userDetails)
    const isSignedIn = useSelector(state => state.user.isSignedIn)
    // const allCoins = useSelector(state => state.user.allCoins)
    // const icos = useSelector(state => state.user.icos)

    const dispatch = useDispatch()


    // useEffect(() => {
    //     if (isSignedIn && icos?.length === 0) {
    //         dispatch(fetchAllIco(token))
    //     }
    //     if (allCoins?.length === 0) {
    //         dispatch(fetchAllCoins(total))
    //     }

    // }, [token, isSignedIn, total, allCoins, icos, dispatch])

    useEffect(() => {
        if (isSignedIn) {
            dispatch(fetchAllIco(token))
        }
        dispatch(fetchAllCoins(total))
    }, [token, isSignedIn, total, dispatch])


    return (
        <div className='home'>
            <HeroSection />

            <Carosel />
            <MarketTrends showSeach={false} />
            <MobileMarketTrends showSearch={false} />
            <AppDesc />
            <Footer />
            <MobileNavBar />
        </div>
    )
}

export default Home
