import React from 'react'
import { NavLink } from 'react-router-dom'
import './MobileNavBar.css'
import { useSelector } from 'react-redux'

const MobileNavBar = () => {

    const selectedCoin = useSelector(state => state.user.selectedCoin)

    return (
        <div className='mobileNavBarHolder' >
            <div className='mobileNavBar'>
                <NavLink to='/' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-home-2-fill navIcon"></i>
                        <p className='mobileNavLink'>Home</p>
                    </div>
                </NavLink>
                <NavLink to='/market' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-building-line navIcon"></i>
                        <p className='mobileNavLink'>Market</p>
                    </div>
                </NavLink>
                <NavLink to={`/exchanges/${selectedCoin}`} className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-exchange-fill navIcon"></i>
                        <p className='mobileNavLink'>Exchange</p>
                    </div>
                </NavLink>
                {/*<NavLink to='/assets' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-wallet-fill navIcon"></i>
                        <p className='mobileNavLink'>Assets</p>
                    </div>
            </NavLink>*/}
                <NavLink to='/ico' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-archive-fill navIcon"></i>
                        <p className='mobileNavLink'>ICO</p>
                    </div>
                </NavLink>

                <NavLink to='/user/profile' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox' >
                        <i className="ri-user-3-fill navIcon"></i>
                        <p className='mobileNavLink'>Profile</p>
                    </div>
                </NavLink>


            </div>
        </div>
    )
}

export default MobileNavBar
