import React from 'react'
import './DeleteModal.css'
import { useSelector, useDispatch } from 'react-redux'
import { adminDeleteFinance } from '../../Redux/User/userAction'

    
    

const DeleteModal = ({ closeDeleteModal }) => {
    

    const dispatch = useDispatch()
    const financeId = useSelector(state => state.user?.deleteId)
    const { token } = useSelector(state => state.user.userDetails)


    const deleteFinance = () => {
        dispatch(adminDeleteFinance(financeId, token))
        closeDeleteModal()
    }
    

    return (
        <div className='deleteModal' >
            <div className="deleteCard">
                <div>
                    <h3 className="deleteQuestion">Are you sure you want to delete this finance?</h3>
                    <div className="deleteOptions">
                        <button onClick={deleteFinance} className="deleteYes">Yes</button>
                        <p className="cancelDeleteText"  onClick={closeDeleteModal} >No</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default DeleteModal
