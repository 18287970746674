import React, { useEffect, useRef } from 'react';
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import { useSelector } from 'react-redux';
import './Exchanges.css'
import OfflineView from '../../Components/OfflineView/OfflineView';
import Footer from '../../Components/Footer/Footer';
import MobileNavBar from '../../Components/MobileNavBar/MobileNavBar';

let tvScriptLoadingPromise;

const Exchange = () => {

  const selectedCoin = useSelector(state => state.user.selectedCoin)

  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_4fc62') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            width: 1000,
            height: 1000,
            // symbol: "NASDAQ:AAPL",
            symbol: selectedCoin,
            interval: "D",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: "tradingview_4fc62"
          });
        }
      }
    },
    [selectedCoin]
  );

  return (
    <div className='ExchangePageParent'>
      <HomeNavBar gradient={false} />
      <MobileNavBar />
      <div className='exchangePageContainer' >
        <div className='exchangeContainer' >
          <div id='tradingview_4fc62' />
          <div className="tradingview-widget-copyright">
            <a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noreferrer" target="_blank"></a>
          </div>
        </div>
        <OfflineView />

      </div>
      <Footer />
    </div>

  );
}


export default Exchange