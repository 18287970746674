import React, { useEffect } from 'react'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import './ICO.css'
// import { Link } from 'react-router-dom'
import MobileNavBar from '../../Components/MobileNavBar/MobileNavBar'
import ICOCard from '../../Components/ICOCard/ICOCard'
import Footer from '../../Components/Footer/Footer'
import Loading from '../../Components/Loading/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllIco } from '../../Redux/User/userAction'

const ICO = () => {

    const dispatch = useDispatch()

    const icos = useSelector(state => state.user.icos)
    const isLoading = useSelector(state => state.user.isLoading)
    const token = useSelector(state => state.user.userDetails.token)

    let ICOS = <Loading />

    useEffect(() => {

        // if (icos?.length === 0) {
        dispatch(fetchAllIco(token))
        // }

        // }, [dispatch, icos, token])
    }, [dispatch, token])

    if (!isLoading) {
        ICOS =
            icos.map((data) => {
                return <ICOCard key={data.id} data={data} />
            })
    }

    return (
        <div>
            <div className='icoPage' >
                <div className='navholder' >
                    <HomeNavBar gradient={false} />
                </div>
                <MobileNavBar />
                <div className='icoBody icoHoldingBox'>
                    <div className='icoBodyParent' >

                        <div className='icoHolder' >
                            <div className='icoHeaders' >
                                <div className='icoLeftOptions' >
                                    <div style={{ background: '#FFB331' }} className='icoOptionBox'>
                                        <p style={{ color: '#121212' }} className='icoOption' >All</p>
                                    </div>


                                </div>
                                {/*<div className='icoRightHolder' >
                                <i className="ri-file-list-2-line transactionOrderIcon "></i>
                                <Link className='transactionOrder' to='/ico/lab/order' >First transaction order</Link>
                            </div>*/}
                            </div>
                        </div>
                        <div className='icoCardHolder' style={{ display: (isLoading) ? 'flex' : 'grid' }} >
                            {
                                ICOS
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ICO
