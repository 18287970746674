import React, { useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import { useNavigate, Link } from 'react-router-dom'
import './PlatformInformation.css'

const PlatformInformation = () => {

    const [isFirstClicked, setIsFirstClicked] = useState(true)
    const [isSecondClicked, setIsSecondClicked] = useState(false)
    const [isThirdClicked, setIsThirdClicked] = useState(false)
    const [isLastClicked, setIsLastClicked] = useState(false)

    const makeFirstClicked = () => {
        setIsFirstClicked(true)
        setIsSecondClicked(false)
        setIsThirdClicked(false)
        setIsLastClicked(false)
    }
    const makeSecondClicked = () => {
        setIsFirstClicked(false)
        setIsSecondClicked(true)
        setIsThirdClicked(false)
        setIsLastClicked(false)
    }
    const makeThirdClicked = () => {
        setIsFirstClicked(false)
        setIsSecondClicked(false)
        setIsThirdClicked(true)
        setIsLastClicked(false)
    }
    const makeLastClicked = () => {
        setIsFirstClicked(false)
        setIsSecondClicked(false)
        setIsThirdClicked(false)
        setIsLastClicked(true)
    }

    const navigate = useNavigate()

    const about = <div>
        <h1 id='about' >About us</h1>
        <p className='aboutDesc' >quatumn is the world's leading digital asset comprehensive platform, a full-stop platform that integrates digital asset quotes, transactions, alerts, and reminders. Users do not need to register additionally, realize the in-depth sharing of major exchanges in currency trading, and automatically select the best transaction price among several exchanges for users, realizing "fastest transaction, best price"
        </p>
    </div>

    const disclaimer = <div>
        <p id='disclaimer'>Disclaimer</p>

        <p className='aboutDesc'>Risk tips</p>

        <p className='aboutDesc'>
            1. Digital assets involve major risks, and there are many uncertain factors in market transactions. At the same time, because the total market value of digital currency is generally less, the price is vulnerable to the influence of banker control and global government policies, resulting in large fluctuations. You may have a large profit or loss due to price fluctuation. Any digital asset can fluctuate dramatically, or even become worthless.
        </p>

        <p className='aboutDesc' >
            2. Due to the formulation or modification of national laws, regulations and normative documents, digital asset transactions may be suspended or prohibited at any time. When users use our service, please assess the risk according to local laws, regulations and policy documents.
        </p>

        <p className='aboutDesc' >
            3. When users participate in digital asset transactions, they should carefully consider and evaluate the investment value and investment risk of digital assets according to their own financial situation, control the transaction risk on their own, and bear the economic risk of possible loss of all investment.
        </p>

        <p className='aboutDesc' >
            Legal statement
        </p>

        <p className='aboutDesc' >
            1、 Ownership of rights
        </p>

        <p className='aboutDesc' >
            Unless otherwise stated by our platform, all rights (including copyright, trademark right, patent right, trade secret and other related rights) of all products, technologies, software, programs, data and other information (including text, icon, picture, audio, video, chart, color combination, layout, etc.) in our platform belong to our service provider and / or its affiliates Owned by the company. Without the permission of our service provider and / or its affiliates, no one is allowed to use any content in our platform by means of monitoring, copying, disseminating, displaying, mirroring, uploading, downloading, etc. through robots, spiders and other programs or devices.
        </p>

        <p className='aboutDesc' >
            Our platform text and logo, as well as other logos, emblems, product and service names of our platform are trademarks of our service provider and / or its affiliates. If there is any need for publicity, display and other use, you must obtain the prior written authorization of our service provider and / or its affiliates.
        </p>

        <p className='aboutDesc' >
            2、 Limitation of liability
        </p>

        <p className='aboutDesc' >
            Whereas our platform is an online trading platform with digital assets for trading digital assets and derivatives. As a third-party platform of "network service provider", we does not guarantee that the information and services on the website platform can fully meet the needs of users. The works reprinted by our platform (including user comments) for the purpose of transmitting more information do not mean that we agree with their opinions or have confirmed the authenticity of their contents.
        </p>

        <p className='aboutDesc' >
            1. Users understand and agree that our services are provided in accordance with the existing technology and conditions. We will do its best to provide services to users to ensure the consistency and security of services; however, We cannot foresee and prevent legal, technical and other risks at any time, including but not limited to service interruption, data loss and other possible causes caused by force majeure, viruses, Trojans, hacker attacks, system instability, third-party service defects, government behavior and other reasons Loss and risk. We will be exempt from any liability for damages if the user cannot use the services due to the following circumstances: but not limited to:
        </p>

        <p className='aboutDesc' >
            1) . system shutdown maintenance period announced by our platform;
        </p>

        <p className='aboutDesc' >
            2) . the telecommunication equipment fails to transmit data;
        </p>

        <p className='aboutDesc' >
            3) . our platform system fails to operate normally due to force majeure such as typhoon, earthquake, tsunami, flood, power failure, war, terrorist attack, etc;
        </p>

        <p className='aboutDesc' >
            4) . service interruption or delay caused by hacker attack, computer virus invasion or attack, technical adjustment or failure of telecommunication department, website upgrade, banking problems, temporary shutdown caused by government control and other reasons affecting the normal operation of the network;
        </p>

        <p className='aboutDesc' >
            5) . losses caused by technical problems that cannot be predicted or solved by the existing technical forces of the industry;
        </p>

        <p className='aboutDesc' >
            6) . losses caused to users or other third parties due to the fault or delay of the third party.
        </p>

        <p className='aboutDesc' >
            We have the right to cancel the abnormal transaction results and roll back all transactions in a certain period of time according to the actual situation due to unexpected factors such as system failure, network reasons, DDoS and other hacker attacks.
        </p>

        <p className='aboutDesc' >
            2. The user has fully understood the transaction risk when using the our service and agrees that in any case, we will not assume any liability for the following matters:
        </p>
        <p className='aboutDesc' >
            1) Loss of income;
        </p>


        <p className='aboutDesc' >
            2) Trading profit or contract loss;
        </p>

        <p className='aboutDesc' >
            3) Business interruption;
        </p>

        <p className='aboutDesc' >
            4) Loss of money expected to be saved;
        </p>

        <p className='aboutDesc' >
            5) Loss of information;
        </p>

        <p className='aboutDesc' >
            6) Loss of opportunity, goodwill or reputation;
        </p>

        <p className='aboutDesc' >
            7) Data damage or loss;
        </p>

        <p className='aboutDesc' >
            8) The cost of purchasing alternative products or services;
        </p>

        <p className='aboutDesc' >
            9) Digital assets die out or exit the market.
        </p>
    </div>

    const policy = <div>

        <h1 id='policy' >
            Service Policy
        </h1>

        <p className='aboutDesc' >
            1. General Provisions
        </p>

        <p className='aboutDesc' >
            1.1 The User Agreement (hereinafter referred to as "this Agreement" or "these terms and conditions") consists of the main body, Terms of Privacy, Understanding Your client and Anti-money-laundering Policy, as well as any rules, statements, instructions, etc. that this Website has published or may publish in the future.
        </p>

        <p className='aboutDesc' >
            1.2 Before using the services offered by this Website, you shall read this Agreement carefully, and consult a professional lawyer if you have any doubt or as may be otherwise necessary. If you do not agree to the terms and conditions of this Agreement and/or any change made thereto from time to time and at any time, please immediately stop using the service provided by this Website or stop logging onto this Website. Upon your logging into this Website or using any service offered by this Website or engaging in any other similar activity, it shall be deemed as having understood and fully agreeing to all terms and conditions of this Agreement, including any and all changes, modifications or alterations that this Website may make to this Agreement from time to time and at any time.
        </p>

        <p className='aboutDesc' >
            1.3 After filling in the relevant information in accordance with the requirements of this Website, and going through other relevant procedures, you will successfully register yourself as a member of this Website (hereinafter referred to as "Member"); in the process of registration, if you click on the "I Agree", it shall be deemed that you have reached an agreement with the Company by way of electronic signature; or when you use this Website, you click on the "I Agree" button or a similar button, or if you use the services offered by this Website in any of the ways allowed by this Website, it shall be deemed that you fully understand, agree to and accept all the terms and conditions under this Agreement, and in this case, the absence of your handwritten signature will not affect the legal binding force that this Agreement may have on you.
        </p>

        <p className='aboutDesc' >
            1.4 After you become a member of this Website, you will receive a member account and corresponding password, which shall be properly kept by you as a member of this Website; Members shall be liable for all activities and events carried out through their accounts.
        </p>

        <p className='aboutDesc' >
            1.5 You cannot engage in trading on the digital asset trading platform provided by this Website and gain access to the services that are exclusively available to members in accordance with the rules and regulations of this Website, unless and until you become a member of this Website; if you are not a member of this Website, you can only log in to and browse the Website and have access to other services as are permitted by the rules and regulations of this Website.
        </p>

        <p className='aboutDesc' >
            1.6 Upon registering yourself as a member of this Website and using any of the services and functions offered by this Website, it shall be deemed that you have read, understood this Agreement, and:
        </p>

        <p className='aboutDesc' >
            1.6.1 accepted to be bound by all terms and conditions of this Agreement;
        </p>

        <p className='aboutDesc' >
            1.6.2 You confirm that you have attained the age of 16, or another statutory age for entering into contracts as is required by a different applicable law, and your registration with this Website, purchase or sale via this Website, release information on this Website and other behaviors indicating your acceptance of the Services offered by this Website shall comply with the relevant laws and regulations of the sovereign state or region that has jurisdiction over you, and you confirm that you have sufficient capacity to accept these terms and conditions, enter into transactions and to use this Website for digital asset transactions.
        </p>

        <p className='aboutDesc' >
            1.6.3 You undertake that all your digital assets involved in transactions hereunder are legally acquired and owned by you.
        </p>

        <p className='aboutDesc' >
            1.6.4 You agree to undertake any and all liabilities for your own transaction and non-transaction activities as well as any and all profits and losses therefrom.
        </p>

        <p className='aboutDesc' >
            1.6.5 You confirm that the information provided at the time of registration is true and accurate.
        </p>

        <p className='aboutDesc' >
            1.6.6 You agree to comply with any and all relevant laws, including the reporting of any transaction profits for tax purposes.
        </p>

        <p className='aboutDesc' >
            1.6.7 You agree to always refrain from engaging in or participating in any act or activity that damages the interests of this Website or the Company, whether or not in connection with the Services provided by this Website.
        </p>

        <p className='aboutDesc' >
            1.6.8 This Agreement is only binding on the rights and obligations between you and us, and does not involve legal relations and legal disputes arising from and relating to the transaction of digital assets between the users of this Website, and between other websites and you.
        </p>

        <p className='aboutDesc' >
            2. Amendment of this Agreement
        </p>

        <p className='aboutDesc' >
            We reserve the right to amend this Agreement from time to time, and disclose such amendment by way of announcement on the Website without sending a separate notice to you on your rights. The date when the amendment is made will be indicated on the first page of the amended agreement. The amended agreement will take effect immediately upon announcement on the Website. You shall browse this Website from time to time and follow information on the time and content of amendments, if any, made to this Agreement. If you do not agree with the amendments, you shall stop using the services offered by this Website immediately; if you continue to use the services offered by this Website, it shall be deemed that you accept and agree to be bound by the amended agreement.
        </p>

        <p className='aboutDesc' >
            3.Registration
        </p>

        <p className='aboutDesc' >
            3.1 Eligibility for Registration You confirm and promise that: you shall be a natural person, legal person or other organization with the ability to sign this Agreement and the ability to use the services of this Website, as is provided by applicable laws, when you complete the registration process or when you use the services offered by this Website in any other manner as is otherwise permitted by this Website. Upon clicking on the button indicating that you agree to register, it shall be deemed that you yourself or your authorized agent agrees to the content of this Agreement and your authorized agent will register with this Website and use the services offered by this Website on your behalf. If you are not a natural person, legal person or organization with the abovementioned ability, you and your authorized agent shall bear all the consequences of that, and the company reserves the right to cancel or permanently freeze your account and to hold you and your authorized agent accountable.
        </p>

        <p className='aboutDesc' >
            3.2 Purpose of Registration You confirm and promise that you do not register with this Website for the purpose of violating any of the applicable laws or regulations or undermining the order of digital asset transactions on this Website.
        </p>

        <p className='aboutDesc' >
            3.3 Registration Process
        </p>

        <p className='aboutDesc' >
            3.3.1 You agree to provide a valid email address, a mobile phone number and other information in accordance with the requirements on the user registration page of this Website. You can use the email address, mobile phone number or any other manner permitted by this Website to log in to this Website. Where it is necessary and in accordance with the requirements of applicable laws and regulations of relevant jurisdictions, you shall provide your real name, identity card and other information required by applicable laws, regulations, the Terms of Privacy, and anti-money-laundering terms, and constantly update your registration data so that they will be timely, detailed and accurate as is required. All of the original typed data will be referenced as registration information. You shall be responsible for the authenticity, integrity and accuracy of such information and bear any direct or indirect loss and adverse consequences arising out of it.
        </p>

        <p className='aboutDesc' >
            3.3.2 If any of the applicable laws, regulations, rules, orders and other regulatory documents of the sovereign country or region in which you are based requires that mobile phone accounts must be based on real names, you hereby confirm that the mobile phone number you provide for registration purposes has gone through the real-name registration procedure. If you cannot provide such a mobile phone number as is required, any direct or indirect losses and adverse consequences arising therefrom and affecting you shall be borne by you.
        </p>

        <p className='aboutDesc' >
            3.3.3 After you provide the required registration information in a legal, complete and valid manner and such information passes relevant verification, you shall have the right to obtain an account and a password of this Website. Upon obtaining such account and password, your registration shall be deemed as successful and you can log into this Website as a member thereof.
        </p>

        <p className='aboutDesc' >
            3.3.4 You agree to receive emails and/or short messages sent by this Website related to the management and operation thereof.
        </p>

        <p className='aboutDesc' >
            4．Services
        </p>

        <p className='aboutDesc' >
            This Website only provides online transaction platform services for you to engage in digital asset trading activities through this Website (including but not limited to the digital asset transactions etc.). This Website does not participate in the transaction of digital assets as a buyer or seller; This Website does not provide any services relating to the replenishment and withdrawal of the legal currency of any country.
        </p>

        <p className='aboutDesc' >
            4.1 Content of Services
        </p>

        <p className='aboutDesc' >
            4.1.1 You have the right to browse the real-time quotes and transaction information of digital asset products on this Website, to submit digital asset transaction instructions and to complete the digital asset transaction through this Website.
        </p>

        <p className='aboutDesc' >
            4.1.2 You have the right to view information under the member accounts on this Website and to apply the functions provided by this Website.
        </p>

        <p className='aboutDesc' >
            4.1.3 You have the right to participate in the website activities organized by this Website in accordance with the rules of activities posted on this Website.
        </p>

        <p className='aboutDesc' >
            4.1.4 Other services that this Website promises to offer to you.
        </p>

        <p className='aboutDesc' >
            4.2 Service Rules You undertake to comply with the following service rules of this Website:
        </p>

        <p className='aboutDesc' >
            4.2.1 You shall comply with the provisions of applicable laws, regulations, rules, and policy requirements, and ensure the legality of the source of all digital assets in your account, and shall refrain from engaging in any illegal activities or other activities that damages the rights and interests of this Website or any third party, such as sending or receiving information that is illegal, illicit or infringes on the rights and interests of any other person, sending or receiving pyramid scheme information or information or remarks causing other harms, unauthorized use or falsification of the email header information of this Website, inter alia.
        </p>

        <p className='aboutDesc' >
            4.2.2 You shall comply with applicable laws and regulations and properly use and keep your account in this Website and login password, password of your financial transactions, and the mobile phone number bound with your account that you provide upon registration of your account, as well as the security of the verification codes received via your mobile phone. You shall be solely responsible for any and all your operations carried out using your account with this Website and login password, financial transaction password, verification codes sent to your mobile phone, as well as all consequences of such operations. When you find that your account with this Website, your login password, financial transaction password, or mobile phone verification codes is used by any unauthorized third party, uncover any other problem relating to the security of your account, you shall inform this Website in a prompt and effective manner, and request this Website to temporarily suspend the services to your account with this Website. This Website shall have the right to take action on your request within a reasonable time; nonetheless, this Website does not bear any liability for the consequences that have arisen before such action is taken, including but not limited to any loss that you may sustain. You may not assign your account with this Website to any other person by way of donation, lending, leasing, transfer or otherwise without the consent of this Website.
        </p>

        <p className='aboutDesc' >
            4.2.3 You agree to take responsibility for all activities (including but not limited to information disclosure, information release, online click-approving or submission of various agreements on rules, online renewal of agreements or purchase service) using your account and password with this Website.
        </p>

        <p className='aboutDesc' >
            4.2.4 In your digital asset transactions on this Website, you may not maliciously interfere with the normal proceeding of the digital asset transaction or disrupt the transaction order; you may not use any technical means or other means to interfere with the normal operation of this Website or interfere with the other users' use of the services; you may not maliciously defame the business goodwill of this Website on the ground of falsified fact.
        </p>

        <p className='aboutDesc' >
            4.2.5 If any dispute arises between you and any other user in connection with online transaction, you may not resort to any means other than judicial or governmental means to request this Website to provide relevant information.
        </p>

        <p className='aboutDesc' >
            4.2.6 All taxes payable as well as all fees relating to hardware, software and services that are incurred by you in the course of using the services provided by this Website shall be solely borne by you.
        </p>

        <p className='aboutDesc' >
            4.2.7 You shall abide by this Agreement and other terms of service and operating rules that this Website may release from time to time, and you have the right to terminate your use of the services provided by this Website at any time.
        </p>

        <p className='aboutDesc' >
            4.3 Product Rules
        </p>

        <p className='aboutDesc' >
            4.3.1 Rules for trading products You undertake that in the process in which you log into this Website and engage in transactions with other users through this Website, you will properly comply with the following transaction rules.
        </p>

        <p className='aboutDesc' >
            4.3.1.1 Browsing transaction information When you browse the transaction information on this Website, you should carefully read all the content in the transaction information, including but not limited to the price, consignment, handling fee, buying or selling direction, and you shall accept all the contents contained in the transaction information before you may click on the button to proceed with the transaction.
        </p>

        <p className='aboutDesc' >
            4.3.1.2 Submission of Commission After browsing and verifying the transaction information, you may submit your transaction commissions. After you submit the transaction commission, it shall be deemed that you authorize this Website to broker you for the corresponding transactions, and this Website will automatically complete the matchmaking operation when there is a transaction proposal that meets your price quotation, without prior notice to you.
        </p>

        <p className='aboutDesc' >
            4.3.1.3 Accessing transaction details You can check the corresponding transaction records in the transaction statements by the Management Center, and confirm your own detailed transaction records.
        </p>

        <p className='aboutDesc' >
            4.3.1.4 Revoking/modifying transaction commission. You have the right to revoke or modify your transaction commission at any time before the transaction is concluded.
        </p>

        <p className='aboutDesc' >
            5.Rights and Obligations of this Website
        </p>

        <p className='aboutDesc' >
            5.1 If you do not have the registration qualifications agreed on in this Agreement, this Website shall have the right to refuse to allow you to register; if you have already registered, this Website shall have the right to revoke your member account, and this Website reserves the right to hold you or your authorized agent accountable. Furthermore, this Website reserves the right to decide whether to accept your application for registration under any other circumstances.
        </p>

        <p className='aboutDesc' >
            5.2 When this Website finds at its sole discretion that you or your associated account user is not suitable for high-risk investment, this Website shall have the right to suspend or terminate the use of your account and all associated accounts thereof.
        </p>

        <p className='aboutDesc' >
            5.3 When this Website finds out that the user of an Account is not the initial registrant of that Account, it shall have the right to suspend or terminate the user's access to that Account.
        </p>

        <p className='aboutDesc' >
            5.4 Where by means of technical testing or manual sampling, among others, this Website reasonably suspects that the information you provide is wrong, untrue, invalid or incomplete, this Website shall have the right to notify you to correct or update the information, or suspend or terminate its supply of the services to you.
        </p>

        <p className='aboutDesc' >
            5.5 This Website shall have the right to correct any information displayed on this Website when it uncovers any obvious error in such information.
        </p>

        <p className='aboutDesc' >
            5.6 This Website reserves the right to modify, suspend or terminate the Services offered by this Website, at any time, and the right to modify or suspend the Service without prior notice to you; if this Website terminates one or more of the Services offered by this Website, such termination by this Website will take effect on the date of announcement of such termination on the Website.
        </p>

        <p className='aboutDesc' >
            5.7 This Website shall take necessary technical means and management measures to ensure the normal operation of this Website, and shall provide a necessary and reliable trading environment and transaction services, and shall maintain the order of digital assets trading.
        </p>

        <p className='aboutDesc' >
            5.8 If you fail to log into this Website using your member account number and password for an uninterrupted period of one year, this Website shall have the right to revoke your account. After your account is revoked, this Website shall have the right to offer the member name represented by such account to other applicants for membership.
        </p>

        <p className='aboutDesc' >
            5.9 This Website shall ensure the security of your digital assets by strengthening technical input and enhancing security precautions, and is under the obligation to notify you in advance of the foreseeable security risks in your account.
        </p>

        <p className='aboutDesc' >
            6.Indemnity
        </p>

        <p className='aboutDesc' >
            6.1 Under any circumstance, our liability for your direct damage will not exceed the total cost incurred by your three (3) months' use of services offered by this Website.
        </p>

        <p className='aboutDesc' >
            6.2 Shall you breach this Agreement or any applicable law or administrative regulation, you shall pay to us at least US$ Two million in compensation and bear all the expenses in connection with such breach (including attorney's fees, among others). If such compensation cannot cover the actual loss, you shall make up for the difference.
        </p>

        <p className='aboutDesc' >
            7. The Right to Injunctive Relief
        </p>

        <p className='aboutDesc' >
            Both you and we acknowledge that common law remedies for breach of agreement or possible breach of contract may be insufficient to cover all the losses that we sustain; therefore, in the event of a breach of contract or a possible breach of contract, the non-breaching party shall have the right to seek injunctive relief as well as all other remedies that are permitted under common law or equity.
        </p>

        <p className='aboutDesc' >
            8．Limitation and Exemption of Liability
        </p>

        <p className='aboutDesc' >
        </p>

        <p className='aboutDesc' >
            8.1 You understand and agree that under no circumstance will we be held liable for any of the following events:
        </p>

        <p className='aboutDesc' >
            8.1.1 loss of income;
        </p>
        <p className='aboutDesc' >
            8.1.2 loss of transaction profits or contractual losses;
        </p>

        <p className='aboutDesc' >
            8.1.3 disruption of the business
        </p>

        <p className='aboutDesc' >
            8.1.4 loss of expected currency losses
        </p>

        <p className='aboutDesc' >
            8.1.5 loss of information
        </p>

        <p className='aboutDesc' >
            8.1.6 loss of opportunity, damage to goodwill or reputation
        </p>

        <p className='aboutDesc' >
            8.1.7 damage or loss of data;
        </p>

        <p className='aboutDesc' >
            8.1.8 cost of purchasing alternative products or services;
        </p>

        <p className='aboutDesc' >
            8.1.9 any indirect, special or incidental loss or damage arising from any infringement (including negligence), breach of contract or any other cause, regardless of whether or not such loss or damage may reasonably be foreseen by us, and regardless of whether or not we are notified in advance of the possibility of such loss or damage.
        </p>

        <p className='aboutDesc' >
            8.1.10 Items 8.1.1 to 8.1.9 are independent of each other.
        </p>

        <p className='aboutDesc' >
            8.2 You understand and agree that we shall not be held liable for any damages caused by any of the following events:
        </p>

        <p className='aboutDesc' >
            8.2.1 Where we are properly justified in believing that your specific transactions may involve any serious violation or breach of law or agreement;
        </p>

        <p className='aboutDesc' >
            8.2.2 Where we are reasonably justified in believing that your conduct on this Website is suspected of being illegal or immoral;
        </p>

        <p className='aboutDesc' >
            8.2.3 The expenses and losses arising from the purchase or acquisition of any data, information or transaction, etc. through the services offered by this Website;
        </p>

        <p className='aboutDesc' >
            8.2.4 Your misunderstanding of the Services offered by this Website;
        </p>

        <p className='aboutDesc' >
            8.2.5 Any other losses related to the services provided by this Website, which cannot be attributed to us.
        </p>

        <p className='aboutDesc' >
            8.3 Where we fail to provide the Services or delay in providing such Services due to information network equipment maintenance, information network connectivity failures, errors in computer, communications or other systems, power failures, weather conditions, unexpected accidents, industrial actions, labor disputes, revolts, uprisings, riots, lack of productivity or production materials, fires, floods, storms, explosions, wars, failure on the part of banks or other partners, collapse of the digital asset market, actions by government, judicial or administrative authorities, other acts that are not within our control or beyond our inability to control, or due to causes on the part of third parties, we shall not assume any responsibility for such failure to provide service or delay in providing services, or for the resultant loss you may sustain as a result of such failure or delay.
        </p>

        <p className='aboutDesc' >
            8.4 We cannot guarantee that all the information, programs, texts, etc. contained in this Website are completely safe, free from the interference and destruction by any malicious programs such as viruses, trojans, etc., therefore, your log-into this Website or use of any services offered by this Website, download of any program, information and data from this Website and your use thereof are your personal decisions and therefore you shall bear the any and all risks and losses that may possibly arise.
        </p>

        <p className='aboutDesc' >
            8.5 We do not make any warranties and commitments in connection with any of the information, products and business of any third party websites linked to this Website, as well as any other forms of content that do not belong to us; your use any of the services, information, and products provided by a third party website is your personal decision and therefore you shall assume any and all the responsibilities arising therefrom.
        </p>

        <p className='aboutDesc' >
            8.6 We do not make any explicit or implicit warranties regarding your use of the Services offered by this Website, including but not limited to the applicability, freedom from error or omission, consistency, accuracy, reliability, and applicability to a specific purpose, of the services provided by this Website. Furthermore, we do not make any commitment or guarantee in connection with the validity, accuracy, correctness, reliability, quality, stability, integrity and timeliness of the technology and information covered by the services offered by this Website. Whether to log in this Website or use the services provided by this Website is your personal decision and therefore you shall bear all the risks and possible losses arising from such decision. We do not make any explicit or implicit warranties in connection with the market, value and price of digital assets; you understand and acknowledge that the digital asset market is unstable, that the price and value of assets may fluctuate or collapse at any time, and that the transaction of digital assets is based on your personal free will and decision and therefore you shall assume any and all risks and losses that may possible arise therefrom.
        </p>

        <p className='aboutDesc' >
            8.7 The guarantees and undertakings specified in this Agreement shall be the only guarantee and statements that we make in connection with the Services provided by us under this Agreement and through this Website, and shall supersede any and all the warranties and commitments arising in any other way and manner, whether in writing or in words, express or implied. All these guarantees and statements represent only our own commitments and undertakings and do not guarantee any third party's compliance with the guarantees and commitments contained in this Agreement.
        </p>

        <p className='aboutDesc' >
            8.8 We do not waive any of the rights not mentioned in this Agreement and to the maximum extent permitted by the applicable law, to limit, exempt or offset our liability for damages.
        </p>

        <p className='aboutDesc' >
            8.9 Upon your registration of your account with this Website, it shall be deemed that you approve any and all operations performed by us in accordance with the rules set forth in this Agreement, and any and all risks arising from such operations shall be assumed by you.
        </p>

        <p className='aboutDesc' >
            9.Termination of Agreement
        </p>

        <p className='aboutDesc' >
            9.1 This Website shall have the right to terminate all Service offered by this Website to you in accordance with this Agreement, and this Agreement shall terminate on the date of termination of all services offered by this Website to you.
        </p>

        <p className='aboutDesc' >
            9.2 After the termination of this Agreement, you do not have the right to require this Website to continue to provide you with any service or perform any other obligation, including, but not limited to, requesting this Website to keep or disclose to you any information in your former original account, or to forward to you or any third party any information therein that is not read or sent.
        </p>

        <p className='aboutDesc' >
            9.3 The termination of this Agreement shall not prevent the observant party from demanding the breaching party to assume other liabilities.
        </p>

        <p className='aboutDesc' >
            10．Intellectual Property
        </p>

        <p className='aboutDesc' >
            10.1 All intellectual achievements included in this Website, including, but not limited to, website logos, databases, website design, text and graphics, software, photos, videos, music, sounds and any combinations of the aforementioned files, and the intellectual property rights of software compilation, associated source code and software (including small applications and scripts) shall be owned by this Website. You may not copy, modify, copy, transmit or use any of the foregoing materials or content for commercial purposes.
        </p>

        <p className='aboutDesc' >
            10.2 All rights contained in the name of this Website (including but not limited to business goodwill and trademarks, logos) shall be owned by the Company.
        </p>

        <p className='aboutDesc' >
            10.3 Upon accepting this Agreement, it shall be deemed that you, on the basis of your own free will, have transferred and assigned exclusively and free of charge to this Website all copyright of any form of information that you publish on this Website, including, but not limited to copyrights, distribution rights, lease rights, exhibition rights, performance rights, projection rights, broadcasting rights, information network dissemination rights, shooting rights, adaptation rights, translation rights, compilation rights and other transferable rights that copyright owners are entitled to, and this Website shall have the right to sue for any infringement on such copyright and obtain full compensation for such infringement. This Agreement shall apply to any content that is published by you on this Website and is protected by copyright law, regardless of whether the content is generated before or after the signing of this Agreement.
        </p>

        <p className='aboutDesc' >
            10.4 You shall not illegally use or dispose of the intellectual property rights of this Website or any other person during your use of the services offered by this Website. For any information that you publish on this Website, you may not publish or authorize other websites (or media) to use such information in any manner wuatsoever.
        </p>

        <p className='aboutDesc' >
            10.5 Your log into this Website or use of any of the services offered by this Website shall not be deemed as our transfer of any intellectual property to you.
        </p>

        <p className='aboutDesc' >
            11.Information protection
        </p>

        <p className='aboutDesc' >
            Regarding the collection and use of your personal information and storage protection, the privacy policy publicized on this website shall prevail.
        </p>

        <p className='aboutDesc' >
            12．Calculation
        </p>

        <p className='aboutDesc' >
            All the transaction calculations are verified by us, and all the calculation methods have been posted on the Website, but we can not ensure that your use of this Website will not be disturbed or free from errors.
        </p>

        <p className='aboutDesc' >
            13.Export Control
        </p>

        <p className='aboutDesc' >
            You understand and acknowledge that in accordance with relevant laws of the Republic of Seychelles, you shall not export, re-export, import or transfer any material (including software) on this Website; therefore, you hereby undertake that you will not voluntarily commit or assist or participate in any of the above export or related transfer or other violations of applicable laws and regulations; if you uncover any of the aforementioned events, you will report to us and assist us in handling them.
        </p>

        <p className='aboutDesc' >
            14．Transfer
        </p>

        <p className='aboutDesc' >
            The rights and obligations agreed in this Agreement shall be equally binding on the assignees, the heirs, executors and administrators of the parties hereto who benefit from the rights and obligations. Without our consent, you may not transfer to any third party any of your rights or obligations hereunder, provided, however, we may, at any time, assign our rights and obligations under this Agreement to any third party with thirty (30) days' notice to you.
        </p>

        <p className='aboutDesc' >
            15.Severability
        </p>

        <p className='aboutDesc' >
            If any provision of this Agreement is found unenforceable, invalid or illegal by any court of competent jurisdiction, validity of the remaining provisions of this Agreement shall not be affected.
        </p>


        <p className='aboutDesc' >
            16．No Agency
        </p>

        <p className='aboutDesc' >
            Nothing in this Agreement shall be deemed to have created, implied or otherwise treated us as your agent, trustee or other representative, unless it is provided otherwise in this Agreement.
        </p>


        <p className='aboutDesc' >
            17.Waiver
        </p>

        <p className='aboutDesc' >
            Our or your waiver of the right to hold the other party liable for breaches of agreement or any other liability as is agreed upon in this Agreement shall not be construed or deemed as a waiver of the right to hold the other party for other breaches of contract; a failure to exercise any right or remedy shall not be construed in any way as a waiver of such right or remedy.
        </p>

        <p className='aboutDesc' >
            18.Headings
        </p>

        <p className='aboutDesc' >
            All headings herein are exclusively for the convenience of wording and are not intended to to expand or limit the content or scope of the terms and conditions of this Agreement.
        </p>

        <p className='aboutDesc' >
            19．Entry into Force and Interpretation of the Agreement
        </p>

        <p className='aboutDesc' >
            19.1 This Agreement shall enter into force when you click through the registration page of this Website, complete the registration procedures, obtain your account number and password of this Website, and shall be binding on you and this Website.
        </p>

        <p className='aboutDesc' >
            19.2 The ultimate power of interpretation of this Agreement shall be vested in this Website.
        </p>
    </div>

    const privacy = <div>
        <h1 id='privacy' >
            Privacy Policy
        </h1>
        <p className='aboutDesc' >
            our company is incorporated under the laws of the HongKong, operates the APP (hereinafter referred to as “this Platform” or “the Platform”), which is a Platform that is exclusively used by users to trade digital assets and provides them with relevant Services (hereinafter referred to as "the Services"). For the convenience of wording in this Agreement, the Company and the Website are collectively referred to as “We” or other appropriate forms of the first-person pronouns. Any natural person or entity that logs into the Platform shall be a user thereof and are hereinafter referred to as "You" or any other applicable forms of the second-person pronouns. Hereinafter You and us are collectively referred to as "both parties", and individually as "a party".
        </p>

        <p className='aboutDesc' >
            We fully understand and respect the importance of Your personal information to You, and We will adopt corresponding appropriate safety protection measures to protect Your personal information in accordance with the requirements of the applicable laws and regulations. In view of this, this Privacy Policy (hereinafter referred to as "this Policy/this Privacy Policy") is formulated and the following reminder is provided to You:
        </p>

        <p className='aboutDesc' >
            This Policy applies to all products and services We provide. If another privacy policy is specifically applicable to any product or service We provide to You, such privacy policy shall prevail; otherwise, this Privacy Policy shall prevail.
        </p>

        <p className='aboutDesc' >
            In particular, this Policy does not apply to services provided to You by any third party, and such services shall be subject to a privacy policy that is otherwise specified to You.
        </p>

        <p className='aboutDesc' >
            Before using our products or services, please read carefully and thoroughly understand this Policy, and pay special attention to the clauses that are in bold type or underlined and in bold type, make sure that You fully understand and agree to them before You start using our products or services. If You have any question, comment or suggestion in connection with any content of this Policy, You can contact us through any of the contact methods officially provided by us. If You do not agree with any term or condition in this Policy, You should promptly stop accessing this Platform.
        </p>

        <p className='aboutDesc' >
            Part I Definition
        </p>

        <p className='aboutDesc' >
            Personal information: various kinds of information recorded electronically or otherwise, which, alone or in combination with any other information, can identify the identity of a specific natural person or reflect the activities of a specific natural person.
        </p>

        <p className='aboutDesc' >
            Personal sensitive information: personal information, including Identity Card number, personal biometric information, bank account numbers, property information, tracks of whereabouts, transaction information, and information of children who are not older than fourteen(14) years old, inter alia. (We will prominently identify specific personal sensitive information in bold type in this Privacy Policy).
        </p>

        <p className='aboutDesc' >
            Deletion of personal information: the act of removing personal information from the systems involved in daily business functions to keep it in a state that cannot be retrieved or accessed.
        </p>

        <p className='aboutDesc' >
            Children: minors under the age of fourteen(14).
        </p>

        <p className='aboutDesc' >
            Part II Privacy Policy
        </p>

        <p className='aboutDesc' >
            This Section will help You understand the following:
        </p>

        <p className='aboutDesc' >
            1. How We collect and use Your information
        </p>

        <p className='aboutDesc' >
            2. How We use Cookie and similar technologies
        </p>

        <p className='aboutDesc' >
            3. How We share, transfer and publicly disclose Your information
        </p>

        <p className='aboutDesc' >
            4. How We protect Your information
        </p>

        <p className='aboutDesc' >
            5. How You manage Your information
        </p>

        <p className='aboutDesc' >
            6. How We deal with the information of minors
        </p>

        <p className='aboutDesc' >
            7. How this Privacy Policy is updated
        </p>

        <p className='aboutDesc' >
            8. How to contact us
        </p>

        <p className='aboutDesc' >
            I. How We collect and use Your information
        </p>

        <p className='aboutDesc' >
            When You use our products and/or services, We need/may need to collect and use Your personal information, including the following two types of information:
        </p>

        <p className='aboutDesc' >
            1. In order to enable the basic functions of the services/products We provide to You, You need to authorize us to collect and use Your information as may be necessary. If You refuse to provide such information, You will not be able to use our products and/or services normally;
        </p>

        <p className='aboutDesc' >
            2. In order to enable the auxiliary functions of the services/products We provide to You, You may choose to authorize us to collect and use Your information as is necessary. If You refuse to provide such information, You will not be able to use the corresponding auxiliary functions or cannot achieve the intended effects of such functions, which, however, will not affected Your normal access to the basic functions of our products and/or services;
        </p>

        <p className='aboutDesc' >
            You understand and agree:
        </p>

        <p className='aboutDesc' >
            1. We are committed to creating diversified products and services to meet Your needs. As We provide a wide variety of products and services to You, and the specific range of products/services that different users choose to use may vary from person to person, therefore, the corresponding basic/additional functions of such services and products, as well as the types and ranges of personal information collected and used hereunder may also vary subject to the specific product/service functions.
        </p>

        <p className='aboutDesc' >
            2. In order to provide You with a better product and service experience, We are making continuous efforts to improve our technology. Subsequently, We may introduce new or optimized functions from time to time, and may need to collect and use new personal information or change the purpose or method of using such personal information. In this regard, We will separately explain to You the purpose, scope and usage of the corresponding information by updating this Policy, pop-up windows, page prompts, inter alia, and provide You with the means to choose and agree at Your discretion, and collect and use the corresponding information after obtaining Your express consent. During this process, if You have any questions, comments or suggestions, You can contact us through various contact methods provided by this Platform, and We will provide a reply to You as soon as possible.
        </p>

        <p className='aboutDesc' >
            We will collect and use Your personal information to realize the following functions under this Policy:
        </p>

        <p className='aboutDesc' >
            i. Help You register and provide You with the services offered by this Platform;
        </p>

        <p className='aboutDesc' >
            1. Registration Service
        </p>

        <p className='aboutDesc' >
            We provide You with basic membership services through Your account with the Platform. In order to register as a member and access our membership services, You need to fill in and provide the following information when applying for registration:
        </p>

        <p className='aboutDesc' >
            (1) Identity Information. Such information can help us verify whether You are eligible to be registered as a member of this Platform. Such information includes but is not limited to Your name, address of Your place of residence, and other certificates and corresponding numbers thereof that are issued by Your country or government to certify Your identity information, as well as all other information that can help us verify Your identity (hereinafter referred to as "Identity Information"). Such Identity Information may vary depending whether You are an individual user or institutional user. Furthermore, in accordance with the applicable laws and regulations of different jurisdictions, the information collected by the Platform from users may vary depending on the country or region that the users are from. The following information and data are intended to serve as an indicative example:
        </p>

        <p className='aboutDesc' >
            For an individual user: his/her nationality, full legal name (including former names, if any, as well as his/her name presented in the local language of such individual user), Identity Card number, email address, telephone number, social security number ("SSN"), passport number or any other government-issued identity card number, date of birth, proof of identity (e.g. passport, driver's license or other identity certificates issued by the government).
        </p>


        <p className='aboutDesc' >
            For an institutional user: its legal corporation name (including its legal corporation name in the local language of such user), registration information, business telephone number, personal information of the authorized representative, primary business premises, and personal information of all shareholders/beneficial owners thereof (passports or identity cards issued by the government), the corporate structure of the institutional user, documents relating to the formation of the institutional user, resolutions of the board of directors thereof, declaration of the source of funding thereof, risk and compliance control instructions and other information or documents as may be required by the Platform.
        </p>

        <p className='aboutDesc' >
            (2) Service information. Such information helps us to contact You and successfully provide You with different forms of digital asset trading services. Such information includes but is not limited to: Your personal Identity Information, contact information, transaction information, as well as Your debit card information and/or other account information (hereinafter collectively referred to as "Service Information"). Furthermore, let it be understood that the Services We provide to You are subject to constant update and development. If You choose to use any other service that is not covered by the above description, and therefore it is necessary for us to collect additional information of Yours based on such service, We will explain to You the scope and purpose of such information collection through notices on web pages, interactive processes, agreements, and pop-up prompts, among others, to obtain Your consent to such collection.
        </p>

        <p className='aboutDesc' >
            If You only need to use the browsing service, You do not need to register as a member and provide the above information.
        </p>

        <p className='aboutDesc' >
            2. Other Registration Services
        </p>

        <p className='aboutDesc' >
            Real-name authentication: after You register and log in, You need to enter the "Personal Center" to complete the real-name authentication. During the process of such real-name authentication, You need to provide Your real name, identity card number or passport number to the Platform.
        </p>

        <p className='aboutDesc' >
            Advanced Certification: when Your transaction volume on this Platform exceeds a certain limit, the Platform will require You to undergo advanced certification, in order to ensure the safety of Your assets. You agree that in such advanced authentication process, in addition to providing the information required for the real name authentication, You will also undergo the advanced authentication procedures of "facial recognition", as is required by the Platform. Facial recognition will involve the collection of Your relevant video streams or face images by the Platform.
        </p>

        <p className='aboutDesc' >
            Account Information Display: if You already have an account with the Platform, We may display Your above-mentioned personal information in the services (for real-name authentication, only the outcome of such authentication will be displayed, i.e., whether You have passed such authentication or not) and the operations You perform in the products and services associated with Your account with the Platform, including centralized display of Your personal data and transaction orders through Your Platform account. We respect Your choices in connection with the set-up of Your account with the Platform.
        </p>

        <p className='aboutDesc' >
            ii. Security Management Services and Service Information Push Services
        </p>

        <p className='aboutDesc' >
            When You use the services provided by this Platform or access this Platform, in order to ensure Your transaction security and the safe and stable operation of services, this Platform needs to automatically receive and record Your web browser information, Your device information, including the device identification information (the type and model of device through which You log into Your account), Your IP address and records on the web page You request to visit, Your location, network environment, device fingerprint information, service log information, software information, location information, inter alia.
        </p>

        <p className='aboutDesc' >
            Please note that device information alone cannot identify a specific natural person. If We use this kind of non-personal information in combination with other information to determine the identity of a specific natural person, or use it in combination with personal information, such non-personal information will be regarded as personal information during such combination. Unless otherwise authorized by You or required by any of the applicable laws and regulations, We will anonymize such information and remove all identification marks therein.
        </p>

        <p className='aboutDesc' >
            In order to provide You with more convenient and personalized information display and push services, We may extract Your preferred features based on Your device information and Your browsing and usage information on this Platform or the client thereof in the future, and generate indirect group profiling based on such features, for the purpose of displaying and pushing information and possible commercial advertisements. If You do not agree to receive information pushed to You in this way, or wish to withdraw Your consent to the collection of Your personal information, You can contact us in accordance with the contact information provided under this Policy.
        </p>

        <p className='aboutDesc' >
            We strive to ensure Your browsing experience. If You do not want to receive the commercial advertising We send to You, You can unsubscribe to such advertising or otherwise disable this function by sending us a short text message or in any other way We specify.
        </p>

        <p className='aboutDesc' >
            Furthermore, We will also use the above-mentioned information of Yours to continuously improve and optimize the above functions.
        </p>

        <p className='aboutDesc' >
            iii. Keeping and Inquiry of Transaction Records
        </p>

        <p className='aboutDesc' >
            We collect data related to Your transactions on this Platform, including but not limited to transaction records. If You need to query Your order transaction records, You can query Your personal transaction details in the "Order Management" section of the Platform, which include: cyber name of the transaction counter-party, time of order placement, order serial numbers, transaction information, type and quantity of digital assets involved, inter alia.
        </p>

        <p className='aboutDesc' >
            iv. Customer Service and Dispute Resolution
        </p>

        <p className='aboutDesc' >
            When You contact us or apply to us for in-sales and after-sales service or for dispute resolution, We need You to provide necessary personal information to verify Your membership, so as to ensure the security of Your account and the system.
        </p>

        <p className='aboutDesc' >
            In order to facilitate our contact with You, help You solve the problems as soon as possible or record the solutions and results of the problems, We may keep Your communication records and related content (including Your account information, order information, other information You provide to prove the relevant facts, or contact information You provide). If You make queries, lodge complaints or provide suggestions in connection with any specific order, We will use Your account information and order information.
        </p>

        <p className='aboutDesc' >
            As may be reasonably necessary for us to provide services and improve service quality, We may also use other information of Yours, including the relevant information You provide when You contact our customer service department, and the replies You provide to our questionnaire surveys.
        </p>

        <p className='aboutDesc' >
            v. Miscellaneous
        </p>

        <p className='aboutDesc' >
            1. If the information You provide involve any personal information of any other user, You must ensure that You have obtained legal authorization before providing such personal information to this Platform. If any personal information of any child is involved, You must obtain the consent of such child's guardian before You may release such information. Under the aforementioned circumstance, the guardian shall have the right to contact us in any of the ways specified under Article IX of this Policy to request for the correction or deletion of any content involving the personal information of such child.
        </p>

        <p className='aboutDesc' >
            2. If We use any information hereunder for any purpose not specified in this Policy, or use any information collected for any specific purpose for any purposes other than such specific purpose, or We take the initiative to obtain Your personal information from a third party, We will obtain Your prior consent.
        </p>

        <p className='aboutDesc' >
            If We obtain Your information indirectly from a third party, We will explicitly require such third party in writing to obtain Your prior consent in accordance with law before collecting any personal information of Yours, inform You of the content of Your information that such third party shares with us, and obtain Your explicit confirmation before providing us with any sensitive information of Yours; furthermore, we will require such third party to provide an undertaking as to the legality and compliance of the source of personal information such third party provides to us. If the third party violates any of the applicable laws, We will explicitly require such third party to bear the corresponding legal responsibilities; furthermore, We will strengthen the security of personal information (including by proper filing of sensitive information, encrypted storage of sensitive information, access control, inter alia). We will use no less than the same methods and measures used by us to protect the personal information of our users to protect such personal information indirectly obtained by us.
        </p>

        <p className='aboutDesc' >
            3. Exceptions to the Requirements for Authorization and Consent
        </p>

        <p className='aboutDesc' >
            You fully understand and agree that under any of the following circumstances Your authorization is not required for us to collect and use Your personal information, and We are not obliged to respond to Your requests for correction/modification, deletion and cancellation of your information, or your requests for the withdrawal of your consent or your requests to access your information:
        </p>

        <p className='aboutDesc' >
            (1) Where state security and/or national defense security are involved;
        </p>

        <p className='aboutDesc' >
            (2) Where public security, public health and/or major public interests are involved;
        </p>

        <p className='aboutDesc' >
            (3) Where judicial or administrative enforcement, e.g., criminal investigation, prosecution, trial and sentence enforcement, is involved;
        </p>

        <p className='aboutDesc' >
            (4) Where it is for the purpose of protecting Your or another person’s life, property and/or any other important legal rights and interests, yet it is difficult to obtain the consent of Yours or such person, as the case may be;
        </p>

        <p className='aboutDesc' >
            (5) Where such information is Your personal information that You disclose to the public by Yourself;
        </p>

        <p className='aboutDesc' >
            (6) Where such information is Your personal information that is collected from legally disclosed information, such as legal news reports and government information disclosures, inter alia.
        </p>

        <p className='aboutDesc' >
            (7) Where it is required by any agreement or any other written document signed with You;
        </p>

        <p className='aboutDesc' >
            (8) Where it is necessary for maintaining the safe and stable operation of the products and/or services provided hereunder, such as uncovering and handling errors and faults in the products and/or services hereunder;
        </p>

        <p className='aboutDesc' >
            (9) Where it is necessary for legal news reporting;
        </p>

        <p className='aboutDesc' >
            (10) Where it is necessary for any public-interests-based statistical survey or academic research by any academic research institute, and when the results of such research or survey are provided externally, the personal information contained in such results shall be neutralized; and
        </p>

        <p className='aboutDesc' >
            (11) Other circumstances stipulated by any of the applicable laws and regulations.
        </p>

        <p className='aboutDesc' >
            Please be aware that in accordance with the applicable laws, if We adopt technical measures and other necessary measures to process personal information so that the data recipient cannot re-identify a specific individual and cannot restore such personal information to its status prior to our processing, or We may conduct unmarked research, statistical analysis and prediction on the collected information to improve the content and layout of this Platform, to provide product or service support for business decisions, and to improve our products and services (including using anonymous data for machine learning or model algorithm training), then the use of such processed information shall not be subject to a separate notice to You or Your consent.
        </p>

        <p className='aboutDesc' >
            4. If We stop operating the products or services of this Platform, We will stop collecting Your personal information in a timely manner, notify You of such termination of operation either through a special one-on-one notice to You or a general public announcement, and we will delete or anonymize the personal information that We hold in connection with such terminated business.
        </p>

        <p className='aboutDesc' >
            II. How We Use Cookies
        </p>

        <p className='aboutDesc' >
            If Your browser or browser’s add-on service permits, You can change the setting of Your browser so as to modify its degree of cookie acceptance or so that Your browser rejects Our cookies. For more details, please refer to AboutCookies.org. However, if You make such changes, it may affect Your safe access to our Platform under certain circumstances, and in this case, You may need to change Your user settings every time You visit our Platform. You understand and agree that:
        </p>

        <p className='aboutDesc' >
            1. When You visit our Platform, we will use Google Stats via cookies to record our performance and check the effect of online advertising. Cookies are small amounts of data sent to Your browser and stored on Your computer hard drive. Cookies can only be sent to Your computer hard disk when You use Your computer to access our Platform.
        </p>

        <p className='aboutDesc' >
            2. Cookies are often used to record the habits and preferences of visitors when they visit various items on our Platform. The data collected via cookies are anonymous collective statistics and do not contain personal data.
        </p>

        <p className='aboutDesc' >
            3. Cookies cannot be used to obtain data on Your hard disk, Your email address, or Your personal data; instead, they enable the Platform or service provider’s system to recognize Your browser and capture and remember information. Most browsers are preset to accept cookies. You can choose to set Your browser to refuse to accept cookies, or to notify You as soon as cookies are installed. However, if Your browser is preset to prohibit cookies, You may be unable to start or use some features of our Platform.
        </p>

        <p className='aboutDesc' >
            III. How We share, transfer and publicly disclose Your information
        </p>

        <p className='aboutDesc' >
            (1) Sharing
        </p>

        <p className='aboutDesc' >
            We will not share Your personal information with any company, organization and individual other than service providers of this Platform, except for under any of the following circumstances:
        </p>

        <p className='aboutDesc' >
            1. Sharing under statutory circumstances: we may share Your personal information externally in accordance with applicable laws and regulations, or as may be necessary for litigation and dispute resolution requirements, or as is required by administrative and judicial authorities.
        </p>

        <p className='aboutDesc' >
            2. Sharing with explicit consent: after obtaining Your explicit consent, We will share Your personal information with other parties.
        </p>

        <p className='aboutDesc' >
            3. Sharing subject to Your voluntary choice: We will, in accordance with Your choice, share with the transaction counter-party of the digital assets the necessary information related to the transaction in Your order information, so as to meet Your transactions related to transaction and after-sales services.
        </p>

        <p className='aboutDesc' >
            4. Sharing with authorized partners: we will share information with our affiliated parties and trusted third parties that help us operate our Platform, carry out business or provide services to You, as long as these parties agree to keep the information strictly confidential; We will share information when We believe that such disclosure of information is appropriate, in compliance with laws, regulations, rules and regulations or orders from courts of law or other competent authorities, implements our Platform strategy, is required for the normal operation of the Platform, is required for related parties to provide services or protect our or any other person’s rights, property or safety.
        </p>

        <p className='aboutDesc' >
            ii. Transfer
        </p>

        <p className='aboutDesc' >
            We will not transfer Your personal information to any company, organization or individual except for under any of the following circumstances:
        </p>

        <p className='aboutDesc' >
            1. Transfer subject to explicit consent: After obtaining Your explicit consent, We will transfer Your personal information to another party;
        </p>

        <p className='aboutDesc' >
            2. In the event of the merger, acquisition or bankruptcy liquidation of the Platform, or any other situation involving merger, acquisition or bankruptcy liquidation, if the transfer of personal information is involved therein, We will require the new company or organization holding Your personal information originally held by us to continue abiding by this Policy; otherwise We will require such company or organization or individuals involved to seek Your authorization and consent.
        </p>

        <p className='aboutDesc' >
            iii. Public Disclosure
        </p>

        <p className='aboutDesc' >
            We will only publicly disclose Your personal information under the following circumstances:
        </p>

        <p className='aboutDesc' >
            1. We may publicly disclose Your personal information with Your explicit consent or based on Your voluntary choice;
        </p>

        <p className='aboutDesc' >
            2. If We determine that You have violated any of the applicable laws and regulations or seriously violated any of the relevant agreement or rules of the Platform, or in order to protect the personal and property safety of users of the Platform or the public from infringement, We may disclose Your personal information in accordance with the applicable laws and regulations or with Your consent, including relevant violations and measures already taken against You by the Platform.
        </p>

        <p className='aboutDesc' >
            iv. Exceptions to the requirements for Your prior authorization and consent for the sharing, transfer or public disclosure of your personal information
        </p>

        <p className='aboutDesc' >
            Under the following circumstances, the sharing, transfer or public disclosure of Your personal information is not subject to Your prior authorization and consent:
        </p>

        <p className='aboutDesc' >
            (1) where state security and/or national defense security are involved;
        </p>

        <p className='aboutDesc' >
            (2) where public security, public health and/or major public interests are involved;
        </p>

        <p className='aboutDesc' >
            (3) Where judicial or administrative enforcement, e.g., criminal investigation, prosecution, trial and sentence enforcement, is involved;
        </p>

        <p className='aboutDesc' >
            (4) Where it is for the purpose of protecting Your or another person’s life, property and/or any other important legal rights and interests, yet it is difficult to obtain the consent of Yours or such person, as the case may be;
        </p>

        <p className='aboutDesc' >
            (5) Where such information is Your personal information that You disclose to the public by Yourself; and
        </p>

        <p className='aboutDesc' >
            (6) Where such information is Your personal information that is collected from legally disclosed information, such as legal news reports and government information disclosures, inter alia.
        </p>

        <p className='aboutDesc' >
            Please be aware that in accordance with the applicable laws, if We adopt technical measures and other necessary measures to process personal information so that the data recipient cannot re-identify a specific individual and cannot restore such personal information to its status prior to our processing, then the sharing, transfer or public disclosure of such personal data so processed shall not be subject to notification to You or consent by You.
        </p>

        <p className='aboutDesc' >
            IV. How We protect Your information
        </p>

        <p className='aboutDesc' >
            1. We implement proper physical, electronic, management and technical measures to protect and safeguard Your personal data. We will exert our best efforts to ensure that any personal data collected through our Platform are free from any nuisance caused by any third party unrelated to us. The safety measures We adopt include but are not limited to:
        </p>

        <p className='aboutDesc' >
            (1) Physical measures: Records containing Your personal data will be stored in a properly locked place.
        </p>

        <p className='aboutDesc' >
            (2) Electronic measures: Computer data containing Your personal information will be stored in computer systems and storage media that are subject to strict log-in restriction.
        </p>

        <p className='aboutDesc' >
            (3) Management measures: We have set up an internal safety defense department to protect the users' information, established relevant internal control systems, and adopted the principle of strict authorization for our employees who may come into contact with Your information; therefore, only properly authorized employees are permitted to come into contact with Your personal information and such employees must comply with our internal confidentiality rules for personal data. Furthermore, we provide sustained training to our staff on relevant laws and regulations, privacy and safety guidelines, enhance publicity and education on safety awareness, and organize our relevant internal personnel to carry out emergency response training and emergency drills on a regular basis, so as to enable them to fully understand their job duties and emergency response strategies and procedures.
        </p>

        <p className='aboutDesc' >
            (4) Technical Measures: encryption technology such as Secure Socket Layer Encryption may be adopted to transfer Your personal data.
        </p>

        <p className='aboutDesc' >
            (5) Security Measures: In order to ensure Your information security, we are committed to using various currently available general security technologies and supporting management systems to minimize the risks that Your information may be disclosed, damaged, misused, accessed without authorization, disclosed without authorization or altered. For example, the Secure Socket Layer (SSL) software is used for encrypted transmission, encrypted information storage and strict restriction of data center access. When transmitting and storing sensitive personal information (including personal biometric information), we will adopt security measures such as encryption, authority control, removal of identification marks, and de-sensitization, inter alia.
        </p>

        <p className='aboutDesc' >
            (6) Other measures: We regularly review our personal data collection, storage and processing procedures; furthermore, we limit the access of our employees and suppliers to Your data in accordance with the principle of “as necessary”, and our employees and suppliers must abide by strict contractual confidentiality obligations.
        </p>

        <p className='aboutDesc' >
            2. If You become aware of any security vulnerability of our Platform, please contact us promptly so that We can adopt appropriate actions as soon as possible.
        </p>

        <p className='aboutDesc' >
            3. Despite the above-mentioned technical and security measures, We cannot guarantee the absolute safety of data transmission on the Internet, so We cannot absolutely guarantee that the personal data You provide to us through our Platform are safe at all times. We will not regularly update and make public security risks and personal information security impact assessment reports, inter alia, which You can obtain through announcements made by this Platform.
        </p>

        <p className='aboutDesc' >
            V. How You manage Your information
        </p>

        <p className='aboutDesc' >
            You can access and manage Your information in any of the following ways:
        </p>

        <p className='aboutDesc' >
            i. Query, correct and delete Your information
        </p>

        <p className='aboutDesc' >
            You have the right to obtain a copy of Your personal data upon Your request, and to determine whether the information we have about You is accurate and up-to-date. If any of Your personal data is not accurate, You can request to update Your information. You can also request for the deletion of Your personal data, provided, however, we may reject Your deletion request in some cases, e.g., where such rejection is required by law or for other legal purposes. For data access, correction or deletion requests, please contact us according to the contact information under Article VIII hereof.
        </p>

        <p className='aboutDesc' >
            In response to application for access to or correction or deletion of data, we will verify the identity of the applicant to ensure that such applicant is legally entitled to make such application. Although our goal is to respond to such application on a gratuitous basis, we reserve the right to charge You a reasonable fee if Your request is repeated or onerous.
        </p>

        <p className='aboutDesc' >
            ii. Changing the scope of Your authorization
        </p>

        <p className='aboutDesc' >
            The fulfillment of each business function is subject to certain basic personal information (see Part I of this Privacy Policy). Furthermore, You can contact us through the contact information under Article VIII to grant or withdraw Your authorization.
        </p>

        <p className='aboutDesc' >
            When You withdraw Your consent, We will no longer process the corresponding personal information. However, Your decision to withdraw Your consent will not affect the existing personal information processing based on Your authorization.
        </p>

        <p className='aboutDesc' >
            iii. Cancellation of Your account
        </p>

        <p className='aboutDesc' >
            You can contact us through the contact information under Article VIII hereof to apply for cancellation of Your account. After You voluntarily cancel Your account, We will stop providing You with products or services, and delete Your personal information or anonymize it in accordance with the requirements of applicable laws.
        </p>

        <p className='aboutDesc' >
            iv. Respond to Your above requests
        </p>

        <p className='aboutDesc' >
            To ensure safety, You may need to provide a written request or prove Your identity in other ways. We may ask You to verify Your identity before processing Your request.
        </p>

        <p className='aboutDesc' >
            We will reply within fifteen(15) days. If You are not satisfied with our reply, You can also initiate a complaint through the contact information provided by us.
        </p>


        <p className='aboutDesc' >
            In principle, We do not charge any fee on Your reasonable requests, provided, however, We may charge a certain fee on Your repeated requests or requests that exceed reasonable limits, based on the actual circumstances. We may reject requests involving information that is not directly related to Your identity, unjustified repeated information, or requests that require too many technical means (e.g. to develop new systems or fundamentally change existing practices), requests that pose risks to any other person's legitimate rights and interests, or are unrealistic.
        </p>

        <p className='aboutDesc' >
            Under the following circumstances, We will not be able to respond to Your request in accordance with applicable laws and regulations:
        </p>

        <p className='aboutDesc' >
            1. where state security and/or national defense security are involved;
        </p>

        <p className='aboutDesc' >
            2. where public security, public health and/or major public interests are involved;
        </p>

        <p className='aboutDesc' >
            3. Where judicial or administrative enforcement, e.g., criminal investigation, prosecution, trial and sentence enforcement, is involved;
        </p>

        <p className='aboutDesc' >
            4. Where there is sufficient evidence proving that the owner of such personal information has subjective malice or abuse of power;
        </p>

        <p className='aboutDesc' >
            5. Where response to Your request would cause serious damage to the legitimate rights and interests of You or any other individual or organization;
        </p>

        <p className='aboutDesc' >
            6. Where any trade secret is involved.
        </p>

        <p className='aboutDesc' >
            VI. How We deal with the information of minors
        </p>

        <p className='aboutDesc' >
            This Platform is not open to children. If We find or suspect that the user is under 14 years old, We will ask the user to close his account and will not allow the user to continue to use the Platform products or services. We will delete the relevant information as soon as possible. If You know that anyone under the age of 14 uses our services, please inform us so that We can take action to prevent them from obtaining our products or services.
        </p>

        <p className='aboutDesc' >
            If we find out or suspect that a user is less than fourteen (14) years old, we will ask the user to close his/her account and will not allow the user to continue using the products and services of the Platform. We will delete the relevant information of such users as soon as possible. If You know that any person who is under the age of fourteen (14) uses our services, please notify us so that we can adopt actions to prevent such user from accessing our products or services.
        </p>

        <p className='aboutDesc' >
            For minors who have reached the age of fourteen(14) but are less than eighteen(18) years old, We expect their parents or guardians to guide such minors in using our services. We will protect the confidentiality and security of such minors' information in accordance with relevant state laws and regulations.
        </p>

        <p className='aboutDesc' >
            If You are a minor, it is recommended that Your parents or guardians read this Policy and use our services or provide us with Your information subject to the consent of Your parents or guardian. If we collect Your information with the consent of Your parents or guardians, we will only use or disclose such information when it is permitted by law, expressly approved by Your parents or guardian or is necessary for protecting Your rights and interests. If Your parents or guardian disagrees with Your use of our services or Your providing us with information in accordance with this Policy, please stop using our services promptly and inform us in time so that we can take corresponding measures.
        </p>

        <p className='aboutDesc' >
            If You are the parent or guardian of a minor, please contact us through the contact information hereunder when You have any questions in connection with our processing of the information of the minor of whom You are a parent or guardian.
        </p>

        <p className='aboutDesc' >
            VII. How this Privacy Policy is updated
        </p>

        <p className='aboutDesc' >
            Our Privacy Policy is subject to changes.
        </p>

        <p className='aboutDesc' >
            Without Your explicit consent, We will not restrict Your rights under this Privacy Policy. We will publish any and all changes to this Privacy Policy via official channels. If You do not agree with any such change, You should stop accessing this Platform promptly. After an updated version of this Privacy Policy is released, Your continued visit to this Platform shall be deemed as showing and indicating that You agree with such update and agree to abide by the updated version of this Privacy Policy.
        </p>

        <p className='aboutDesc' >
            For any major change made hereto, We will also issue more prominent notices (including notices made in the form of public announcements made through this Platform and even pop-up prompts to You).
        </p>

        <p className='aboutDesc' >
            Major changes referred to in this Policy include but are not limited to:
        </p>

        <p className='aboutDesc' >
            1. Significant changes in our service mode. For example, the purpose of processing personal information, the types of personal information processed, the ways in which personal information is used, inter alia.
        </p>

        <p className='aboutDesc' >
            2. Significant changes in our control. Such as changes in information controllers caused by any merger and acquisition, inter alia;
        </p>

        <p className='aboutDesc' >
            3. Any change in the major person(s) which personal information hereunder is shared with, transferred or publicly disclosed to;
        </p>

        <p className='aboutDesc' >
            4. Significant changes in Your right to participate in personal information processing and the way You exercise such right;
        </p>

        <p className='aboutDesc' >
            5. Changes in our department responsible for personal information security, or changes in the contact information of such department, or the ways in which complaints hereunder are lodged; and
        </p>

        <p className='aboutDesc' >
            6. The security impact assessment report for personal information hows that there is a high degree of risks.
        </p>
    </div>

    let content = about

    if (isSecondClicked) {
        content = disclaimer
    }
    else if (isThirdClicked) {
        content = policy
    }
    else if (isLastClicked) {
        content = privacy
    }

    else {
        content = about
    }

    return (

        <div div className="withdrawalBody" >
            <div className="linearGradientBody">
                <HomeNavBar gradient={true} />
                <div className="withdrawContainer">
                    <div className="arrowBox" onClick={() => navigate(-1)}>
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className="withdraw">Platform Information</h2>
                </div>
            </div>
            <div className='aboutParent' >


                <div className='withdrawals about'>
                    <div className='leftSection' >
                        <div style={{ background: (isFirstClicked) ? 'rgba(255, 179, 49, .1)' : 'none' }} onClick={makeFirstClicked} className='linkBox' ><Link to='/info#about' style={{ color: (isFirstClicked) ? 'rgb(255, 179, 49)' : '#fff', textDecoration: 'none' }} >About</Link></div>
                        <div style={{ background: (isSecondClicked) ? 'rgba(255, 179, 49, .1)' : 'none' }} onClick={makeSecondClicked} className='linkBox' ><Link to='/info#disclaimer' style={{ color: (isSecondClicked) ? 'rgb(255, 179, 49)' : '#fff', textDecoration: 'none' }}>Disclaimer</Link></div>
                        <div style={{ background: (isThirdClicked) ? 'rgba(255, 179, 49, .1)' : 'none' }} onClick={makeThirdClicked} className='linkBox' ><Link to='/info#policy' style={{ color: (isThirdClicked) ? 'rgb(255, 179, 49)' : '#fff', textDecoration: 'none' }}>Conditions</Link></div>
                        <div style={{ background: (isLastClicked) ? 'rgba(255, 179, 49, .1)' : 'none' }} onClick={makeLastClicked} className='linkBox' ><Link to='/info#privacy' style={{ color: (isLastClicked) ? 'rgb(255, 179, 49)' : '#fff', textDecoration: 'none' }}>Privacy </Link></div>
                    </div>
                    <div className='rightSection' >{content}
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}




export default PlatformInformation