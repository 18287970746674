import React, { useEffect, useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import './AdminWithdrawal.css'
import Loading from '../../Components/Loading/Loading'
import { useSelector, useDispatch } from 'react-redux'
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import { useNavigate } from 'react-router-dom'
import { getSingleWithdrawAdmin, getAllWithdrawsAdmin, userDeleteId } from '../../Redux/User/userAction'
import AdminMobileNav from '../../Components/AdminMobileNav/AdminMobileNav'
import DeleteWithdrawModal from '../../Components/DeleteWithdrawModal/DeleteWithdrawModal'

const AdminWithdrawal = () => {

    const [isModalOpen, setIsModalOpen] = useState(false)

    const openDeleteModal = () => {
        setIsModalOpen(true)
    }

    const closeDeleteModal = () => {
        setIsModalOpen(false)
    }


    const dispatch = useDispatch()

    const navigate = useNavigate()

    const adminAllWithdrawals = useSelector(state => state.user.adminAllWithdrawals)

    const { token } = useSelector(state => state.user.userDetails)

    useEffect(() => {
        if (adminAllWithdrawals.length === 0) {
            dispatch(getAllWithdrawsAdmin(token))
        }
        else {
            return
        }
    }, [token, adminAllWithdrawals.length, dispatch])

    const fetchAdminSingleWithdraw = (userId) => {
        dispatch(getSingleWithdrawAdmin(userId, token))
        navigate(`/admin/withdraws/${userId}`)
    }

    const deleteWithdraw = (userId) => {
        // dispatch(adminDeleteWithdraw(userId, token))
        openDeleteModal()
        dispatch(userDeleteId(userId))
    }

    let content = <Loading />

    if (adminAllWithdrawals.length > 0) {
        content = <div className='tableParent withDrawalTableContainer'  >
            <table className='userListTable  ' >
                <thead>
                    <tr>
                        <th>Withdraw id</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Currency</th>
                        <th>Address</th>
                        <th>Amount</th>
                        <th>Action</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        adminAllWithdrawals.map((withdraw, id) => {
                            return (
                                <tr key={id} >
                                    <td>{id}</td>
                                    <td>{withdraw.email}</td>
                                    <td>{withdraw.name}</td>
                                    <td>{withdraw.currency}</td>
                                    <td>{withdraw.address}</td>
                                    <td>{new Intl.NumberFormat().format(withdraw.amount)}</td>
                                    <td><button className='viewBtn' onClick={() => fetchAdminSingleWithdraw(withdraw.withdraw_id)} >Update</button></td>
                                    <td><button className='viewBtn' onClick={() => deleteWithdraw(withdraw.withdraw_id)} >Delete</button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    else if (adminAllWithdrawals.length === 0) {
        content = <div className='depositBody' ><EmptyComponent /></div>

    }

    return (
        <div>
            <div className='navholder' >
                <AdminNavbar gradient={false} />
                <AdminMobileNav />
                {isModalOpen && <DeleteWithdrawModal closeDeleteModal={closeDeleteModal} />}
            </div>
            <div className='financeBodyParent' style={{ paddingBottom: '2rem' }} >
                <div className='financeBody ' >
                    {content}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminWithdrawal
