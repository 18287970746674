import React from 'react'
import Slider from 'react-slick'
import './Carosel.css'
import CaroselCard from '../CaroselCard/CaroselCard'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { CaroselCardData } from '../CaroselCard/CaroseData';

const Carosel = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        infinite: true,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='sliderClass'>
            <div className='sliderSection'>
                <div className='sliderParent'>
                    <div className='slider'>
                        <Slider  {...settings}>
                            {
                                CaroselCardData.map(caroselCard => {
                                    return (
                                        <CaroselCard key={caroselCard.id} img={caroselCard.img} />
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>

            </div>
        </div>


    )
}

export default Carosel