import React from "react";
import HomeNavBar from "../../Components/HomeNavBar/HomeNavBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./ICODetails.css";
import Loading from "../../Components/Loading/Loading";
import Footer from "../../Components/Footer/Footer";

const ICODetails = () => {
  const navigate = useNavigate();
  const icoDetails = useSelector((state) => state.user.icoDetails);
  const isLoading = useSelector((state) => state.user.isLoading);

  const startDate = new Date(icoDetails.start_date).toLocaleDateString("en-US");
  const endDate = new Date(icoDetails.end_date).toLocaleDateString("en-US");

  let percentage = 0;
  if (icoDetails?.progress_bar === null) {
    percentage = 0;
  } else {
    percentage = icoDetails?.progress_bar;
  }

  return (
    <div className="withdrawalBody">
      <div className="linearGradientBody">
        <HomeNavBar gradient={true} />
        <div className="withdrawContainer">
          <div className="arrowBox" onClick={() => navigate(-1)}>
            <i className="ri-arrow-left-line backArw"></i>
          </div>
          <h2 className="withdraw">ICO</h2>
        </div>
      </div>

      <div className="icoDetailsBox">
        {isLoading ? (
          <Loading />
        ) : (
          <div className="icoDetailsHodingBox">
            <div className="icoDetailsHodingBoxLeft icoLeftSub2">
              <div className="icoLeftSub">
                <img
                  src={icoDetails.image_url}
                  alt="icon"
                  className="icoImg icoLeftImg"
                  icboBoxImg
                />
                <p className="icoDetailsCoinName">{icoDetails.coin_name}</p>
              </div>
            </div>
            <div className="icoDetailsHodingBoxRight activityInfoBox2">
              <div className="activityInfoBox">
                <p className="activityInfo">Activity Info</p>
              </div>
              <div className="progressBox">
                <div className="totalSupplyBox">
                  <p className="progress">Progress</p>
                  <p className="progress">Total supply</p>
                </div>
                <div className="progressbarLength">
                  <div
                    className="progressBar"
                    style={{ width: `${percentage}%` }}
                  />
                </div>
                <div className="totalSupplyBox">
                  <p className="progress">{percentage}%</p>
                  <p className="progress">{icoDetails.circulation}</p>
                </div>
              </div>
              <div className="otherCardDetails">
                <p className="bolder">Status</p>
                <p className="lighter">Completed</p>
                <p className="bolder">Total Supply</p>
                <p className="lighter">{icoDetails.circulation}</p>
                <p className="bolder">Publish price</p>
                <p className="lighter">{icoDetails.price}</p>
                <p className="bolder">Activity Coin Symbol</p>
                <p className="lighter">{icoDetails.coin_name}</p>
                <p className="bolder">activity.acceptcoin</p>
                <p className="lighter">USDT</p>
                <p className="bolder">Start Date</p>
                <p className="lighter">{startDate}</p>
                <p className="bolder">End Date</p>
                <p className="lighter">{endDate}</p>
              </div>
              <button className="attendBtn" disabled={true}>
                Attend
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ICODetails;
