import React from "react";
import { Link, useNavigate } from "react-router-dom";
import EmailRegister from "../../Components/EmailRegister/EmailRegister";
import logo from '../../Assets/primary logotype-01.png'
import "./SignUp.css";

const SignUp = () => {
  const navigate = useNavigate();



  return (
    <div className="loginContainer registerContainer">
      <div className="backArrowBox" onClick={() => navigate("/")}>
        <i className="ri-arrow-left-line backArrow"></i>
      </div>
      <div className="signUpBoxParent">
        <div className="signUpBox">
          <div className="logoHolder">
            <Link to='/' className='brand' >
              <img className='logoImg' src={logo} alt='logo' />
            </Link>
          </div>
          <EmailRegister />
          <div className="haveAnAcctBox">
            <Link className="haveAnAcct" to="/login">
              Have an Account?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
