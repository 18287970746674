import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import IDPhotos from '../../Components/IDPhotos/IDPhotos'

import './IdentityAuth.css'

const IdentityAuth = () => {

    const [isNameEmpty, setIsNameEmpty] = useState(true)
    const [isIdNumberEmpty, setIisIdNumberEmpty] = useState(true)


    const [name, setName] = useState('')
    const [idNumber, setIdNumber] = useState('')

    const handleSubmit = e => {
        e.preventDefault()
        if (name.length === 0 && idNumber.length === 0) {
            setIsNameEmpty(false)
            setIisIdNumberEmpty(false)
            return
        }

        else if (name.length === 0) {
            setIsNameEmpty(false)
        }
        else if (idNumber.length === 0) {
            setIisIdNumberEmpty(false)
        }
        else {
            alert(`and ${name} and ${idNumber}`)
        }
    }



    const navigate = useNavigate()

    return (
        <div className='withdrawalBody'>
            <div className='linearGradientBody'>
                <HomeNavBar gradient={true} />
                <div className='withdrawContainer'>
                    <div className='arrowBox' onClick={() => navigate(-1)} >
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className='withdraw'>Identity authentication</h2>
                </div>
            </div>
            <div className='withdrawals identityAuth'>
                <form onSubmit={handleSubmit}>
                    <div className='telephoneBox fundPasswordbox  identityAuth' >
                        <div className='activityInfoBox'>
                            <p className='activityInfo' >Activity Info</p>
                        </div>
                        <div className='emailRegister bindteleBox identityAuthInputBox'>

                            <div className='inputContainer identityAuthInput'>
                                <input className='loginInput' type='text' placeholder='Please enter yor real name'
                                    onChange={e => setName(e.target.value)} value={name}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            e.target.style.border = '1px solid red'
                                            setIsNameEmpty(false)
                                        }
                                        else {
                                            setIsNameEmpty(true)
                                        }
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.border = '1px solid #535353'
                                    }}
                                />
                                {!isNameEmpty && <p className='errMsg'>Please enter your real name</p>}
                            </div>
                            <div className='inputContainer identityAuthInput'>

                                <input className='loginInput' type='text' placeholder='Please enter the ID number'
                                    onChange={e => setIdNumber(e.target.value)} value={idNumber}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            e.target.style.border = '1px solid red'
                                            setIisIdNumberEmpty(false)
                                        }
                                        else {
                                            setIisIdNumberEmpty(true)
                                        }
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.border = '1px solid #535353'
                                    }}
                                />
                                {!isIdNumberEmpty && <p className='errMsg'>Please enter the ID number</p>}
                            </div>
                        </div>

                    </div>
                    <IDPhotos />
                    <div className='authBtnHolder' >
                        <button className='signInBtn identityAuthBtn signUp'>Submit Review</button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default IdentityAuth
