import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import { persistStore } from "redux-persist";
import thunk from 'redux-thunk'
import { persistedReducer } from "./rootReducer";

const middlewares = [logger, thunk];

if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
}

export const store = createStore(
    persistedReducer,
    applyMiddleware(...middlewares)
);
export const persistor = persistStore(store);


// import { createStore, applyMiddleware } from 'redux'
// import logger from 'redux-logger'
// import thunk from 'redux-thunk'
// import { rootReducer } from './rootReducer';


// const middlewares = [logger, thunk];

// if (process.env.NODE_ENV !== "production") {
//     middlewares.push(logger);
// }

// export const store = createStore(
//     rootReducer,
//     applyMiddleware(...middlewares)
// );