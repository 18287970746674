import React, { useState } from 'react'
import './UsersList.css'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { adminViewSingleUser, userDeleteId } from '../../Redux/User/userAction'
import DeleteUserModal from '../DeleteUserModal/DeleteUserModal'


const UsersList = ({ full }) => {

    const [isModalOpen, setIsModalOpen] = useState(false)

    const openDeleteModal = () => {
        setIsModalOpen(true)
    }

    const closeDeleteModal = () => {
        setIsModalOpen(false)
    }

    const allUsers = useSelector(state => state.user.users)
    const { token } = useSelector(state => state.user.userDetails)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const fetchSingleUser = (userId) => {
        dispatch(adminViewSingleUser(userId, token))
        navigate(`/admin/users/${userId}`)
    }

    const deleteUser = (userId) => {
        openDeleteModal()
        dispatch(userDeleteId(userId))
    }

    return (
        <div className='tableParent' style={{ width: (full) ? '100%' : 'auto', height: (full) ? '100vh' : '70vh' }} >
            {isModalOpen && <DeleteUserModal closeDeleteModal={closeDeleteModal} />}
            <table className='userListTable' >
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th>Action</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allUsers.map((user, id) => {
                            return (
                                <tr key={id} >
                                    <td>{id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone_number}</td>
                                    <td><button className='viewBtn' onClick={() => fetchSingleUser(user.user_id)} >View</button></td>
                                    <td><button className='viewBtn' onClick={() => deleteUser(user.user_id)}  >Delete </button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default UsersList
