import React from 'react'
import './CaroselCard.css'

const CaroselCard = ({ img }) => {
    return (
        <img src={img} alt='pic' className='caroselImage' />
    )
}

export default CaroselCard
