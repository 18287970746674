import React from 'react'
import HomeNavBar from '../HomeNavBar/HomeNavBar'
import QRCodeViewer from '../QRCode/QRCode'

import './HeroSection.css'

const HeroSection = () => {
    return (
        <>
            <div className='heroNav'  >
                <HomeNavBar gradient={false} />
            </div>
            <div className='heroSection'>
                <div className='heroSectionBackdrop'>
                    <HomeNavBar gradient={false} />

                    <section className='heroBox'>
                        <div className='heroTextContainer'>
                            <h1 className='heroTextHeader'>User-trusted cryptocurrency trading platform</h1>
                            <p className='heroTextDesc'>Strive to promise to escort the safety of users with strict protocols and industry-leading technical measures</p>
                        </div>
                        {/*<QRCodeViewer text='Ruona. Agba Coder' />*/}
                    </section>
                </div>
            </div>
        </>

    )
}

export default HeroSection
