import React, { useEffect } from 'react'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import MarketTrends from '../../Components/MarketTrends/MarketTrends'
import MobileMarketTrends from '../../Components/MobileMarketTrends/MobileMarketTrends'
import { useDispatch } from 'react-redux'
import { fetchAllCoins } from '../../Redux/User/userAction'
import './Market.css'
import Footer from '../../Components/Footer/Footer'
import MobileNavBar from '../../Components/MobileNavBar/MobileNavBar'

const Market = () => {

    const total = 20

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAllCoins(total))
    }, [total, dispatch])


    return (
        <div className='marketContainer' >
            <HomeNavBar gradient={false} />
            <MobileNavBar />
            <MarketTrends showSearch={true} />
            <MobileMarketTrends showSearch={true} />
            <Footer />
        </div>
    )
}

export default Market
