import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { adminDeleteUser } from '../../Redux/User/userAction'




const DeleteUserModal = ({ closeDeleteModal }) => {


    const dispatch = useDispatch()
    const deleteUserId = useSelector(state => state.user?.deleteId)
    const { token } = useSelector(state => state.user.userDetails)


    const deleteWithdraw = () => {
        dispatch(adminDeleteUser(deleteUserId, token))
        closeDeleteModal()
    }


    return (
        <div className='deleteModal' >
            <div className="deleteCard">
                <div>
                    <h3 className="deleteQuestion">Are you sure you want to delete this withdrawal?</h3>
                    <div className="deleteOptions">
                        <button onClick={deleteWithdraw} className="deleteYes">Yes</button>
                        <p className="cancelDeleteText" onClick={closeDeleteModal} >No</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DeleteUserModal
