import React, { useState } from 'react'
import './MarketTrends.css'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import millify from 'millify'
import { selectCoin } from '../../Redux/User/userAction'

import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import Loading from '../Loading/Loading'

const MarketTrends = ({ showSearch }) => {

    const [searchField, setSearchField] = useState('')

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const allCoins = useSelector(state => state.user.allCoins)
    const isLoading = useSelector(state => state.user.isLoading)


    const filteredCoins = allCoins?.filter(coin => {
        return coin.symbol.toLowerCase().includes(searchField.toLowerCase())
    })

    const showCoinDetails = (coinSymbol) => {
        dispatch(selectCoin(coinSymbol))
        navigate(`/exchanges/${coinSymbol}`)
    }

    let content = <Loading />

    if (isLoading === false && allCoins?.length > 0) {
        content = filteredCoins?.map(coin => {
            return (
                <div key={coin.id} className='coinTable' >
                    <div className='coinRow' >
                        <div className='coinContainer' >
                            <img className='coinImage' src={coin.image} alt={coin.id} />
                        </div>
                        <div className='coinContainer' >
                            <p className='coinName' >{coin.symbol.toUpperCase()}/USDT</p>
                        </div>
                        <div className='coinContainer' >
                            <p className='coinName' >{millify(coin.current_price)}</p>
                        </div>
                        <div className='coinContainer' >
                            <p className='coinName' style={{ color: (coin.market_cap_change_percentage_24h > 0) ? 'rgb(14, 203, 129)' : 'red' }} >{millify(coin.market_cap_change_percentage_24h)}%</p>
                        </div>
                        <div className='coinContainer' >
                            <p className='coinName' >{millify(coin.high_24h)}</p>
                        </div>
                        <div className='coinContainer' >
                            <p className='coinName' >{millify(coin.low_24h)}</p>
                        </div>
                        <div className='coinContainer' >
                            <p className='coinVolume'>{millify(coin.market_cap_change_24h)}</p>
                        </div>
                        <div className='coinContainer' >
                            <button className='coinBtn' onClick={() => showCoinDetails(coin.symbol.toUpperCase())} >Exchange</button>
                        </div>
                    </div>
                </div>
            )
        })
    }



    if (filteredCoins?.length === 0 && allCoins?.length > 0) {
        content = <div className='emptyBoxHolder' >
            <EmptyComponent />
        </div>
    }

    return (
        <div className='coinBodyParent' style={{ height: (allCoins?.length < 5 && showSearch) ? '90vh' : 'fit-content' }} >
            <div className='coinBody' >
                <div className='marketTrendsSearch' >
                    {
                        !showSearch ?
                            <h1 className='marketTrendText'>Market Trend</h1> :

                            <div className='marketHeading' >
                                <div className='hotContainer' >
                                    <p className='hotText'>Hot</p>
                                </div>
                                <div className='searchContainer' >
                                    <i className="ri-search-line searchIcon"></i>
                                    <input onChange={e => setSearchField(e.target.value)} className='searchInput' type='search' placeholder='Search' />
                                </div>
                            </div>
                    }
                </div>

                <div className='coinTableContainer' >
                    <div className='coinTable' >
                        <div className='coinRow' >
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Logo</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Pair</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Latest</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Increase</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Highest</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Lowest</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >24H Vol</p>
                            </div>
                            <div className='coinContainer'>
                                <p className='coinRowHeader' >Action</p>
                            </div>
                        </div>
                    </div>


                    {
                        content
                    }
                </div>
            </div>
        </div>
    )
}

export default MarketTrends
