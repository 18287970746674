import React from 'react'
import Footer from '../../Components/Footer/Footer'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from '../../Components/Loading/Loading'

const AdminViewUser = () => {

    const user = useSelector(state => state.user.user)
    const navigate = useNavigate()
    const isLoading = useSelector(state => state.user.isLoading)

    return (
        <div className="withdrawalBody adminSingleWithdraw">
            <div className="">
                <AdminNavbar />
                <div className="withdrawContainer">
                    <div className="arrowBox" onClick={() => navigate(-1)}>
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className="withdraw">User</h2>
                </div>
                <div className='createFinanceContainer'>
                    <button onClick={() => navigate('/admin/create-finance')} className='createFinanceBtn'> Create Finance</button>
                </div>
                <div className='singleFinanceParent' style={{ height: '70vh' }} >
                    <div className='icoBodyParent ' >
                        {
                            isLoading ? <Loading /> :
                                <div className='tableParent withDrawalTableContainer'  >
                                    <table className='userListTable  ' >
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>

                                                <th>Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                <tr  >
                                                    <td>{user?.name}</td>
                                                    <td>{user?.email}</td>

                                                    <td>{user?.phone_number}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default AdminViewUser
