import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import Footer from '../../Components/Footer/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../Components/Loading/Loading'
import { TailSpin } from 'react-loading-icons'
import { adminGetAllFinance, adminUpdateSingleUserFinance, offMessage } from '../../Redux/User/userAction'

const ViewSingleFinance = () => {

    const singleUserFinance = useSelector(state => state.user.singleUserFinance)
    const isLoading = useSelector(state => state.user.isLoading)
    const message = useSelector(state => state.user.message)
    const { token } = useSelector(state => state.user.userDetails)



    const [currency, setCurrency] = useState('')
    const [amount, setAmount] = useState('')



    useEffect(() => {
        setCurrency(singleUserFinance[0]?.currency)
        setAmount(singleUserFinance[0]?.amount)
    }, [setCurrency, setAmount, singleUserFinance])

    const navigate = useNavigate()

    const { financeId } = useParams()





    const updateDetails = {
        currency, amount
    }


    const dispatch = useDispatch()
    const updateUserFinance = () => {
        dispatch(adminUpdateSingleUserFinance(financeId, updateDetails, token))
        dispatch(adminGetAllFinance(token))
    }

    const goBack = () => {
        dispatch(offMessage())
        navigate(-1)
    }


    return (
        <div className="withdrawalBody adminSingleWithdraw">
            <div className="">
                <AdminNavbar />
                <div className="withdrawContainer">
                    <div className="arrowBox" onClick={goBack}>
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className="withdraw">Finance</h2>
                </div>
                <div className='singleFinanceParent'>
                    <div className='icoBodyParent ' >
                        {
                            isLoading ? <Loading /> :
                                <>
                                    <div className='tableParent withDrawalTableContainer'  >
                                        <table className='userListTable  ' >
                                            <thead>
                                                <tr>

                                                    <th>User Id</th>
                                                    <th>Currency</th>
                                                    <th>Amount</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    singleUserFinance?.map(userFinance => {
                                                        return (
                                                            <tr key={userFinance?.finance_id} >

                                                                <td>{userFinance?.user_id}</td>
                                                                <td>{userFinance?.currency}</td>
                                                                <td>{new Intl.NumberFormat().format(userFinance?.amount)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='EditIcoInputsContainer editWithdrawal' >
                                        <input onChange={e => setCurrency(e.target.value)} className='editIcoInput' placeholder='Currency' value={currency} />
                                        <input onChange={e => setAmount(e.target.value)} className='editIcoInput' type='number' placeholder='Amount' value={amount} />
                                        {
                                            isLoading ? <button className='attendBtn updateIcoBtn ' ><TailSpin width={20} stroke="#fff" /></button> :

                                                <button onClick={updateUserFinance} className='attendBtn updateIcoBtn '>Update Finance</button>
                                        }
                                        <p className='success'>{message}</p>
                                    </div>
                                </>


                        }


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ViewSingleFinance
