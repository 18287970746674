import React, { useState, useEffect } from 'react'
import './EmailRegister.css'
import { useNavigate } from 'react-router-dom'
import { userRegister } from '../../Redux/User/userAction'
import { TailSpin } from 'react-loading-icons'
import { useDispatch, useSelector } from 'react-redux'

const EmailRegister = () => {

    const [isEmailEmpty, setIsEmailEmpty] = useState(true)
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(true)
    const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(true)
    const [isNameEmpty, setIsNameEmpty] = useState(true)
    const [isVerificationCodeEmpty, setIsVerificationCodeEmpty] = useState(true)

    const [email, setEmail] = useState('')
    const [password_hash, setPassword] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [name, setName] = useState('');
    const [verification_code, setVerificationCode] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [err, setErr] = useState('')

    const regMsg = useSelector(state => state.user.message)
    const isRegSuccessful = useSelector(state => state.user.isRegSuccessful)
    const isLoading = useSelector(state => state.user.isLoading)

    const userDetails = {
        name, password_hash, phone_number, email, verification_code
    }



    const handleSubmit = e => {
        e.preventDefault()
        if (email.length === 0 && password_hash.length === 0 && verification_code.length === 0 && phone_number.length === 0 && name.length === 0) {
            setIsEmailEmpty(false)
            setIsPasswordEmpty(false)
            setIsPhoneNumberEmpty(false)
            setIsNameEmpty(false)
            setIsVerificationCodeEmpty(false)
            return
        }
        else if (email.length === 0) {
            setIsEmailEmpty(false)
        }
        else if (password_hash.length === 0) {
            setIsPasswordEmpty(false)
        }
        else if (phone_number.length === 0) {
            setIsPhoneNumberEmpty(false)
        }
        else if (name.length === 0) {
            setIsNameEmpty(false)
        }
        else if (verification_code.length === 0) {
            setIsVerificationCodeEmpty(false)
        }
        else if (verification_code !== '9870') {
            setIsVerificationCodeEmpty(false)
        }

        else {
            setIsEmailEmpty(true)
            setIsPasswordEmpty(true)
            // console.log(userDetails)
            dispatch(userRegister(userDetails))
            setErr(regMsg)
        }
    }

    useEffect(() => {
        if (isRegSuccessful) {
            navigate('/login')
        }
    }, [isRegSuccessful, navigate])

    useEffect(() => {
        setErr(regMsg)
    }, [setErr, regMsg])


    return (
        <div className='emailRegister'>
            <form>
                <div className='inputContainer'>

                    <input className='loginInput' type='email' placeholder='Enter Account Email'
                        onChange={e => setEmail(e.target.value.toLowerCase())} value={email}
                        onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                e.target.style.border = '1px solid red'
                                setIsEmailEmpty(false)
                            }
                            else {
                                setIsEmailEmpty(true)
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.border = '1px solid #535353'
                        }}
                    />
                    {!isEmailEmpty && <p className='errMsg'>Enter Account</p>}
                </div>
                <div className='inputContainer'>

                    <input className='loginInput' type='password' placeholder='Enter Password'
                        onChange={e => setPassword(e.target.value)} value={password_hash}
                        onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                e.target.style.border = '1px solid red'
                                setIsPasswordEmpty(false)
                            }
                            else {
                                setIsPasswordEmpty(true)
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.border = '1px solid #535353'
                        }}
                    />
                    {!isPasswordEmpty && <p className='errMsg'>Enter Password</p>}
                </div>
                <div className='inputContainer'>

                    <input className='loginInput' type='number' placeholder='Enter Phone Number'
                        onChange={e => setPhoneNumber(e.target.value)} value={phone_number}
                        onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                e.target.style.border = '1px solid red'
                                setIsPhoneNumberEmpty(false)
                            }
                            else {
                                setIsPhoneNumberEmpty(true)
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.border = '1px solid #535353'
                        }}
                    />
                    {!isPhoneNumberEmpty && <p className='errMsg'>Phone Number</p>}
                </div>
                <div className='inputContainer'>

                    <input className='loginInput' type='text' placeholder='Please enter your name'
                        onChange={e => setName(e.target.value.toLowerCase())} value={name}
                        onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                e.target.style.border = '1px solid red'
                                setIsNameEmpty(false)
                            }
                            else {
                                setIsNameEmpty(true)
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.border = '1px solid #535353'
                        }}
                    />
                    {!isNameEmpty && <p className='errMsg'>Please enter your name</p>}
                </div>
                <div className='inputContainer'>

                    <input className='loginInput' type='number' placeholder='Enter Invitaion code'
                        onChange={e => setVerificationCode(e.target.value)} value={verification_code}
                        onBlur={(e) => {
                            if (e.target.value.length === 0) {
                                e.target.style.border = '1px solid red'
                                setIsVerificationCodeEmpty(false)
                            }
                            else {
                                setIsVerificationCodeEmpty(true)
                            }
                        }}
                        onFocus={(e) => {
                            e.target.style.border = '1px solid #535353'
                        }}
                    />
                    {!isVerificationCodeEmpty && <p className='errMsg'>Please enter Invitation Code</p>}
                </div>

                <p className='msg' >{err}</p>
                {
                    isLoading ?
                        <button className='signInBtn signUp'><TailSpin width={20} stroke="#fff" /></button> :
                        <button onClick={handleSubmit} className='signInBtn signUp'>Sign Up</button>
                }
            </form>
        </div >
    )
}

export default EmailRegister
