import React from 'react'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import MobileNavBar from '../../Components/MobileNavBar/MobileNavBar'
import verified from '../../Assets/verified.png'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import logo from '../../Assets/Quantumn logo icon.png'
import './Profile.css'
import { logout } from '../../Redux/User/userAction'
import { useSelector, useDispatch } from 'react-redux'

const Profile = () => {

    const { email } = useSelector(state => state.user.userDetails)
    const { user_id } = useSelector(state => state.user.userDetails)
    const dispatch = useDispatch()

    const { token } = useSelector(state => state.user.userDetails)


    const navigate = useNavigate()

    const logoutFn = () => {
        dispatch(logout(token))
        navigate('/')
    }


    return (
        <div className='userProfile' >
            <MobileNavBar />
            <div className='navholder' >
                <HomeNavBar gradient={false} />
            </div>
            <div className='userDetailsBox'>
                <div className='userDetailsHolder'>
                    <img src={logo} alt='userImage' className='userProfileImage' />
                    <div className='userDetailsTextBox'>
                        <h3 className='userDetailsEmail'>{email}</h3>
                        <p className='userDetailsID' >UID: {user_id}</p>
                    </div>
                </div>
            </div>
            <div className='userDetails' >
                <div className='userProfileDetailsBox' >
                    {/*<div className='verifiedBox' >
                        <div>
                            <h2 className='verifedText' >Verified</h2>
                            <p className='verifiedBoxDesc' >To protect your account security, please complete real-name certification before you can trade operations!</p>
                            <button onClick={() => navigate('/user/auth')} className='verifiedBtn withdrawalSubmitBtn ' >Not Verifed!</button>
                        </div>
                        <img src={verified} alt='verified' className='verifiedImage' />
                    </div>*/}
                    <div className='securityBox' >
                        <div className='securityBoxHolder securityBoxHolderSec' >
                            <div className='securityBoxDetails'>
                                <i className="ri-shield-keyhole-fill profileIcons shield " />
                                <p className='securityLevelText' >Security Level: High</p>
                            </div>
                            <div className='securityLvl securityLvlSec'>
                                <div className='securityLvlBox activeBox' />
                                <div className='securityLvlBox activeBox' />
                                <div className='securityLvlBox activeBox' />
                            </div>
                        </div>
                        {/**Second */}
                        <div className='securityBoxHolder' >
                            <div className='securityBoxDetails'>
                                <i className="ri-mail-line profileIcons mail " />
                                <p className='securityLevelText' >Email</p>
                            </div>
                            <div className='securityLvl'>
                                <p className='securityLvlEmail' >{email}</p>
                            </div>
                        </div>
                        {/** Third*/}
                        <div className='securityBoxHolder lastOne ' >
                            <div className='securityBoxDetails'>
                                <i className="ri-smartphone-line profileIcons phone " />
                                <p className='securityLevelText' >Telephone</p>
                            </div>
                            <div className='securityLvl'>
                                <p onClick={() => navigate('/user/phone')} className='bindTel' >Bind telephone</p>
                            </div>
                        </div>
                    </div>
                    <div className='securityBox'>
                        <div className='securityBoxHolder' >
                            <p className='withdrawalLeftText'>Password management</p>
                        </div>
                        <div className='securityBoxHolder' >
                            <div className='securityBoxDetails'>
                                <i className="ri-lock-fill profileIcons shield " />
                                <p className='securityLevelText' >Login Password</p>
                            </div>
                            <div className='securityLvl'>
                                <Link className='bindTel' to='/user/change-password' >Modify</Link>
                            </div>
                        </div>
                        {/** Third*/}
                        {/*<div className='securityBoxHolder lastOne ' >
                            <div className='securityBoxDetails'>
                                <i className="ri-bank-card-fill profileIcons phone " />
                                <p className='securityLevelText' >Fund Password</p>
                            </div>
                            <div className='securityLvl'>
                                <p onClick={() => navigate('/user/fund/password ')} className='bindTel' >Set</p>
                            </div>
                </div>*/}
                    </div>
                    <button className='logoutBtn' onClick={logoutFn} >Logout</button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Profile
