import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import './SetFundPassword.css'
import HomeNavBar from '../../Components/HomeNavBar/HomeNavBar'
import { offMessage } from '../../Redux/User/userAction'
import { useDispatch } from 'react-redux'


const SetFundPassword = () => {

    const [isNewPasswordEmpty, setIsNewPasswordEmpty] = useState(true)
    const [isConfirmNewPassword, setIsConfirmNewPasswordEmpty] = useState(true)


    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')

    const dispatch = useDispatch()

    const handleSubmit = e => {
        e.preventDefault()
        if (newPassword.length === 0 && confirmNewPassword.length === 0) {
            setIsNewPasswordEmpty(false)
            setIsConfirmNewPasswordEmpty(false)
            return
        }

        else if (newPassword.length === 0) {
            setIsNewPasswordEmpty(false)
        }
        else if (confirmNewPassword.length === 0) {
            setIsConfirmNewPasswordEmpty(false)
        }
        else if (newPassword !== confirmNewPassword) {
            setIsConfirmNewPasswordEmpty(false)
            setIsNewPasswordEmpty(false)
        }
        else {

            alert(`and ${newPassword} and ${confirmNewPassword}`)
        }
    }


    const navigate = useNavigate()

    const goBack = () => {
        dispatch(offMessage())
        navigate(-1)
    }

    return (
        <div className='withdrawalBody '>
            <div className='linearGradientBody'>
                <HomeNavBar gradient={true} />
                <div className='withdrawContainer'>
                    <div className='arrowBox' onClick={goBack} >
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className='withdraw'>Set Fund password</h2>
                </div>
            </div>
            <div className='withdrawals setFundPassword'>
                <div className='telephoneBox fundPasswordbox' >
                    <div className='emailRegister bindteleBox '>
                        <form onSubmit={handleSubmit}>
                            <div className='inputContainer'>

                                <input className='loginInput' type='password' placeholder='Please enter new password'
                                    onChange={e => setNewPassword(e.target.value)} value={newPassword}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            e.target.style.border = '1px solid red'
                                            setIsNewPasswordEmpty(false)
                                        }
                                        else {
                                            setIsNewPasswordEmpty(true)
                                        }
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.border = '1px solid #535353'
                                    }}
                                />
                                {!isNewPasswordEmpty && <p className='errMsg'>Please enter your new Password</p>}
                            </div>
                            <div className='inputContainer'>

                                <input className='loginInput' type='password' placeholder='Confirm Password'
                                    onChange={e => setConfirmNewPassword(e.target.value)} value={confirmNewPassword}
                                    onBlur={(e) => {
                                        if (e.target.value.length === 0) {
                                            e.target.style.border = '1px solid red'
                                            setIsConfirmNewPasswordEmpty(false)
                                        }
                                        else {
                                            setIsConfirmNewPasswordEmpty(true)
                                        }
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.border = '1px solid #535353'
                                    }}
                                />
                                {!isConfirmNewPassword && <p className='errMsg'>Confirm new assword</p>}
                            </div>
                            <button className='signInBtn signUp'>Submit</button>
                        </form>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SetFundPassword
