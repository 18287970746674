import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../../Redux/User/userAction";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loading-icons";
import logo from '../../Assets/primary logotype-01.png'


const ResetPassword = () => {

    const [err, setErr] = useState('')
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const msg = useSelector(state => state.user.message)
    const isLoading = useSelector(state => state.user.isLoading)
    const resetSuccess = useSelector(state => state.user.resetSuccess)

    let { params } = useParams();




    const [isEmailEmpty, setIsEmailEmpty] = useState(true);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);

    const [password_hash, setNewPassword] = useState("");
    const [confirm_password, setConfirmNewPassword] = useState("");

    // const [isPasswordSame, setIsPasswordSame] = useState('')

    const resetPasswordDetails = {
        password_hash, confirm_password
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (password_hash.length === 0 && confirm_password.length === 0) {
            setIsEmailEmpty(false);
            setIsPasswordEmpty(false);
            return;
        } else if (password_hash.length === 0) {
            setIsEmailEmpty(false);
        } else if (confirm_password.length === 0) {
            setIsPasswordEmpty(false);
        }
        else if (password_hash !== confirm_password) {
            setIsPasswordEmpty(false);
            setIsEmailEmpty(false);
        }
        else {
            setIsEmailEmpty(true);
            setIsPasswordEmpty(true);
            dispatch(resetPassword(resetPasswordDetails, params))
            if (resetSuccess === true) {
                navigate('/login')
            }
        }
    };


    useEffect(() => {
        if (resetSuccess === true) {
            navigate('/login')
        }
    }, [resetSuccess, navigate])


    useEffect(() => {
        setErr(msg)
    }, [setErr, msg])


    return (
        <div className="loginContainer">
            {/*<div className="backArrowBox" onClick={() => navigate("/")}>
                <i className="ri-arrow-left-line backArrow"></i>
            </div>*/}

            <div className="loginBox">
                <div className="logoHolder">

                    <img className='logoImg' src={logo} alt='logo' />

                </div>
                <div className="formContainer">
                    <form className="loginForm">
                        <div className="inputContainer">
                            <label htmlFor="newPassword" className="loginLabel">New Password</label>
                            <input
                                id='newPassword'
                                className="loginInput"
                                type="password"
                                placeholder="New Password"
                                onChange={(e) => setNewPassword(e.target.value)}
                                value={password_hash}
                                onBlur={(e) => {
                                    if (e.target.value.length === 0) {
                                        e.target.style.border = "1px solid red";
                                        setIsEmailEmpty(false);
                                    } else {
                                        setIsEmailEmpty(true);
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.style.border = "1px solid #535353";
                                }}
                            />
                            {!isEmailEmpty && <p className="errMsg">Please enter your new password</p>}
                        </div>
                        <div className="inputContainer">
                            <label htmlFor="password" className="loginLabel">Confirm New Password</label>
                            <input
                                id="password"
                                className="loginInput"
                                type="password"
                                placeholder="Enter Password"
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                value={confirm_password}
                                onBlur={(e) => {
                                    if (e.target.value.length === 0) {
                                        e.target.style.border = "1px solid red";
                                        setIsPasswordEmpty(false);
                                    } else {
                                        setIsPasswordEmpty(true);
                                    }
                                }}
                                onFocus={(e) => {
                                    e.target.style.border = "1px solid #535353";
                                }}
                            />
                            {!isPasswordEmpty && <p className="errMsg">Please confirm your new Password</p>}
                        </div>

                        {isLoading ? <button className='signInBtn signUp'><TailSpin width={20} stroke="#fff" /></button> : <button onClick={handleSubmit} className="signInBtn resetBtn">Reset Password</button>}

                        <p className='msg' >{err}</p>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default ResetPassword;
