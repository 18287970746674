import React from 'react'
import Footer from '../../Components/Footer/Footer'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import './AdminDeposits.css'
import Loading from '../../Components/Loading/Loading'
import { useSelector, } from 'react-redux'
import EmptyComponent from '../../Components/EmptyComponent/EmptyComponent'
import AdminMobileNav from '../../Components/AdminMobileNav/AdminMobileNav'


const AdminDeposits = () => {


    const adminAllDeposits = useSelector(state => state.user.adminAllDeposits)



    let content = <Loading />

    if (adminAllDeposits.length > 0) {

        content = <div className='tableParent withDrawalTableContainer  '  >
            <table className='userListTable withdrawTable ' >
                <thead>
                    <tr>
                        <th>Withdraw id</th>
                        <th>User Id</th>
                        <th>Currency</th>
                        <th>Address</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        adminAllDeposits.map((withdraw, id) => {
                            return (
                                <tr key={id} >
                                    <td>{withdraw.withdraw_id}</td>
                                    <td>{withdraw.user_id}</td>
                                    <td>{withdraw.currency}</td>
                                    <td>{withdraw.address}</td>
                                    <td>{new Intl.NumberFormat().format(withdraw.amount)}</td>
                                    <td><button className='viewBtn' >View</button></td>
                                </tr>
                            )
                        })
                    }


                </tbody>
            </table>
        </div>
    }

    else if (adminAllDeposits?.length === 0) {
        content = <EmptyComponent />
    }




    return (
        <div>
            <div className='navholder' >
                <AdminNavbar gradient={false} />
                <AdminMobileNav />
            </div>
            <div className='icoBody'>
                <div className='icoBodyParent depositBody ' >
                    {content}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminDeposits
