import React from 'react'
import { NavLink } from 'react-router-dom'


const AdminMobileNav = () => {



    return (
        <div className='mobileNavBarHolder' >
            <div className='mobileNavBar'>
                <NavLink to='/admin/home' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-home-2-fill navIcon"></i>
                        <p className='mobileNavLink'>Home</p>
                    </div>
                </NavLink>
                <NavLink to='/admin/deposits' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-building-line navIcon"></i>
                        <p className='mobileNavLink'>Deposit</p>
                    </div>
                </NavLink>
                <NavLink to='/admin/withdraws' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-exchange-fill navIcon"></i>
                        <p className='mobileNavLink'>Withdraws</p>
                    </div>
                </NavLink>
                {/*<NavLink to='/assets' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-wallet-fill navIcon"></i>
                        <p className='mobileNavLink'>Assets</p>
                    </div>
            </NavLink>*/}
                <NavLink to='/admin/ico' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox'>
                        <i className="ri-archive-fill navIcon"></i>
                        <p className='mobileNavLink'>ICO</p>
                    </div>
                </NavLink>

                <NavLink to='/admin/finance' className={({ isActive }) =>
                    isActive ? "mobileLink" : "mobileLinkNotActive"
                }>
                    <div className='mobileNavBox' >
                        <i className="ri-bank-card-fill navIcon"></i>
                        <p className='mobileNavLink'>Finance</p>
                    </div>
                </NavLink>


            </div>
        </div>
    )
}

export default AdminMobileNav
