import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './OfflineView.css'
import EmptyComponent from '../EmptyComponent/EmptyComponent'
import { useNavigate } from 'react-router-dom'

const OfflineView = () => {

    const isSignedIn = useSelector(state => state.user.isSignedIn)

    const selectedCoin = useSelector(state => state.user.selectedCoin)

    const showBuy = () => {
        setBuy(true)
    }

    const showSell = () => {
        setBuy(false)
    }

    const navigate = useNavigate()

    const [buy, setBuy] = useState(true)


    const [sellAmount, setSellAmount] = useState('')

    return (

        <div className='offlineParent' >
            <div className='offlineLeft'>
                <div className='delegateContainer'>
                    <div className='icoLeftOptions' >
                        <div style={{ background: '#FFB331' }} className='icoOptionBox'>
                            <p style={{ color: '#121212' }} className='icoOption' >Delegate</p>
                        </div>
                    </div>
                </div>
                <div className='delegateOptions'>
                    <p>Time</p>
                    <p>Pair</p>
                    <p>Direction</p>
                    <p>Price</p>
                    <p>Amount</p>
                    <p>Finish Amount</p>
                    <p>Status</p>
                </div>
                <div className='offlineLoginParent'>
                    {
                        !isSignedIn ?
                            <div className='offlineLoginContainer'>
                                <p>Please Log In</p>
                                <button onClick={() => navigate('/login')} className='loginBtn offlineViewLogin'>Log In</button>
                            </div> :
                            <div>
                                <EmptyComponent />
                            </div>
                    }

                </div>
            </div>
            <div className='offlineRight'>
                <div className='buySellOptionContainer' >
                    <button onClick={showBuy} style={{ background: (buy) ? '#12BEA1' : 'inherit' }} className='buySellBtn' >Buy</button>
                    <button onClick={showSell} style={{ background: (buy) ? 'inherit' : '#F84273' }} className='buySellBtn' >Sell</button>
                </div>
                <div className='offlineRightDescBox' >
                    {
                        buy ? <p className='offlineRightDesc'>At the best price in the market Buy</p> : <p className='offlineRightDesc'>At the best price in the market Sell</p>
                    }
                </div>
                <div className='offlineInputContainer'>
                    <input className='offlineInputField' onChange={e => setSellAmount(e.target.value)} type='number' placeholder={buy ? 'Amount' : 'Sell Amount'} value={sellAmount} />
                    <span>USDC</span>
                </div>
                <div className='percentageOptions' >
                    <div className='percentageOption' >20%</div>
                    <div className='percentageOption' >40%</div>
                    <div className='percentageOption' >60%</div>
                    <div className='percentageOption' >80%</div>
                    <div className='percentageOption' >100%</div>
                </div>
                <>
                    <p className='availBalText'>Available Balance</p>
                    <div className='availBal'>
                        <span className='availBalFigure'>0.0000</span>
                        <span className='availBalCurrency'>USDC</span>
                    </div>
                </>
                {
                    !isSignedIn ? <>
                        <button className='offlineLoginBigBtn' onClick={() => navigate('/login')} >Log In</button>
                    </> :
                        <>
                            <button className='offlineLoginBigBtn' style={{ background: (buy) ? '#12BEA1' : '#F84273' }}> {buy ? 'Buy' : 'Sell'} {selectedCoin}</button>
                        </>
                }


            </div>
        </div>
    )
}

export default OfflineView
