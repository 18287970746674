import React from 'react'
import './AddressRecords.css'
import EmptyComponent from '../EmptyComponent/EmptyComponent'

const AddressRecords = () => {
    return (
        <div className='addressRecords'>
            <div className='withdrawalsRecordsTextBox'>
                <p className='withdrawalsRecordsText'>My withdrawal address</p>
            </div>
            <div className='withdrawRecords'>
                <EmptyComponent />
            </div>
        </div>
    )
}

export default AddressRecords
