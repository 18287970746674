import React from 'react'
import './AppDesc.css'
import Image1 from '../../Assets/image1.png'
import Image2 from '../../Assets/image2.png'
import Image3 from '../../Assets/image3.png'

const AppDesc = () => {
    return (
        <div className='AppDescContainer'>
            <div className='AppDesc'>
                <div className='appDescTextHolder'>
                    <h1 className='heroTextHeader'>User-trusted cryptocurrency trading platform</h1>
                    <p className='heroTextDesc'>Strive to promise to escort the safety of users with strict protocols and industry-leading technical measures</p>
                </div>

                {/**3 DIFFERENT SECTIONS */}
                <div className='AppDescSections'>
                    <div className='AppDescSectionsLeft'>
                        <h3 className='AppDescSectionsHeader'>In the market with liquidity depth of encrypted currency trading</h3>
                        <p className='AppDescSectionsDesc'>Years of operating experience, to provide users with the rich, flexible, stable, safe and reliable trading environment</p>
                    </div>
                    <div className='AppDescSectionsRight'>
                        <img src={Image1} className='AppDescImage' alt='currency trader' />
                    </div>
                </div>

                <div className='AppDescSections'>
                    <div className='AppDescSectionsLeft'>
                        <img src={Image2} className='AppDescImage' alt='currency trader' />
                    </div>
                    <div className='AppDescSectionsRight'>
                        <h3 className='AppDescSectionsHeader'>Our advantage</h3>
                        <p className='AppDescSectionsDesc'>Whether you are through our API, mobile application or website, Bitbaseindustry leading trading engine can let you quickly trade safely.</p>
                    </div>

                </div>

                <div className='AppDescSections'>
                    <div className='AppDescSectionsLeft'>
                        <h3 className='AppDescSectionsHeader'>Focus on the user experience</h3>
                        <p className='AppDescSectionsDesc'>UI：Carefully polished details, UI, Strict control of interaction details, Trading can also be a visual enjoyment</p>
                    </div>
                    <div className='AppDescSectionsRight'>
                        <img src={Image3} className='AppDescImage' alt='currency trader' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppDesc
