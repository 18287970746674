import React, { useState, useEffect } from 'react'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import Footer from '../../Components/Footer/Footer'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../../Components/Loading/Loading'
import { TailSpin } from 'react-loading-icons'
import { getAllWithdrawsAdmin, adminUpdateWithdraw, offMessage } from '../../Redux/User/userAction'

const AdminViewSingleWithdraw = () => {

    const adminViewSingleWithdrawal = useSelector(state => state.user.adminViewSingleWithdrawal)
    const isLoading = useSelector(state => state.user.isLoading)
    const message = useSelector(state => state.user.message)
    const { token } = useSelector(state => state.user.userDetails)



    const [currency, setCurrency] = useState('')
    const [address, setAddress] = useState('')
    const [amount, setAmount] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        setCurrency(adminViewSingleWithdrawal[0]?.currency)
        setAmount(adminViewSingleWithdrawal[0]?.amount)
        setAddress(adminViewSingleWithdrawal[0]?.address)
    }, [setCurrency, setAmount, setAddress, adminViewSingleWithdrawal])



    const updateDetails = {
        currency, amount, address
    }


    const dispatch = useDispatch()
    const updateWithdrawal = () => {

        dispatch(adminUpdateWithdraw(adminViewSingleWithdrawal[0]?.withdraw_id, updateDetails, token))
        dispatch(getAllWithdrawsAdmin(token))
    }

    const goBack = () => {
        dispatch(offMessage())
        navigate(-1)
    }


    return (
        <div className="">
            <div className="SingleWithdrawal financeParent">
                <AdminNavbar />
                <div className="withdrawContainer">
                    <div className="arrowBox" onClick={goBack}>
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className="withdraw">Withdraw</h2>
                </div>
                <div className='singleFinanceParent'>
                    <div className='icoBodyParent ' >
                        {
                            isLoading ? <Loading /> :
                                <>

                                    <div className='tableParent  withDrawalTableContainer'  >
                                        <table className='userListTable  ' >
                                            <thead>
                                                <tr>


                                                    <th>Currency</th>
                                                    <th>Address</th>
                                                    <th>Amount</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    <tr  >


                                                        <td>{adminViewSingleWithdrawal[0]?.currency}</td>
                                                        <td>{adminViewSingleWithdrawal[0]?.address}</td>
                                                        <td>{new Intl.NumberFormat().format(adminViewSingleWithdrawal[0]?.amount)}</td>

                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='EditIcoInputsContainer editWithdrawal' >
                                        <input onChange={e => setCurrency(e.target.value)} className='editIcoInput' placeholder='Currency' value={currency} />
                                        <input onChange={e => setAddress(e.target.value)} className='editIcoInput' placeholder='Address' value={address} />
                                        <input onChange={e => setAmount(e.target.value)} className='editIcoInput' placeholder='Amount' value={amount} type='number' />
                                        {
                                            isLoading ? <button className='attendBtn updateIcoBtn ' ><TailSpin width={20} stroke="#fff" /></button> :

                                                <button onClick={updateWithdrawal} className='attendBtn updateIcoBtn '>Update Withdrawal</button>
                                        }
                                        <p className='success'>{message}</p>
                                    </div>
                                </>
                        }


                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminViewSingleWithdraw
