import { useState, useRef, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import './HomeNavBar.css'
import { logout } from '../../Redux/User/userAction'
import { useSelector, useDispatch } from 'react-redux'
import logo from '../../Assets/primary horizontal-01.png'
import companyLogo from '../../Assets/Quantumn logo icon.png'

const HomeNavBar = ({ gradient }) => {

    const dispatch = useDispatch()

    const [isMobileOpen, setIsMobileOpen] = useState(true)

    const isSignedIn = useSelector(state => state.user.isSignedIn)
    const { email } = useSelector(state => state.user.userDetails)
    const selectedCoin = useSelector(state => state.user.selectedCoin)
    const { token } = useSelector(state => state.user.userDetails)

    const navigate = useNavigate()

    const logoutFn = () => {
        dispatch(logout(token))
        navigate('/')
    }


    // Auto Logout
    const timeOut = 3600000;
    const logoutTimer = useRef(null);

    useEffect(() => {
        const startTimeout = () => {
            logoutTimer.current = setTimeout(() => {
                dispatch(logout(token));
            }, timeOut);
        };

        const resetTimeout = () => {
            clearTimeout(logoutTimer.current);
            startTimeout();
        };


        startTimeout();
        resetTimeout()


    }, [dispatch, timeOut, token]);


    const toggleOption = () => {
        setIsMobileOpen(prev => !prev)
    }


    return (
        <header className='DesktopHeader' style={{ background: (gradient ? 'transparent' : 'rgb(24, 24, 26, .1)') }} >
            <div className='normalNavContainer' >
                <Link to='/' className='brand normalMenuLogo' >
                    <img className='logoImg' src={logo} alt='logo' />
                </Link>
                <div className='mobileNavOptions' >
                    {
                        isMobileOpen ? <i onClick={toggleOption} className="ri-menu-line mobileOption"></i>
                            : <i onClick={toggleOption} className="ri-close-fill mobileOption"></i>
                    }
                </div>
                {
                    isSignedIn ?

                        <div className={!isMobileOpen ? 'normalMenu' : 'normalMenuNotOpen'} >
                            <ul>
                                <li className='navItem'>
                                    <NavLink className={({ isActive }) =>
                                        isActive ? "navLinkActive" : "navLink"
                                    } to='/user/withdraw'>Withdraw</NavLink>
                                </li>
                                <li className='navItem'>
                                    <NavLink className={({ isActive }) =>
                                        isActive ? "navLinkActive" : "navLink"
                                    } to='/user/finance'>Finance</NavLink>
                                </li>
                                <li className='navItem'>
                                    <NavLink className={({ isActive }) =>
                                        isActive ? "navLinkActive" : "navLink"
                                    } to='/user/deposit'>Deposit</NavLink>
                                </li>
                            </ul>
                            <button className='loginBtn' onClick={logoutFn} >Log Out</button>
                        </div> :

                        <div className={!isMobileOpen ? 'normalMenu' : 'normalMenuNotOpen'} >
                            <button className='loginBtn' onClick={() => navigate('/login')} >Log In</button>
                            <button onClick={() => navigate('/signup')} className='signUpBtn normalNavRegBtn'>Sign Up</button>
                        </div>

                }
            </div>
            <nav>

                <div className='left'>
                    <Link to='/' className='brand' >
                        <img className='logoImg' src={logo} alt='logo' />
                    </Link>
                    <ul className='navParent'>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/'>Home</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/market'>Market</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to={`/exchanges/${selectedCoin}`}>Exchange</NavLink>
                        </li>
                        <li className='navItem'>
                            <NavLink className={({ isActive }) =>
                                isActive ? "navLinkActive" : "navLink"
                            } to='/ico'>ICO</NavLink>
                        </li>
                    </ul>
                </div>
                {
                    !isSignedIn ?
                        <div className='navParentDiv'>
                            <button onClick={() => navigate('/login')} className='loginBtn'>Log In</button>
                            <button onClick={() => navigate('/signup')} className='signUpBtn'>Sign Up</button>
                        </div> :
                        <div className='userParentDiv'>
                            <img src={companyLogo} alt='logo' className='userImage' />
                            <p className='userEmail'>{email}</p>
                            <i className="ri-arrow-down-s-line downArrow"></i>
                            <div className='subMenuBoxContainer' >
                                <div className='subMenuBox'>
                                    <div className='subMenuTop' >
                                        <div className='subMenuTopComponents'>
                                            <div className='subImageBox' >
                                                <img className='subMenuImage' src={companyLogo} alt='user' />
                                            </div>
                                            <p className='userEmail'>{email}</p>
                                        </div>
                                    </div>
                                    <div className='subMenuBottom' >
                                        <div className='subMenuBottomNavItems' >
                                            <div onClick={() => navigate('/user/profile')} className='subMenuBottomLinksBox' >
                                                <p className='subMenuLinks' >User profile</p>
                                            </div>
                                            <div onClick={() => navigate('/user/deposit')} className='subMenuBottomLinksBox' >
                                                <p className='subMenuLinks' >Deposit</p>
                                            </div>
                                            <div onClick={() => navigate('/user/withdraw')} className='subMenuBottomLinksBox' >
                                                <p className='subMenuLinks' >Withdraw</p>
                                            </div>
                                            <div className='subMenuBottomLinksBox' onClick={() => navigate('/user/finance')} >
                                                <p className='subMenuLinks' >Finance</p>
                                            </div>

                                        </div>
                                        <div className='subMenuLogoutBox' >
                                            <h4 className='subMenuLogoutBtn' onClick={logoutFn} >Log out</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                }
            </nav>
        </header>
    )
}

export default HomeNavBar
