import React, { useState } from 'react'
import Footer from '../../Components/Footer/Footer'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { adminCreateFinance, adminGetAllFinance } from '../../Redux/User/userAction'


const CreateFinance = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()


    const { user_id } = useSelector(state => state.user.user)
    const { token } = useSelector(state => state.user.userDetails)


    const [currency, setCurrency] = useState('')
    const [amount, setAmount] = useState('')

    const createBody = {
        user_id, currency, amount
    }

    const createFinance = (e) => {
        e.preventDefault()
        if (currency.length === 0 || amount.length === 0) {
            return
        }
        else {
            dispatch(adminCreateFinance(token, createBody))
            dispatch(adminGetAllFinance(token))
            navigate('/admin/finance')
        }

    }

    return (
        <div>
            <div className='navholder' >
                <AdminNavbar gradient={false} />
            </div>

            <div className='icoBody '>
                <div className="withdrawContainer">
                    <div className="arrowBox" onClick={() => navigate(-1)}>
                        <i className="ri-arrow-left-line backArw"></i>
                    </div>
                    <h2 className="withdraw">Create Finance</h2>
                </div>
                <div className='icoBodyParent depositBody createFinanceBody' >
                    <form className="loginForm" onSubmit={createFinance}>
                        <div className="inputContainer">
                            <input

                                className="loginInput"
                                type="text"
                                placeholder="User Id"
                                // onChange={(e) => setEmail(e.target.value)}
                                value={user_id}
                            />

                        </div>
                        <div className="inputContainer">
                            <input
                                id='currency'
                                className="loginInput"
                                type="text"
                                placeholder="Currency"
                                onChange={(e) => setCurrency(e.target.value)}
                                value={currency}
                            />

                        </div>
                        <div className="inputContainer">
                            <input
                                id='amount'
                                className="loginInput"
                                type="number"
                                placeholder="Amount"
                                onChange={(e) => setAmount(e.target.value)}
                                value={amount}
                            />

                        </div>
                        <button className='createUserFinance'>Create</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CreateFinance
