import React, { useEffect } from 'react'
import './AdminHome.css'
import AdminNavbar from '../../Components/AdminNavBar/AdminNavbar'
import AdminMobileNav from '../../Components/AdminMobileNav/AdminMobileNav'
import UsersList from '../../Components/UsersList/UsersList'
import { useSelector, useDispatch } from 'react-redux'
import Footer from '../../Components/Footer/Footer'
import { fetchAllUsers, getAllWithdrawsAdmin, fetchAllICOs, adminGetAllFinance, getAllDepositsAdmin } from '../../Redux/User/userAction'
import Loading from '../../Components/Loading/Loading'



const AdminHome = () => {

    const isLoading = useSelector(state => state.user.isLoading)
    const { token } = useSelector(state => state.user.userDetails)
    const users = useSelector(state => state.user.users)
    const icos = useSelector(state => state.user.icos)
    const adminAllWithdrawals = useSelector(state => state.user.adminAllWithdrawals)
    const adminAllDeposits = useSelector(state => state.user.adminAllDeposits)
    const adminAllFinance = useSelector(state => state.user.adminAllFinance)

    const dispatch = useDispatch()

    useEffect(() => {
        // if (users?.length === 0) {
        dispatch(fetchAllUsers(token))
        // }
        // if (icos?.length === 0) {
        dispatch(fetchAllICOs(token))
        // }
        //     if (adminAllWithdrawals?.length === 0) {
        dispatch(getAllWithdrawsAdmin(token))
        //     }
        //     if (adminAllWithdrawals?.length === 0) {
        dispatch(getAllDepositsAdmin(token))
        //     }
        //     if (adminAllFinance?.length === 0) {
        dispatch(adminGetAllFinance(token))
        //     }
        // }, [dispatch, adminAllFinance, users, icos, token, adminAllDeposits, adminAllWithdrawals])
    }, [dispatch, token])

    return (
        <>
            <div className='adminParent' >
                <AdminNavbar gradient={true} />
                <AdminMobileNav />

                {
                    isLoading ? <Loading /> :
                        <div className='userListContainer'>
                            <UsersList full={true} />
                        </div>
                }
            </div>
            <Footer />
        </>
    )
}

export default AdminHome
